import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import IngredientForm from './IngredientForm';

const EditIngredients = (props) => {
  const {
    ingredients,
    form,
    addIngredient,
    updateIngredient,
    saveRecipe,
    allowUnitConversion,
    removeIngredient,
    renderFormErrors,
    units,
    links
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_recipe.forms.edit_ingredients', locale: localeContext.locale };
  const [loading, setLoading] = useState(false);
  const onFormSubmit = () => {
    setLoading(true);
    const formSaved = saveRecipe();
    setLoading(formSaved);
  };
  let calculatedIdentifier = 1;

  return (
    <React.Fragment>
      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8">
          <hr />
        </div>
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <span className="text-center font-20 text-black-50 font-weight-light">
            {I18n.t('title', SCOPE_OPTIONS)}
          </span>
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8">
          {ingredients.map((ingredient, i) => (
            <IngredientForm
              key={i}
              index={i}
              form={form}
              ingredient={ingredient}
              updateIngredient={updateIngredient}
              calculatedIdentifier={
                ingredient.deleted ? null : calculatedIdentifier++
              }
              allowUnitConversion={allowUnitConversion}
              removeIngredient={removeIngredient}
              renderFormErrors={renderFormErrors}
              units={units}
              links={links}
            />
          ))}
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-outline-secondary btn-lg mr-4"
            onClick={addIngredient}
            data-tid="add-more-ingredient-btn"
          >
            {I18n.t('add_more', SCOPE_OPTIONS)}
          </button>
          <button
            type="button"
            className={`btn btn-info btn-lg ${loading ? 'disabled' : ''}`}
            onClick={onFormSubmit}
            disabled={loading}
            data-tid="recipe-submit-btn"
          >
            <i
              className={`fa fa-spinner fa-spin mr-1 ${
                loading ? '' : 'd-none'
                }`}
            />
            {I18n.t('actions.save', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

EditIngredients.propTypes = {
  ingredients: PropTypes.array.isRequired,
  addIngredient: PropTypes.func.isRequired,
  updateIngredient: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  saveRecipe: PropTypes.func.isRequired,
  allowUnitConversion: PropTypes.bool.isRequired,
  renderFormErrors: PropTypes.bool.isRequired,
  units: PropTypes.array.isRequired,
  links: PropTypes.object.isRequired
};

export default EditIngredients;
