import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import FlashAlert from '../../../FlashMessage/FlashAlert';
import { validUrl, validUsername, validPassword } from '../../../Helper/Validators/Integration/AkauntingValidator';
import AkauntingIcon from '../../../Helper/AkauntingIcon';
import { statusHandler } from '../../../Helper/RequestHelpers';

const CredentialsForm = (props) => {
  const {
    links: { authorize_akaunting_integration_credentials_path: { form } },
    usingCloudVersion,
    setUsingCloudVersion,
    baseUrl,
    setBaseUrl,
    username,
    setUsername,
    password,
    setPassword,
    setCompanies,
    onClickContinue,
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.integration.forms.credentials_form', locale: localeContext.locale };

  const [loading, setLoading] = useState(false);
  const [flashMessage, setFlashMessage] = useState(null);
  const [renderFormErrors, setRenderFormErrors] = useState(false);

  const renderFlashMessage = (type, i18nKey) => {
    const text = I18n.t(i18nKey, SCOPE_OPTIONS);
    const message = { id: Math.random(), type, text };
    setFlashMessage(message);
  };

  const authorizeCredentials = () => {
    const { action, method, csrf_token } = form;

    fetch(action, {
      credentials: 'same-origin',
      method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        integration: {
          using_cloud_version: usingCloudVersion,
          base_url: baseUrl,
          username,
          password,
        },
      }),
    }).then(statusHandler).then(response => response.json()).then((json) => {
      setLoading(false);
      const { companies } = json;
      setCompanies(companies);
      onClickContinue();
    })
      .catch((ex) => {
        setLoading(false);
        renderFlashMessage('danger', 'errors.wrong_credentials');
      });
  };

  const onFormSubmit = () => {
    setRenderFormErrors(true);
    if (validUrl(baseUrl, usingCloudVersion) && validUsername(username) && validPassword(password)) {
      setLoading(true);
      authorizeCredentials();
    }
  };

  const baseUrlInvalid = !validUrl(baseUrl, usingCloudVersion);
  const usernameInvalid = !validUsername(username);
  const passwordInvalid = !validPassword(password);

  return (
    <React.Fragment>
      {flashMessage && (
        <div>
          <FlashAlert key={flashMessage.id} message={flashMessage} onClose={() => setFlashMessage(null)} />
        </div>
      )}

      <div className="card border">
        <div className="card header">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <div className="mr-3">
                <AkauntingIcon />
              </div>
              <h5>{I18n.t('name', SCOPE_OPTIONS)}</h5>
              <h6 className="ml-auto">{I18n.t('steps', SCOPE_OPTIONS)}</h6>
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-xl-9 col-12">
                <div className="form-group row">
                  <label className="col-lg-3 col-12 col-form-label font-weight-light">
                    {I18n.t('username.label', SCOPE_OPTIONS)}
                  </label>
                  <div className="col-lg-9 col-12">
                    <input type="text" className={`form-control ${renderFormErrors && usernameInvalid ? 'is-invalid' : ''}`} name="username" value={username || ''} onChange={e => setUsername(e.target.value)} />
                    {renderFormErrors && usernameInvalid && (
                      <div className="invalid-feedback">
                        {I18n.t('username.invalid_feedback', SCOPE_OPTIONS)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-12 col-form-label font-weight-light">
                    {I18n.t('password.label', SCOPE_OPTIONS)}
                  </label>
                  <div className="col-lg-9 col-12">
                    <input type="password" className={`form-control ${renderFormErrors && passwordInvalid ? 'is-invalid' : ''}`} name="password" value={password || ''} onChange={e => setPassword(e.target.value)} />
                    {renderFormErrors && passwordInvalid && (
                      <div className="invalid-feedback">
                        {I18n.t('password.invalid_feedback', SCOPE_OPTIONS)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-group row align-items-center">
                  <div className="col-lg-3 col-12" />

                  <div className="col-lg-9 col-12">
                    <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="using-cloud-version-checkbox" name="using_cloud_version" checked={usingCloudVersion} onChange={e => setUsingCloudVersion(e.target.checked)} />
                      <label className="custom-control-label" htmlFor="using-cloud-version-checkbox">{I18n.t('using_cloud_version', SCOPE_OPTIONS)}</label>
                    </div>
                  </div>
                </div>

                <div className={`form-group row ${usingCloudVersion ? 'd-none' : ''}`}>
                  <label className="col-lg-3 col-12 col-form-label font-weight-light">
                    {I18n.t('base_url.label', SCOPE_OPTIONS)}
                  </label>
                  <div className="col-lg-9 col-12">
                    <input type="text" className={`form-control ${renderFormErrors && baseUrlInvalid ? 'is-invalid' : ''}`} name="base_url" value={baseUrl || ''} onChange={e => setBaseUrl(e.target.value)} />
                    {renderFormErrors && baseUrlInvalid && (
                      <div className="invalid-feedback">
                        {I18n.t('base_url.invalid_feedback', SCOPE_OPTIONS)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="actions row mt-4">
                  <div className="col-lg-12 d-flex justify-content-end">
                    <button
                      type="submit"
                      className={`btn btn-info btn-lg ${loading ? 'disabled' : ''}`}
                      onClick={onFormSubmit}
                      disabled={loading}
                      data-tid="continue-to-company-selection"
                    >
                      <i className={`fas fa-spinner fa-spin mr-1 ${loading ? '' : 'd-none'}`} />
                      {I18n.t('actions.continue', SCOPE_OPTIONS)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CredentialsForm.propTypes = {
  onClickContinue: PropTypes.func.isRequired,
  links: PropTypes.object.isRequired,
  usingCloudVersion: PropTypes.bool,
  setUsingCloudVersion: PropTypes.func.isRequired,
  baseUrl: PropTypes.node,
  setBaseUrl: PropTypes.func.isRequired,
  username: PropTypes.node,
  setUsername: PropTypes.func.isRequired,
  password: PropTypes.node,
  setPassword: PropTypes.func.isRequired,
  setCompanies: PropTypes.func.isRequired,
};

export default CredentialsForm;
