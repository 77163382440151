import { isEmpty } from 'lodash';

export const validProduct = product => !isEmpty(product) && product.parasut_id !== undefined;
export const validQuantity = quantity => quantity !== '' && quantity !== 0;
export const validIngredient = ingredient => validProduct(ingredient.product) && validQuantity(ingredient.quantity);
export const validIngredients = ingredients => ingredients.length > 0 && ingredients.filter(ingredient => !ingredient.deleted).filter(ingredient => !validIngredient(ingredient)).length === 0;
export const validInflowIngredients = (ingredients) => {
  if (ingredients.length === 0) {
    return true;
  }
  return validIngredients(ingredients);
};
