import React from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import PlanMaterialsTable from './Forms/PlanMaterialsTable';
import moment from 'moment';

const Form = (props) => {
  const { translation, supply_planning: { name, goal_date }, materials, links } = props;

  const SCOPE_OPTIONS = { scope: 'components.supply_planning.material_form', locale: translation.locale };
  moment.locale(translation.locale);

  const handleCreateBtnClick = () => {
    window.Turbolinks.visit(links.show_supply_planning_url);
  };

  return (
    <LocaleContext.Provider value={translation}>
      <div className="row mt-2">
        <div className="col-12">
          <div className="form-row mx-2">
            <div className="form-group row col-lg-6 col-12">
              <label className="col-lg-2 col-12 col-form-label font-weight-light">{I18n.t('plan_name.label', SCOPE_OPTIONS)}</label>
              <div className="col-lg-10 col-12">
                <input value={name} className="form-control disabled" data-tid="input-supply-planning-plan-name" disabled={true} />
              </div>
            </div>
            <div className="form-group row col-lg-6 col-12 ml-lg-2">
              <label className="col-lg-2 col-12 col-form-label font-weight-light">{I18n.t('goal_date.label', SCOPE_OPTIONS)}</label>
              <div className="col-lg-10 col-12">
                <input value={moment(goal_date).format('L')} className="form-control disabled" data-tid="input-supply-planning-goal-date" disabled={true} />
                <small className="form-text text-muted">{I18n.t('goal_date.info', SCOPE_OPTIONS)}</small>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PlanMaterialsTable materials={materials} />

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className={`btn btn-info btn-lg`} data-tid="supply-planning-create-btn" onClick={handleCreateBtnClick}>
            {I18n.t('actions.create', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>
    </LocaleContext.Provider>
  );
};

Form.propTypes = {
  translation: LocaleProp.isRequired,
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      stock_count: PropTypes.isRequired,
      net_requirement_count: PropTypes.isRequired,
      usage_quantity: PropTypes.isRequired,
      product: PropTypes.object.isRequired
    }).isRequired
  ).isRequired,
  supply_planning: PropTypes.shape({
    name: PropTypes.string.isRequired,
    goal_date: PropTypes.string.isRequired
  }).isRequired,
};

export default Form;
