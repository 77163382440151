import React from 'react';
import PropTypes from 'prop-types';
import { formProp } from '../../Helper/CommonProps';

const SubmitForm = React.forwardRef((props, ref) => {
  const {
    form,
    shipmentDocumentForm,
    lineItems
  } = props;

  const lineItemInputName = index => `shipment_document[line_items_attributes][${index}]`;

  const filteredLineItems = lineItems.filter(lineItem => {
    const q = Number(lineItem.quantity);
    return (q !== null && q !== '' && q !== 0);
  });

  return (
    <React.Fragment>
      <form action={form.action} method="post" data-remote data-turbolinks-form data-tid="customer-order-form" data-element="customer-order-form" ref={ref}>
        <input type="hidden" name={form.csrf_param} value={form.csrf_token} />
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value={form.method} />

        <input type="hidden" value={shipmentDocumentForm.source_type || ''} name="shipment_document[source_type]" />
        <input type="hidden" value={shipmentDocumentForm.source_id || ''} name="shipment_document[source_id]" />
        <input type="hidden" value={shipmentDocumentForm.inflow} name="shipment_document[inflow]" />
        <input type="hidden" value={shipmentDocumentForm.description || ''} name="shipment_document[description]" />
        <input type="hidden" value={shipmentDocumentForm.issue_date || ''} name="shipment_document[issue_date]" />

        {/* filter is used to not to render if lineItem.id null and deleted all together */}
        {filteredLineItems.map((lineItem, i) => (
          <React.Fragment key={`line-item-values-${i}`}>
            <input type="hidden" value={lineItem.id || ''} name={`${lineItemInputName(i)}[id]`} />
            <input type="hidden" value={lineItem.product.id || ''} name={`${lineItemInputName(i)}[product][id]`} />
            <input type="hidden" value={lineItem.product.name || ''} name={`${lineItemInputName(i)}[product][name]`} />
            <input type="hidden" value={lineItem.product.unit || ''} name={`${lineItemInputName(i)}[product][unit]`} />
            <input type="hidden" value={lineItem.product.parasut_id || ''} name={`${lineItemInputName(i)}[product][parasut_id]`} />
            <input type="hidden" value={lineItem.product.code || ''} name={`${lineItemInputName(i)}[product][code]`} />
            <input type="hidden" value={lineItem.product.barcode || ''} name={`${lineItemInputName(i)}[product][barcode]`} />
            <input type="hidden" value={lineItem.quantity || ''} name={`${lineItemInputName(i)}[quantity]`} />
            <input type="hidden" value={lineItem.deleted || false} name={`${lineItemInputName(i)}[deleted]`} />
            <input type="hidden" value={lineItem.source_line_type || ''} name={`${lineItemInputName(i)}[source_line_type]`} />
            <input type="hidden" value={lineItem.source_line_id || ''} name={`${lineItemInputName(i)}[source_line_id]`} />
          </React.Fragment>
        ))}
      </form>
    </React.Fragment>
  );
});

SubmitForm.propTypes = {
  form: formProp.isRequired,
  shipmentDocumentForm: PropTypes.object.isRequired,
  lineItems: PropTypes.array.isRequired,
};

export default SubmitForm;
