import { useReducer } from 'react';

const useCollectionManagerReducer = (initialState, emptyObject) => useReducer((objects, action) => {
  switch (action.type) {
    case 'add':
      return [...objects, emptyObject];
    case 'update':
      return objects.map((object, i) => {
        if (i === action.index) {
          return action.object;
        }
        return object;
      });
    case 'delete':
      return objects.filter((_, i) => i !== action.index);
    default:
      return objects;
  }
}, initialState);

export default useCollectionManagerReducer;
