import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import { isEmpty } from 'lodash';
import Badge from './Badge';
import StepSummary from './StepSummary';

const StepListItem = (props) => {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.workflow_plan.partials.step_list_item', locale: localeContext.locale };

  const { isActive, index, step, onClick } = props;

  return (
    <React.Fragment>
      <div className={`d-flex list-group-item list-group-item-action  border-right cpointer ${isActive ? 'border-left-3 border-left-primary' : 'border-left'}`} onClick={() => onClick(index)}>

        <Badge step={step} index={index+1} />
        <StepSummary step={step} />

        { isActive && (
          <div className="align-self-center ml-auto">
            <i className="fas fa-chevron-right fa-lg" />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

StepListItem.propTypes = {
};

export default StepListItem;
