import React from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { LocaleProp } from 'Context/LocaleContext';
import Filters from '../Filterable/Filters';
import EmptyFilterPlaceholder from './IndexFilter/EmptyFilterPlaceholder';
import SearchBar from '../Searchable/SearchBar';

const IndexFilter = (props) => {
  const {
    columns, currentFilters, predicates, searchPath, currentQueryParameters, translation
  } = props;

  return (
    <LocaleContext.Provider value={translation}>
      <SearchBar
        currentQueryParameters={currentQueryParameters}
        searchPath={searchPath}
        i18nScope='components.purchase_order.search_bar'
      />

      <div className="ml-2">
        <Filters
          columns={columns}
          currentFilters={currentFilters}
          predicates={predicates}
          searchPath={searchPath}
          currentQueryParameters={currentQueryParameters}
          i18nScope='components.purchase_order.filterable'
          emptyFilterState={<EmptyFilterPlaceholder />}
        />
      </div>
    </LocaleContext.Provider>
  )
};

IndexFilter.propTypes = {
  columns: PropTypes.object.isRequired,
  currentFilters: PropTypes.array.isRequired,
  currentQueryParameters: PropTypes.object.isRequired,
  predicates: PropTypes.object.isRequired,
  searchPath: PropTypes.string.isRequired,
  translation: LocaleProp.isRequired,
};

export default IndexFilter;
