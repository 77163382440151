import React from "react"
import PropTypes from "prop-types"
import { Form as ReactForm, Text, TextArea } from 'react-form'
import IngredientList from './IngredientList'
import ParasutIconLink from '../Helper/ParasutIconLink'

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = { quantity: Number(props.manufacturing_record.quantity)}
  }

  formDefaultValues() {
    return {
      quantity: this.props.manufacturing_record.quantity,
      issueDate: this.props.manufacturing_record.issue_date,
      note: this.props.manufacturing_record.note,
      description: this.props.manufacturing_record.description || `${this.props.recipe.description} Üretimi`,
    }
  }

  onQuantityChange(value) {
    this.setState({quantity: Number(value)})
  }

  render () {
    const { form, manufacturing_record } = this.props;
    const { product, ingredients } = manufacturing_record;
    const recipe = this.props.recipe || {};

    return (
      <div>
        <ReactForm defaultValues={this.formDefaultValues()}>
        { formApi => (
          <form action={form.action} method="post" data-remote={true} data-turbolinks-form={true} data-tid="manufacturing-record-form">
            <input type='hidden' name={form.csrf_param} value={form.csrf_token} />
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name="_method" value={form.method} />
            <input type='hidden' name='recipe_id' value={recipe.id} />

            {recipe.description && (
              <div className="form-group row">
                <label htmlFor="recipe_name" className="col-3 col-form-label">Reçete Adı</label>
                <div className="col-9 col-form-label">
                  <span>{recipe.description}</span>
                </div>
              </div>
            )}

            <div className="form-group row">
              <label htmlFor="manufacturing_record_description" className="col-3 col-form-label">Üretim Açıklaması</label>
              <div className="col-9">
                <Text field='description' name='manufacturing_record[description]' className="form-control" data-tid="input-manufacturing-record-description"/>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="manufacturing_record_quantity" className="col-3 col-form-label">Üretilen Mamul Adı</label>
              <div className="col-9 col-form-label">
                <span>{product.name}</span>
              </div>
              {/* independent record - product starts */}
              <input type='hidden' value={product.id || ''} name='manufacturing_record[product][id]' />
              <input type='hidden' value={product.name || ''} name='manufacturing_record[product][name]' />
              <input type='hidden' value={product.unit || ''} name='manufacturing_record[product][unit]' />
              <input type='hidden' value={product.parasut_id || ''} name='manufacturing_record[product][parasut_id]' />
              {/* independent record - product ends */}
            </div>

            <div className="form-group row">
              <label htmlFor="manufacturing_record_quantity" className="col-3 col-form-label">Üretilen Mamul Miktarı</label>
              <div className="col-9 input-group">
                <Text field='quantity' name='manufacturing_record[quantity]' onChange={this.onQuantityChange.bind(this)} autoComplete='off' className="form-control" type="number" required={true} min="0" step="0.0001" data-tid="input-manufacturing-record-quantity"/>
                <div className="input-group-append">
                  <span className="input-group-text">{product.unit || 'Birim'}</span>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="manufacturing_record_issue_date" className="col-3 col-form-label">Üretim Tarihi</label>
              <div className="col-9">
                <Text field='issueDate' name='manufacturing_record[issue_date]' type="datetime-local" className="form-control" data-tid="input-manufacturing-record-issue-date"/>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="manufacturing_record_note" className="col-3 col-form-label">Üretime Özel Not</label>
              <div className="col-9">
                <TextArea field='note' name='manufacturing_record[note]' className="form-control" data-tid="input-manufacturing-record-note" />
              </div>
            </div>

            <div className='form-group'>
              <IngredientList ingredients={ingredients} quantity={this.state.quantity}/>
            </div>

            <button type="submit" className='btn btn-primary col-3 pull-right'>Kaydet</button>
          </form>
        )}
        </ReactForm>
      </div>
    );
  }
}

Form.propTypes = {
  manufacturing_record: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  recipe: PropTypes.object
}

export default Form
