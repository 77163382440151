import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomerAutocomplete from './CustomerAutocomplete';
import CreateNewCustomerModal from './CreateNewCustomerModal';

const CustomerAutocompleteWithCreateModal = (props) => {
  const {
    children,
    customer,
    searchPath,
    onCustomerSelect,
    wrapperPropsStyles,
    wrapperClassNames,
    topClassNames,
    inputDataTid,
    inputClassNames,
    renderAddCustomerModal,
    form,
    createCustomerPath,
    placeholderKey,
    isVendor,
  } = props;

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [newCustomerName, setNewCustomerName] = useState('');

  const handleAddNewCustomer = (value) => {
    setNewCustomerName(value);
    setIsModalOpened(true);
  };

  return (
    <React.Fragment>
      <CustomerAutocomplete
        customer={customer}
        searchPath={searchPath}
        onCustomerSelect={onCustomerSelect}
        wrapperPropsStyles={wrapperPropsStyles}
        wrapperClassNames={wrapperClassNames}
        topClassNames={topClassNames}
        inputDataTid={inputDataTid}
        inputClassNames={inputClassNames}
        renderAddCustomerModal={renderAddCustomerModal}
        onClickAddNewCustomer={handleAddNewCustomer}
        placeholderKey={placeholderKey}
        onlyVendors={isVendor}
      >
        {children}
      </CustomerAutocomplete>
      {isModalOpened && (
        <CreateNewCustomerModal
          isModalOpened={isModalOpened}
          setIsModalOpened={setIsModalOpened}
          newCustomerName={newCustomerName}
          isVendor={isVendor}
          form={form}
          onCustomerCreate={onCustomerSelect}
          createCustomerPath={createCustomerPath}
          i18nScope={'components.customer.create_new_customer_modal'}
        />
      )}
    </React.Fragment>
  );
};

CustomerAutocompleteWithCreateModal.propTypes = {
  // autocomplete props
  customer: PropTypes.object,
  searchPath: PropTypes.string.isRequired,
  onCustomerSelect: PropTypes.func.isRequired,
  wrapperPropsStyles: PropTypes.object,
  wrapperClassNames: PropTypes.string,
  topClassNames: PropTypes.string,
  inputDataTid: PropTypes.string,
  inputClassNames: PropTypes.string,
  renderAddCustomerModal: PropTypes.bool,
  placeholderKey: PropTypes.string,
  // create new customer modal props
  form: PropTypes.object.isRequired,
  createCustomerPath: PropTypes.string.isRequired,
};

export default CustomerAutocompleteWithCreateModal;
