import React, { useContext, useState } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';

const FirstStep = (props) => {
  const { index, step, parsedDate } = props;

  return (
    <div className="history-timeline-block">
      <span className="history-timeline-step badge-warning text-white">
        {index+1}
      </span>
      <div className="history-timeline-content">
        <div className="d-flex justify-content-between pt-1">
          <span className="font-14">
            {step.name}
          </span>
          <span className="text-muted">
            <div className="px-2 bg-warning text-white" style={{filter: 'blur(2.5px)'}}>
              {I18n.strftime(parsedDate, "%d/%m/%Y")}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}

const MiddleStep = (props) => {
  const { index, step, parsedDate } = props;

  return (
    <div className="history-timeline-block">
      <span className="history-timeline-step badge-custom-outline-dark bg-white">
        {index+1}
      </span>
      <div className="history-timeline-content">
        <div className="d-flex justify-content-between pt-1">
          <span className="font-14">
            {step.name}
          </span>
          <span className="text-muted">
            <div className="px-2 bg-warning text-white" style={{filter: 'blur(2.5px)'}}>
              {I18n.strftime(parsedDate, "%d/%m/%Y")}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}

const LastStep = (props) => {
  const { index, step, parsedDate } = props;

  return (
    <div className="history-timeline-block">
      <span className="history-timeline-step badge-success text-white">
        {index+1}
      </span>
      <div className="history-timeline-content">
        <div className="d-flex justify-content-between pt-1">
          <span className="font-14">
            {step.name}
          </span>
          <span className="text-muted">
            <div className="px-2 bg-success text-white">
              {I18n.strftime(parsedDate, "%d/%m/%Y")}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}

const WorkflowStep = (props) => {
  const { index, lastStepIndex, endDate } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.workflow.forms.workflow_step_form', locale: localeContext.locale };

  const parsedDate = I18n.parseDate(endDate);

  let renderedComponent;
  switch(index) {
    case 0:
      renderedComponent = (<FirstStep {...props} parsedDate={parsedDate} />);
      break;
    case lastStepIndex:
      renderedComponent = (<LastStep {...props} parsedDate={parsedDate} />);
      break;
    default:
      renderedComponent = (<MiddleStep {...props} parsedDate={parsedDate} />);
      break;
  };

  return (
    <React.Fragment>
      {renderedComponent}
    </React.Fragment>
  );
};

WorkflowStep.propTypes = {

};

export default WorkflowStep;
