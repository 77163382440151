import React, { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import { areEqual } from "react-window";
import LocaleContext, { I18n } from 'Context/LocaleContext';
import Item from './Item';

// Recommended react-window performance optimisation: memoize the row render function
// Things are still pretty fast without this, but I am a sucker for making things faster
const Row = React.memo(function Row(props) {
  const { data: items, index, style } = props;
  const item = items[index];

  // We are rendering an extra item for the placeholder
  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={item.id} index={index} key={item.id}>
      {provided => <Item provided={provided} item={item} style={style} />}
    </Draggable>
  );
}, areEqual);

export const LoadMoreComponent = (props) => {
  const { column, index, fetchCards, style } = props;
  const localeContext = useContext(LocaleContext);
  const key = `load-more-${column.id}`;

  return (
    <Draggable draggableId={key} index={index} key={key} isDragDisabled={true}>
      {provided => (
        <div className='d-flex not-draggable-item py-2'
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{
            ...provided.draggableProps.style,
            ...style
          }}
        >
          <button onClick={() => fetchCards(column)} className='btn btn-outline-dark btn-sm btn-block'>
            {I18n.t('components.board.elements.row.actions.load_more', { locale: localeContext.locale })}
          </button>
        </div>
      )}
    </Draggable>
  )
}

export const EmptyHelperComponent = (props) => {
  const { column, index, style } = props;
  const localeContext = useContext(LocaleContext);
  const key = `empty-helper-${column.id}`;
  const newManufacturingRecordPath = props.links.new_manufacturing_record_path;

  return (
    <Draggable draggableId={key} index={index} key={key} isDragDisabled={true}>
      {provided => (
        <div className='d-flex not-draggable-item py-2'
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          style={{
            ...provided.draggableProps.style,
            ...style
          }}
        >
          <div className="w-100 text-center mt-5 mx-4">
            <p>{I18n.t('components.board.elements.row.empty_helper', { locale: localeContext.locale })}</p>
            <a href={newManufacturingRecordPath} className='btn btn-outline-dark btn-sm btn-block' data-tid='new-mr-record-from-board-btn'>{I18n.t('components.board.elements.row.actions.create_new_manufacturing_record', { locale: localeContext.locale })}</a>
          </div>
        </div>
      )}
    </Draggable>
  )
}


export default Row;