import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import { formProp } from '../../Helper/CommonProps';

const IngredientCostForm = (props) => {
  const {
    index, ingredientMaterialCost, costCurrency, currencyOptions, updateLineItem, totalCalculatedAmount,
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.material_cost.forms.ingredient_cost_form', locale: localeContext.locale };

  const borderClass = index == 0 ? 'border-top-0' : '';
  const renderExchangeRate = ingredientMaterialCost.currency != costCurrency;

  return (
    <tr>
      <td className={`${borderClass} align-middle`}>
        <small className="text-monospace">{index+1}</small>
      </td>
      <td className={`${borderClass} align-middle`}>
        {ingredientMaterialCost.product.name}
      </td>
      <td className={`${borderClass} align-middle`}>
        {I18n.toNumber(ingredientMaterialCost.ingredient.total_output_for_cost_calculation, { strip_insignificant_zeros: true })} {ingredientMaterialCost.product.unit}
      </td>
      <td className={`${borderClass} align-middle`}>
        <div className="input-group">
          <input value={ingredientMaterialCost.amount} className='form-control' type="number" required min="0" step="0.0001" data-tid="input-ingredient-material-cost-amount" onChange={(e) => updateLineItem(index, { ...ingredientMaterialCost, amount: e.target.value })} />
          <select className="custom-select custom-select-currency-width" value={ingredientMaterialCost.currency} onChange={(e) => updateLineItem(index, { ...ingredientMaterialCost, currency: e.target.value })} data-tid="input-ingredient-unit-selection">
            {currencyOptions.map(currencyObject => {
              return <option value={currencyObject.value} key={currencyObject.value}>{currencyObject.label}</option>
            })}
          </select>
        </div>
        {
          renderExchangeRate && (
            <div className="form-group row mt-2">
              <div className="col-12">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">1 {ingredientMaterialCost.currency}</span>
                    <span className="input-group-text">=</span>
                  </div>
                  <input value={ingredientMaterialCost.exchange_rate} className='form-control' type="number" required min="0" step="0.0001" data-tid="input-ingredient-material-cost-exchange-rate" onChange={(e) => updateLineItem(index, { ...ingredientMaterialCost, exchange_rate: e.target.value })} />
                  <div className="input-group-append">
                    <span className="input-group-text">{costCurrency}</span>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </td>
      <td className={`${borderClass} align-middle`}>
        <span className="d-flex justify-content-end pr-2">{I18n.toNumber(totalCalculatedAmount, { strip_insignificant_zeros: true })} {costCurrency}</span>
      </td>
    </tr>
  );
};

IngredientCostForm.propTypes = {
};

export default IngredientCostForm;
