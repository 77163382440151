import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PresetDateRangePickerHelper from '../../Helper/PresetDateRangePicker';
import { isNull } from 'lodash';

const PresetDateRangePicker = (props) => {
  const {
    startDate, endDate, onDatesChange
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.report.shared', locale: localeContext.locale };
  moment.locale(localeContext.locale)

  const presets = [
    {
      text: I18n.t('date_range_picker.presets.this_week', SCOPE_OPTIONS),
      start: moment().startOf('week'),
      end: moment().endOf('week'),
    },
    {
      text: I18n.t('date_range_picker.presets.this_month', SCOPE_OPTIONS),
      start: moment().startOf('month'),
      end: moment().endOf('month'),
    },
    {
      text: I18n.t('date_range_picker.presets.last_week', SCOPE_OPTIONS),
      start: moment().subtract(1, 'week').startOf('week'),
      end: moment().subtract(1, 'week').endOf('week'),
    },
    {
      text: I18n.t('date_range_picker.presets.last_month', SCOPE_OPTIONS),
      start: moment().subtract(1, 'month').startOf('month'),
      end: moment().subtract(1, 'month').endOf('month'),
    }];

  useEffect(() => {
    if (isNull(startDate) && isNull(endDate)) {
      // if startDate and endDate is null we set 1 week before as a default.
      // notice that defaultState value is null if there isn't anything in the query parameters.
      onDatesChange({ startDate: moment().subtract(1, 'week'), endDate: moment() })
    }
  }, []);

  return (
    <PresetDateRangePickerHelper
      presets={presets}
      startDate={startDate}
      initialVisibleMonth={() => moment().subtract(1, 'month')} // show current month and previous month
      endDate={endDate}
      onDatesChange={onDatesChange}
      startDatePlaceholderText={I18n.t('date_range_picker.start_date_placeholder', SCOPE_OPTIONS)}
      endDatePlaceholderText={I18n.t('date_range_picker.end_date_placeholder', SCOPE_OPTIONS)} />
  )
};

export default PresetDateRangePicker;
