import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import PlanRecipeForm from './PlanRecipeForm';
import { formProp } from '../../Helper/CommonProps';

const PlanRecipeList = (props) => {
  const {
    links,
    planRecipes,
    addPlanRecipe,
    updatePlanRecipe,
    removePlanRecipe,
    saveManufacturingPlan,
    renderFormErrors,
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_manufacturing_plan.forms.plan_recipe_list', locale: localeContext.locale };
  let calculatedIdentifier = 1;

  return (
    <React.Fragment>
      <div className="row">
        <div className="offset-lg-2 col-lg-8"><hr /></div>
        <div className="col-lg-12 d-flex flex-column">
          <span className="font-20 font-weight-light text-black-50 text-center">{I18n.t('title', SCOPE_OPTIONS)}</span>
          <small className="text-center text-black-50">{I18n.t('info', SCOPE_OPTIONS)}</small>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          {
            planRecipes.map((planRecipe, i) => (
              <PlanRecipeForm
                key={i}
                index={i}
                planRecipe={planRecipe}
                updatePlanRecipe={updatePlanRecipe}
                removePlanRecipe={removePlanRecipe}
                calculatedIdentifier={(planRecipe.deleted ? null : calculatedIdentifier++)}
                renderFormErrors={renderFormErrors}
                links={links}
              />
            ))
          }
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className="btn btn-outline-secondary btn-sm" onClick={addPlanRecipe} data-tid="add-new-plan-recipe-btn">
            <i className="fa fa-plus mr-2" />
            {I18n.t('actions.add', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

PlanRecipeList.propTypes = {
  links: PropTypes.shape({
    recipe_search_path: PropTypes.string.isRequired,
    new_recipe_path: PropTypes.string.isRequired,
  }).isRequired,
  planRecipes: PropTypes.arrayOf(PropTypes.shape({
    recipe: PropTypes.object,
    product: PropTypes.object,
    quantity: PropTypes.any,
  })),
  addPlanRecipe: PropTypes.func.isRequired,
  updatePlanRecipe: PropTypes.func.isRequired,
  removePlanRecipe: PropTypes.func.isRequired,
  saveManufacturingPlan: PropTypes.func.isRequired,
  renderFormErrors: PropTypes.bool.isRequired,
};

export default PlanRecipeList;
