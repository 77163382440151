import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import EditRecordDetailForm from './Forms/EditRecordDetailForm';
import EditRecordIngredients from './Forms/EditRecordIngredients';
import SubmitForm from './Forms/SubmitForm';
import { validIngredients, validInflowIngredients } from '../Helper/Validators/IngredientValidator';
import { validDate } from '../Helper/Validators/DateValidator';
import useIngredientManagerReducer from './Hooks/UseIngredientManagerReducer';
import LocaleContext, { LocaleProp } from 'Context/LocaleContext';

const EditForm = (props) => {
  const {
    form, manufacturing_record, recipe, feature_flags, links, translation, units
  } = props;

  //
  // MANUFACTURING RECORD BASE INFO
  //
  const [product, setProduct] = useState(manufacturing_record.product || {});
  const [quantity, setQuantity] = useState(manufacturing_record.quantity);
  const [issueDate, setIssueDate] = useState(manufacturing_record.issue_date);
  const [note, setNote] = useState(manufacturing_record.note);
  const [description, setDescription] = useState(manufacturing_record.description);
  const [autoCalculateQuantity, setAutoCalculateQuantity] = useState(true);
  const calculateUsage = (quantity, spentPerUnit) => parseFloat((quantity * Number(spentPerUnit)).toFixed(4));
  const updateQuantity = (quantity) => {
    if (recipe && autoCalculateQuantity) {
      ingredients.forEach((ingredient, i) => {
        // this will only update ingredients come from recipe.
        if (ingredient.spent_per_used_unit) {
          updateIngredient(i, {
            ...ingredient, quantity: calculateUsage(quantity, ingredient.spent_per_used_unit),
          });
        }
      });
    }
    setQuantity(quantity);
  };
  //
  // MANUFACTURING RECORD RELATED
  //
  const emptyIngredient = { product: {}, quantity: '', used_unit: '' };

  // INGREDIENTS
  const initialIngredientState = (manufacturing_record.ingredients.length > 0 ? manufacturing_record.ingredients : [emptyIngredient]);
  const [ingredients, dispatchIngredients] = useIngredientManagerReducer(initialIngredientState, emptyIngredient);
  const addIngredient = () => dispatchIngredients({ type: 'add' });
  const updateIngredient = (index, ingredient) => dispatchIngredients({ type: 'update', index, ingredient });
  const removeIngredient = (index, ingredient) => {
    dispatchIngredients({ type: 'update', index, ingredient: { ...ingredient, deleted: true } });
  };

  // INFLOW_INGREDIENTS
  const initialInflowIngredientState = (manufacturing_record.inflow_ingredients.length > 0 ? manufacturing_record.inflow_ingredients : []);
  const [inflowIngredients, dispatchInflowIngredients] = useIngredientManagerReducer(initialInflowIngredientState, emptyIngredient);
  const addInflow = () => dispatchInflowIngredients({ type: 'add' });
  const updateInflow = (index, inflowIngredient) => dispatchInflowIngredients({ type: 'update', index, ingredient: inflowIngredient });
  const removeInflow = (index, inflowIngredient) => {
    dispatchInflowIngredients({ type: 'update', index, ingredient: { ...inflowIngredient, deleted: true } });
  };

  // form & validations
  const [renderFormErrors, setRenderFormErrors] = useState(false);
  const submitFormRef = useRef(null);
  const saveIngredients = () => {
    setRenderFormErrors(true);
    if (validInflowIngredients(inflowIngredients) && validIngredients(ingredients) && validDate(issueDate)) {
      submitFormRef.current.submit();
      return true;
    }
    return false;
  };

  const detailComponent = (
    <EditRecordDetailForm
      form={form}
      links={links}
      product={product}
      setProduct={setProduct}
      quantity={quantity}
      setQuantity={updateQuantity}
      issueDate={issueDate}
      setIssueDate={setIssueDate}
      description={description}
      setDescription={setDescription}
      note={note}
      setNote={setNote}
      recipe={recipe}
      autoCalculateQuantity={autoCalculateQuantity}
      setAutoCalculateQuantity={setAutoCalculateQuantity}
      allowUnitConversion={feature_flags.feature_conversion}
      inflowIngredients={inflowIngredients}
      addInflow={addInflow}
      updateInflow={updateInflow}
      removeInflow={removeInflow}
      renderFormErrors={renderFormErrors}
      units={units}
    />
  );
  const ingredientsComponent = (
    <EditRecordIngredients
      links={links}
      form={form}
      ingredients={ingredients}
      addIngredient={addIngredient}
      updateIngredient={updateIngredient}
      removeIngredient={removeIngredient}
      allowUnitConversion={feature_flags.feature_conversion}
      renderFormErrors={renderFormErrors}
      saveManufacturingRecord={saveIngredients}
      units={units}
    />
  );

  return (
    <LocaleContext.Provider value={translation}>
      {detailComponent}
      {ingredientsComponent}
      {/* for autocomplete list result, we need to expand bottom */}
      <div style={{ marginTop: '250px' }}>
        <SubmitForm
          form={form}
          product={product}
          quantity={quantity}
          issueDate={issueDate}
          note={note}
          ingredients={ingredients}
          ref={submitFormRef}
          description={description}
          recipe={recipe}
          inflowIngredients={inflowIngredients}
        />
      </div>
    </LocaleContext.Provider>
  );
};

EditForm.propTypes = {
  manufacturing_record: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  recipe: PropTypes.object,
  feature_flags: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  translation: LocaleProp.isRequired,
  units: PropTypes.array.isRequired,
};

export default EditForm;
