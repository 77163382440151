import React from "react"
import PropTypes from "prop-types"
import { Form as ReactForm, Text } from 'react-form'
import Product from './FormElement/Product'
import Ingredient from './FormElement/Ingredient'
import IngredientForm from './FormElement/IngredientForm'

class Form extends React.Component {
  constructor(props) {
    super(props);
    // console.log('recipe', this.props.recipe)
  }

  formDefaultValues() {
    return {
      description: this.props.recipe.description,
      output_quantity: this.props.recipe.output_quantity,
      ingredients: this.props.recipe.ingredients
    }
  }

  markAsDeleted(formApi, i, ingredient) {
    formApi.removeValue('ingredients', i)
    ingredient.deleted = true
    formApi.addValue('ingredients', ingredient)
  }

  ingredientTableBody(formApi) {
    let calculatedIdentifier = 1;
    return formApi.values.ingredients.map( ( ingredient, i ) => (
      <Ingredient
        key={`ingredient${i}`}
        ingredient={ingredient}
        i={i}
        calculatedIdentifier={(ingredient.deleted ? null : calculatedIdentifier++)}
        remove={() => (this.markAsDeleted(formApi, i, ingredient) )} />
    ))
  }

  render () {
    const { form, recipe, feature_flags } = this.props;

    return (
      <ReactForm defaultValues={this.formDefaultValues()}>
      { formApi => (
        <form action={form.action} method="post" data-remote={true} data-turbolinks-form={true} data-tid="recipe-form" data-element="recipe-form">
          <input type='hidden' name={form.csrf_param} value={form.csrf_token} />
          <input name="utf8" type="hidden" value="✓" />
          <input type="hidden" name="_method" value={form.method} />
          <div className="form-group row">
            <label htmlFor="recipe_description" className="col-3 col-form-label">Reçete Açıklaması</label>
            <div className="col-9">
              <Text field='description' name='recipe[description]' className="form-control" data-tid="input-recipe-description" />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">Mamul</label>
            <div className="col-9">
              <Product product={recipe.product} searchPath={form.product_search_path} parasutProductNewUrl={form.parasut_product_new_url} />
            </div>
          </div>

          <div className='form-group'>
            <span>Malzeme Listesi</span>
            { formApi.values.ingredients && formApi.values.ingredients.length > 0 &&
              (<div className='table-responsive mt-2'>
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Malzeme Adı</th>
                      <th>Miktar</th>
                      <th>Birim</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.ingredientTableBody(formApi)}
                  </tbody>
                </table>
              </div>)
            }
          </div>

          <div className='form-group'>
            <IngredientForm
              searchPath={form.product_search_path}
              parasutProductEditUrl={form.parasut_product_edit_url}
              parasutProductNewUrl={form.parasut_product_new_url}
              addIngredient={(ingredient) => formApi.addValue('ingredients', ingredient) }
              conversionDetailsPath={form.conversion_details_path}
              allowUnitConversion={feature_flags.feature_conversion}
            />
          </div>

          <hr />
          <button type="submit" className='btn btn-primary col-3 pull-right' data-tid="recipe-submit-btn">
            <i className="fa fa-spinner fa-spin mr-1 d-none" data-behavior='loading' />
            Kaydet
          </button>
        </form>
      )}
      </ReactForm>
    )
  }
}

Form.propTypes = {
  recipe: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  feature_flags: PropTypes.object.isRequired,
}

export default Form
