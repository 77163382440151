import React, { useContext, useState } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';

const WorkflowStepForm = (props) => {
  const {
    index,
    step,
    updateStep,
    deleteStep,
    invalid,
    renderFormErrors
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.workflow.forms.workflow_step_form', locale: localeContext.locale };

  return (
    <React.Fragment>
      <div className="line-item mt-2 mx-4 mx-lg-0">
        <div className="d-flex">
          <input value={step.stepName} onChange={(e) => updateStep(index, { ...step, stepName: e.target.value })} className={`form-control flex-grow-1`} data-tid="input-workflow-step-name" placeholder={I18n.t('step_placeholder', SCOPE_OPTIONS)} />

          <div className="ml-3" style={{ maxWidth: '250px' }}>
            <div className="input-group">
              <input value={step.quantity || ''} onChange={(e) => updateStep(index, { ...step, quantity: e.target.value })} className={`form-control ${renderFormErrors && invalid && 'is-invalid'}`} type="number" required min="0" step="1" data-tid="input-workflow-step-quantity" placeholder={I18n.t('quantity_placeholder', SCOPE_OPTIONS)} />
              <div className="input-group-append">
                <span className="input-group-text">{I18n.t('days', SCOPE_OPTIONS)}</span>
              </div>
              <div className="invalid-feedback">
                {I18n.t('quantity.feedback', SCOPE_OPTIONS)}
              </div>
            </div>
          </div>

          <div className="col-form-label ml-3">
            <button type="button" className="close close-danger" data-dismiss="alert" aria-label="Close" onClick={() => deleteStep(index)} data-tid="remove-workflow-step-btn">
              <i className="fas fa-times" />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

WorkflowStepForm.propTypes = {

};

export default WorkflowStepForm;
