import React, { useState, useRef, useContext } from "react"
import PropTypes from "prop-types"
import LocaleContext, { I18n } from 'Context/LocaleContext';
import { isInvalid, isInvalidForQuantity, isInvalidForStockOut } from './Validators';

const LineItem = (props) => {
  const { lineItem, calculatedIdentifier, updateLineItem, index } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.customer_order.outflow_shipment_document.modal', locale: localeContext.locale };

  const formatter = new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 4 });
  const productUnit = lineItem.product.unit ? lineItem.product.unit : "";

  return (
    <tr>
      <td className="align-middle">{calculatedIdentifier}</td>
      <td className="align-middle">{lineItem.product.name}</td>
      <td className="align-middle">{`${formatter.format(lineItem.source_external_info.line_item_quantity)} ${productUnit}`}</td>
      <td className="align-middle">{`${formatter.format(lineItem.remaining_quantity)} ${productUnit}`}</td>
      <td className="align-middle">
        <input value={lineItem.quantity || ''} onChange={(e) => updateLineItem(index, { ...lineItem, quantity: e.target.value })} className={`form-control ${isInvalid(lineItem) ? 'is-invalid' : ''}`} type="number" required min="0" step="1" data-tid="input-customer-order-shipment-document-quantity" placeholder={I18n.t('quantity_placeholder', SCOPE_OPTIONS)} />
        { isInvalidForQuantity(lineItem) &&
          <div className="invalid-feedback">
            {I18n.t('errors.quantity_cant_be_negative', SCOPE_OPTIONS)}
          </div>
        }
        { isInvalidForStockOut(lineItem) &&
          <div className="invalid-feedback">
            {I18n.t('errors.stock_not_available', {...SCOPE_OPTIONS, available_stock: `${formatter.format(lineItem.source_external_info.product_stock_count)} ${productUnit}` })}
          </div>
        }
      </td>
    </tr>
  );
}

LineItem.propTypes = {
  index: PropTypes.number.isRequired,
  lineItem: PropTypes.object.isRequired,
  calculatedIdentifier: PropTypes.number,
  updateLineItem: PropTypes.func.isRequired,
}

export default LineItem;
