import React from 'react';
import PropTypes from 'prop-types';

const calculateTotal = (quantity, spentPerUnit) => {
  // parseFloat is needed to get rid of trailing zeros
  return parseFloat((quantity * Number(spentPerUnit)).toFixed(4));
}

const IngredientFormItem = (props) => {
  const { ingredient, i, quantity } = props;
  const calculatedQuantity = calculateTotal(quantity, ingredient.spent_per_used_unit);

  return (
    <React.Fragment>
      <tr>
        <td>
          {ingredient.product.name}
        </td>
        <td>{ingredient.spent_per_used_unit}</td>
        <td>x</td>
        <td>{quantity}</td>
        <td>=</td>
        <td>{calculatedQuantity} {ingredient.used_unit}
          { /* independent record ingredient begins */ }
          <input type='hidden' value={ingredient.id || ''} name={`manufacturing_record[ingredients_attributes][${i}][id]`} />
          <input type='hidden' value={ingredient.product.id} name={`manufacturing_record[ingredients_attributes][${i}][product][id]`} />
          <input type='hidden' value={ingredient.product.name} name={`manufacturing_record[ingredients_attributes][${i}][product][name]`} />
          <input type='hidden' value={ingredient.product.unit || ''} name={`manufacturing_record[ingredients_attributes][${i}][product][unit]`} />
          <input type='hidden' value={ingredient.product.parasut_id} name={`manufacturing_record[ingredients_attributes][${i}][product][parasut_id]`} />
          <input type='hidden' value={calculatedQuantity} name={`manufacturing_record[ingredients_attributes][${i}][quantity]`} />
          <input type='hidden' value={ingredient.used_unit} name={`manufacturing_record[ingredients_attributes][${i}][used_unit]`} />
          <input type='hidden' value={ingredient.spent_per_used_unit} name={`manufacturing_record[ingredients_attributes][${i}][spent_per_used_unit]`} />
          <input type='hidden' value={ingredient.deleted || false} name={`manufacturing_record[ingredients_attributes][${i}][deleted]`} />
          { /* independent record ingredient ends */ }
         </td>
      </tr>
    </React.Fragment>
  );
}

IngredientFormItem.propTypes = {
  ingredient: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  i: PropTypes.number.isRequired,
};

export default IngredientFormItem;