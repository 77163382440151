import React, { useState, useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { statusHandler } from '../../Helper/RequestHelpers';

Modal.setAppElement('#react-modal-render-wormhole');

const DestroyColumnModal = (props) => {
  const { isModalOpened, setIsModalOpened, column, onUpdate } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.board.elements.destroy_column_modal', locale: localeContext.locale };
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const destroyColumn = () => {
    const { action, method, csrf_token } = column.links.destroy.form;
    fetch(action, {
      credentials: 'same-origin',
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token
      },
    }).then(statusHandler).then((response) => {
      return response.json()
    }).then((json) => {
      setLoading(false);
      setShowError(false);
      setIsModalOpened(false);
      onUpdate();
    }).catch((ex) => {
      setLoading(false);
      setShowError(true);
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    destroyColumn();
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isModalOpened}
        className="modal show"
        style={{ overlay: {}, content: { display: 'block' } }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title font-20">{I18n.t('title', SCOPE_OPTIONS)}</span>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" data-tid="destroy-column-modal-dismiss-btn" onClick={() => setIsModalOpened(false)}>
                <span aria-hidden="true"><i className="fa fa-close" /></span>
              </button>
            </div>
            <div className="modal-body">
              {showError && (
                <div className="card text-white bg-danger mb-3">
                  <div className="card-body">
                    <span className="card-text">{I18n.t('error', SCOPE_OPTIONS)}</span>
                  </div>
                </div>
              )}
              <div className="form-group">
                <p>{I18n.t('actions.destroy_alert', SCOPE_OPTIONS)}</p>
              </div>
              <div className="form-group d-flex justify-content-end">
                <button type="submit" className={`btn btn-info btn-lg btn-danger ${loading ? 'disabled' : ''}`} onClick={handleSubmit} disabled={loading} data-tid="destroy-column-submit-btn">
                  <i className={`fas fa-spinner fa-spin mr-1 ${loading ? '' : 'd-none'}`} />
                  {I18n.t('actions.destroy', SCOPE_OPTIONS)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

DestroyColumnModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  setIsModalOpened: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default DestroyColumnModal;
