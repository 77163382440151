import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import qs from 'qs';
import useEventListener from '@use-it/event-listener';

const SearchBar = (props) => {
  const { searchPath, currentQueryParameters, i18nScope } = props;

  const [searchQuery, setSearchQuery] = useState(currentQueryParameters['query']);
  const generateFilterQuery = () => {
    return qs.stringify({ ...currentQueryParameters, query: searchQuery, page: 1 });
  }

  const enterHandler = (key) => {
    if (key.keyCode === 13) {
      searchBtnRef.current.click();
    }
  }

  const submitSearchRef = useRef(null);
  useEventListener('keydown', enterHandler, submitSearchRef.current);
  const searchBtnRef = useRef(null);

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: i18nScope, locale: localeContext.locale };

  return (
    <div className="flex-grow-1" data-tid="customer-order-search-form">
      <div className="input-group">
        <input
          type="text"
          value={searchQuery || ''}
          className="form-control"
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={I18n.t('placeholder', SCOPE_OPTIONS)}
          ref={submitSearchRef}
        />
        <div className="input-group-append">
          <a href={`${searchPath}?${generateFilterQuery()}`} className="btn btn-outline-info" ref={searchBtnRef}>
            {I18n.t('search_btn_text', SCOPE_OPTIONS)}
          </a>
        </div>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  currentQueryParameters: PropTypes.object.isRequired,
  searchPath: PropTypes.string.isRequired,
  i18nScope: PropTypes.string.isRequired
};

export default SearchBar;