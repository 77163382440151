import React from "react"
import PropTypes from "prop-types"

class Ingredient extends React.Component {
  render () {
    const {ingredient, i, remove, calculatedIdentifier} = this.props;
    return (
      <tr className={ ingredient.deleted ? 'd-none' : ''}>
        <td>{calculatedIdentifier}</td>
        <td>{ingredient.product.name}</td>
        <td>{ingredient.quantity}</td>
        <td>{ingredient.used_unit}</td>
        <td>
          <button type="button" className="close text-danger" data-dismiss="alert" aria-label="Close" onClick={remove} data-tid="remove-ingredient-btn">
            <span aria-hidden="true">&times;</span>
          </button>

          <input type='hidden' value={ingredient.id} name={`recipe[ingredients_attributes][${i}][id]`} />
          <input type='hidden' value={ingredient.product.id} name={`recipe[ingredients_attributes][${i}][product][id]`} />
          <input type='hidden' value={ingredient.product.name} name={`recipe[ingredients_attributes][${i}][product][name]`} />
          <input type='hidden' value={ingredient.product.unit || ''} name={`recipe[ingredients_attributes][${i}][product][unit]`} />
          <input type='hidden' value={ingredient.product.parasut_id} name={`recipe[ingredients_attributes][${i}][product][parasut_id]`} />
          <input type='hidden' value={ingredient.quantity} name={`recipe[ingredients_attributes][${i}][quantity]`} />
          <input type='hidden' value={ingredient.used_unit} name={`recipe[ingredients_attributes][${i}][used_unit]`} />
          <input type='hidden' value={ingredient.deleted || false} name={`recipe[ingredients_attributes][${i}][deleted]`} />
        </td>
      </tr>
    )
  }
}

Ingredient.propTypes = {
  ingredient: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  calculatedIdentifier: PropTypes.number,
}

export default Ingredient
