import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import { validProduct } from '../../Helper/Validators/IngredientValidator';
import ProductAutocompleteWithCreateModal from '../../Product/ProductAutocompleteWithCreateModal';

const EditRecipeForm = (props) => {
  const {
    form,
    outputQuantity,
    setOutputQuantity,
    product,
    setProduct,
    description,
    setDescription,
    recipePath,
    units,
    links
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_recipe.forms.edit_recipe_form', locale: localeContext.locale };
  const unit = product.unit || I18n.t('unit', SCOPE_OPTIONS);

  return (
    <React.Fragment>
      <div className="row mt-5">
        <div className="offset-lg-4 col-lg-5 d-flex">
          <h1 className="flex-grow-1">{I18n.t('title', SCOPE_OPTIONS)}</h1>
          <a
            href={recipePath}
            className="align-self-center btn"
            data-confirm={I18n.t('actions.cancel_alert', SCOPE_OPTIONS)}
            data-tid="cancel-from-recipe-product-selection"
          >
            <i className="fa fa-close fa-2x" />
          </a>
        </div>
      </div>

      <div className="row mt-5">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <span className="text-center font-20 text-black-50 font-weight-light">
            {I18n.t('description', SCOPE_OPTIONS)}
          </span>
        </div>

        <div className="offset-lg-2 col-lg-8 mt-4">
          <input
            value={description || ''}
            onChange={e => setDescription(e.target.value)}
            className="form-control form-control-lg"
            type="text"
            required
            data-tid="input-recipe-description"
            placeholder={I18n.t('placeholder', SCOPE_OPTIONS)}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8">
          <hr />
        </div>
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <span className="text-center font-20 text-black-50 font-weight-light">
            {I18n.t('product', SCOPE_OPTIONS)}
          </span>
        </div>

        <div className="offset-lg-2 col-lg-8 mt-4">
          <div className="d-flex row justify-content-center">
            <ProductAutocompleteWithCreateModal
              units={units}
              product={product}
              onProductSelect={(product) => {
                setProduct(product);
              }}
              searchPath={form.product_search_path}
              topClassNames="col-lg-7"
              wrapperClassNames=""
              parasutProductNewUrl={form.parasut_product_new_url}
              inputDataTid="input-product-autocomplete"
              inputClassNames="form-control-lg"
              form={form}
              createProductPath={form.create_product_path}
              showBuyingPriceInput={false}
              links={links}
            />
            {validProduct(product) && (
              <div className="col-lg-4 col-10">
                <div
                  className="input-group"
                  data-tid="input-product-output-quantity-group"
                >
                  <input
                    value={outputQuantity || ''}
                    onChange={e => setOutputQuantity(e.target.value)}
                    className="form-control form-control-lg"
                    type="number"
                    required
                    min="0"
                    step="0.0001"
                    data-tid="input-product-quantity"
                    placeholder={I18n.t('quantity', SCOPE_OPTIONS)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">{unit}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

EditRecipeForm.propTypes = {
  form: PropTypes.object.isRequired,
  product: PropTypes.object,
  outputQuantity: PropTypes.node,
  description: PropTypes.node,
  setOutputQuantity: PropTypes.func.isRequired,
  setProduct: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  recipePath: PropTypes.string.isRequired,
  units: PropTypes.array.isRequired,
  links: PropTypes.object.isRequired
};

export default EditRecipeForm;
