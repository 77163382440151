import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';

const Column = (props) => {
  const {
    values, selected, onChange, i18nScope
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: i18nScope, locale: localeContext.locale };
  const currentValue = selected || "DEFAULT";

  return (
    <select value={currentValue} className="custom-select custom-select-sm" onChange={onChange} data-tid="filterable-column-select">
      <option value="DEFAULT" disabled>{I18n.t('column.default_option', SCOPE_OPTIONS)}</option>
      {
        Object.keys(values).map((column, i) => {
          return (
            <option key={`column-${i}`} value={column} data-tid={`filterable-column-select-option-${column}`}>
              {I18n.t(`column.values.${column}`, SCOPE_OPTIONS)}
            </option>
          )
        })
      }
    </select>
  )
};

Column.propTypes = {
  values: PropTypes.object.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  i18nScope: PropTypes.string.isRequired,
};

export default Column;
