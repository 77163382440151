import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PresetAutocomplete from '../Helper/PresetAutocomplete';

const PresetAutocompleteInput = (props) => {
  const {
    placeholder,
    selected,
    options,
    inputDataTid,
    inputName,
    isRequired,
    inputClassNames,
    labelClassNames,
    renderLabel,
  } = props;

  const [value, setValue] = useState(selected || '');
  const object = options.find(option => option.value === value) || {}; // if nothing found return an empty object.

  return (
    <PresetAutocomplete
      options={options}
      placeholder={placeholder}
      value={object.label ?? value}
      onChange={value => setValue(value)}
      onSelect={value => setValue(value)}
      inputDataTid={inputDataTid}
      inputClassNames={inputClassNames}
      renderInput={(props) => (
        <div className={`${renderLabel ? 'form-label-group' : ''}`}>
          <input {...props} id={inputName} required={isRequired} autoComplete='chrome-off' />
          {renderLabel && (
            <label className={labelClassNames} htmlFor={inputName}>{placeholder}</label>
          )}
          <input type="hidden" name={inputName} value={object.value ?? value} />
        </div>
      )}
    />
  );
};

PresetAutocompleteInput.propTypes = {
  placeholder: PropTypes.string,
  selected: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  inputClassNames: PropTypes.string,
  inputDataTid: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  labelClassNames: PropTypes.string,
  isRequired: PropTypes.bool,
  renderLabel: PropTypes.bool
};

PresetAutocompleteInput.defaultProps = {
  isRequired: false,
  renderLabel: true
};

export default PresetAutocompleteInput;
