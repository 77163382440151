import React from 'react';
import PropTypes from 'prop-types';
import { formProp } from '../../Helper/CommonProps';

const SubmitForm = React.forwardRef((props, ref) => {
  const {
    form,
    lineItems,
    costCurrency
  } = props;

  const formKey = 'material_cost_form';
  const lineItemInputName = index => `${formKey}[ingredient_material_costs_attributes][${index}]`;

  return (
    <React.Fragment>
      <form action={form.action} method="post" data-remote={true} data-turbolinks-form={true} data-tid="recipe-material-cost-form" data-element="recipe-material-cost-form" ref={ref}>
        <input type="hidden" name={form.csrf_param} value={form.csrf_token} />
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value={form.method} />

        <input type="hidden" value={costCurrency || ''} name={`${formKey}[total_cost_currency]`} />

        {lineItems.map((lineItem, i) => (
          <React.Fragment key={`line-item-values-${i}`}>
            <input type="hidden" value={lineItem.amount || ''} name={`${lineItemInputName(i)}[amount]`} />
            <input type="hidden" value={lineItem.currency || ''} name={`${lineItemInputName(i)}[currency]`} />
            <input type="hidden" value={lineItem.exchange_rate || ''} name={`${lineItemInputName(i)}[exchange_rate]`} />
            <input type="hidden" value={lineItem.ingredient.id || ''} name={`${lineItemInputName(i)}[ingredient_id]`} />
          </React.Fragment>
        ))}
      </form>
    </React.Fragment>
  );
});

SubmitForm.propTypes = {
  form: formProp.isRequired,
  costCurrency: PropTypes.string.isRequired,
  lineItems: PropTypes.array.isRequired,
};

export default SubmitForm;
