import React from "react"
import PropTypes from "prop-types"
import { isEmpty } from 'lodash'
import RecipeAutocomplete from './../RecipeAutocomplete';

class PlanRecipeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { recipe: {}, quantity: '', error: false }

    this.handleQuantity = this.handleQuantity.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onRecipeSelect = this.onRecipeSelect.bind(this);
  }

  onRecipeSelect(recipe) {
    this.setState({ recipe });
  }

  handleQuantity(event) {
    this.setState({ 'quantity': event.target.value });
  }

  formValid() {
    const { recipe, quantity } = this.state;
    if(isEmpty(recipe)) {
      return 'recipe'
    } else if(quantity === '') {
      return 'quantity'
    }
    return true;
  }

  handleClick(e) {
    e.preventDefault();
    if(this.formValid() === true) {
      this.props.addPlanRecipe({
        recipe: this.state.recipe,
        product: this.state.recipe.product,
        quantity: parseFloat(Number(this.state.quantity).toFixed(4)),
      });
      this.resetState();
    } else {
      this.setState({ error: true })
    }
  }

  resetState() {
    this.setState({ recipe: {}, quantity: '', error: false })
  }

  getRecipeUnitPresenter(recipe) {
    if(recipe && recipe.product && recipe.product.unit) {
      return recipe.product.unit;
    } else {
      return 'Birim';
    }
  }

  render () {
    const { recipe, error } = this.state;
    const { searchPath, newRecipePath } = this.props;

    return (
      <RecipeAutocomplete
        recipe={recipe}
        onRecipeSelect={this.onRecipeSelect}
        searchPath={searchPath}
        topClassNames='row'
        wrapperClassNames='col-7'
        inputDataTid="input-plan-recipe-autocomplete"
        newRecipeUrl={newRecipePath}
        >

        <div className="col-5">
          <div className="input-group">
            <input value={this.state.quantity} onChange={this.handleQuantity} className="form-control" placeholder='Miktar' type="number" min="0" step="0.0001" data-tid="input-plan-recipe-quantity" />
            <div className="input-group-append">
              <span className="input-group-text">{this.getRecipeUnitPresenter(recipe)}</span>
            </div>
            <a href="#" onClick={this.handleClick} className='btn btn-outline-info ml-2' data-tid="add-new-plan-recipe-btn">Ekle</a>
          </div>
        </div>
        { error &&
          <div className="col mt-1">
            {this.renderErrorDetail()}
          </div>
        }
      </RecipeAutocomplete>
    );
  }

  renderErrorDetail() {
    switch(this.formValid()) {
      case 'recipe':
        return <span className='text-danger'>Reçete seçmeniz gerekmekte!</span>;
      case 'quantity':
        return <span className='text-danger'>Miktar girmeniz gerekmekte!</span>;
      default:
        return '';
    }
  }
}

PlanRecipeForm.propTypes = {
  searchPath: PropTypes.string.isRequired,
  addPlanRecipe: PropTypes.func.isRequired,
  newRecipePath: PropTypes.string,
}

export default PlanRecipeForm
