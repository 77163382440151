import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import PlanExpenditureForm from './PlanExpenditureForm';

const PlanExpenditureList = (props) => {
  const {
    planExpenditures,
    addPlanExpenditure,
    updatePlanExpenditure,
    removePlanExpenditure,
    renderFormErrors,
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_manufacturing_plan.forms.plan_expenditure_list', locale: localeContext.locale };
  let calculatedIdentifier = 1;

  return (
    <React.Fragment>
      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8"><hr /></div>
        <div className="col-lg-12 d-flex flex-column">
          <span className="font-20 font-weight-light text-black-50 text-center">{I18n.t('title', SCOPE_OPTIONS)}</span>
          <small className="text-center text-black-50">{I18n.t('info', SCOPE_OPTIONS)}</small>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          {
            planExpenditures.map((planExpenditure, i) => (
              <PlanExpenditureForm
                key={i}
                index={i}
                planExpenditure={planExpenditure}
                updatePlanExpenditure={updatePlanExpenditure}
                removePlanExpenditure={removePlanExpenditure}
                calculatedIdentifier={(planExpenditure.deleted ? null : calculatedIdentifier++)}
                renderFormErrors={renderFormErrors}
              />
            ))
          }
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className="btn btn-outline-secondary btn-sm" onClick={addPlanExpenditure} data-tid="add-more-plan-expenditure-btn">
            <i className="fa fa-plus mr-2" />
            {I18n.t('actions.add', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>

    </React.Fragment>
  );
};

PlanExpenditureList.propTypes = {
  planExpenditures: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    expected_currency: PropTypes.any,
    currency: PropTypes.string,
  })).isRequired,
  addPlanExpenditure: PropTypes.func.isRequired,
  updatePlanExpenditure: PropTypes.func.isRequired,
  removePlanExpenditure: PropTypes.func.isRequired,
  renderFormErrors: PropTypes.bool.isRequired,
};

export default PlanExpenditureList;
