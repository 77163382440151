import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CreateNewCustomerModal from './CreateNewCustomerModal';
import SearchBar from '../Searchable/SearchBar';
import LocaleContext, { LocaleProp, I18n } from 'Context/LocaleContext';

const IndexHeader = (props) => {
  const {
    links, translation, currentQueryParameters, can_create_customer
  } = props;

  const SCOPE_OPTIONS = { scope: 'components.customer.index_header', locale: translation.locale };

  const [isModalOpened, setIsModalOpened] = useState(false);

  const goToCustomerShowPage = ({ links }) => {
    window.Turbolinks.visit(links.customer_show_path);
  };

  return (
    <LocaleContext.Provider value={translation}>
      <div className="d-flex">
        <SearchBar
          currentQueryParameters={currentQueryParameters}
          searchPath={links.customer_search_path}
          i18nScope='components.customer.search_bar'
        />

        <div className="ml-2">
          {can_create_customer ?
            (<button
              type="button"
              className="btn btn-outline-primary btn-block"
              data-tid="customer-index-new-customer-btn"
              onClick={() => setIsModalOpened(true)}>
              {I18n.t('actions.new', SCOPE_OPTIONS)}
            </button>) : (<button
              type="button"
              className="btn btn-outline-primary btn-block"
              data-tid="customer-index-new-customer-btn"
              data-toggle="tooltip"
              data-original-title={I18n.t('tooltips.not_allowed_to_create', SCOPE_OPTIONS)}
              data-title={I18n.t('tooltips.not_allowed_to_create', SCOPE_OPTIONS)}
            >{I18n.t('actions.new', SCOPE_OPTIONS)}
            </button>
            )
          }
        </div>
      </div>

      {isModalOpened && (
        <CreateNewCustomerModal
          isModalOpened={isModalOpened}
          setIsModalOpened={setIsModalOpened}
          form={links.create_customer_form.form}
          onCustomerCreate={goToCustomerShowPage}
          createCustomerPath={links.create_customer_path}
          i18nScope={'components.customer.index_header.create_new_customer_modal'}
          renderContactType={true}
        />
      )}
    </LocaleContext.Provider>
  );
};

IndexHeader.propTypes = {
  links: PropTypes.shape({
    customer_search_path: PropTypes.string.isRequired,
    create_customer_path: PropTypes.string.isRequired,
    create_customer_form: PropTypes.shape({
      form: PropTypes.object.isRequired
    }).isRequired,
  }).isRequired,
  currentQueryParameters: PropTypes.object.isRequired,
  translation: LocaleProp.isRequired,
  can_create_customer: PropTypes.bool.isRequired
};

export default IndexHeader;
