import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import SupplyPlanItemForm from './SupplyPlanItemForm';

const SupplyPlanItemList = (props) => {
  const {
    supplyPlanItems,
    addNewPlanItem,
    updatePlanItem,
    deletePlanItem,
    links,
    renderFormErrors,
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.supply_planning.forms.supply_planning_line_item_list', locale: localeContext.locale };

  return (
    <React.Fragment>
      <div className="row">
        <div className="offset-lg-2 col-lg-8"><hr /></div>
        <div className="col-lg-12 d-flex flex-column">
          <span className="font-20 font-weight-light text-black-50 text-center">{I18n.t('title', SCOPE_OPTIONS)}</span>
          <small className="text-center text-black-50">{I18n.t('info', SCOPE_OPTIONS)}</small>
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8">
          {
            supplyPlanItems.map((planItem, i) => (
              <SupplyPlanItemForm
                key={i}
                index={i}
                planItem={planItem}
                updatePlanItem={updatePlanItem}
                deletePlanItem={deletePlanItem}
                links={links}
                renderFormErrors={renderFormErrors}
              />
            ))
          }
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className="btn btn-outline-secondary btn-sm" onClick={addNewPlanItem} data-tid="add-new-supply-plan-item-btn">
            <i className="fa fa-plus mr-2" />
            {I18n.t('actions.add', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

SupplyPlanItemList.propTypes = {
  supplyPlanItems: PropTypes.arrayOf(
    PropTypes.object.isRequired
  ).isRequired,
  addNewPlanItem: PropTypes.func.isRequired,
  updatePlanItem: PropTypes.func.isRequired,
  deletePlanItem: PropTypes.func.isRequired,
  links: PropTypes.shape({
    recipe_search_path: PropTypes.string.isRequired,
    new_recipe_path: PropTypes.string.isRequired,
  }).isRequired,
  renderFormErrors: PropTypes.bool.isRequired
};

export default SupplyPlanItemList;
