import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProducedProductChart from './ReportContent/ProducedProductChart';
import ConsumedProductChart from './ReportContent/ConsumedProductChart';
import SummaryTable from './ReportContent/SummaryTable';
import { GRANULARITY_OPTIONS } from '../Shared/Constants';
import moment from 'moment';
import qs from 'qs';

const ReportContent = (props) => {
  const { generateProducedReportPath, generateConsumedReportPath, generateSummaryTablePath, headerTextComponent,
    tableTextComponent, product, currentQueryParameters, refreshPageWith, consumedProducts } = props;

  const decideWhichGranularityOptionSuitsBest = () => {
    const startDate = moment(currentQueryParameters['start_date']);
    const endDate = moment(currentQueryParameters['end_date']);
    const daysBetween = endDate.diff(startDate, 'days');

    if (daysBetween <= 14) {
      return GRANULARITY_OPTIONS.DAY;
    }

    if (daysBetween <= 60) {
      return GRANULARITY_OPTIONS.WEEK;
    }

    if (daysBetween <= 365) {
      return GRANULARITY_OPTIONS.MONTH;
    }

    return GRANULARITY_OPTIONS.YEAR;
  };

  const granularity = currentQueryParameters['granularity'] || decideWhichGranularityOptionSuitsBest();
  const materials = currentQueryParameters.hasOwnProperty('materials') ? currentQueryParameters['materials'].split(',').map(Number) : [];

  const onGranularityChange = (option) => {
    return refreshPageWith(qs.stringify({ ...currentQueryParameters, granularity: option }));
  };

  const onConsumedDropdownApplyClick = (ids) => {
    // there is a bug in qs library. normally when comma seperated array is null
    // or empty, it should remove the key but it's not doing it right now.
    // so I did a workaround and will remove it when they fixed it.
    // see: https://github.com/ljharb/qs/pull/350

    const options = { arrayFormat: 'comma', skipNulls: true };

    if (ids.length == 0) {
      return refreshPageWith(qs.stringify({ ...currentQueryParameters, materials: null }, options));
    }

    return refreshPageWith(qs.stringify({ ...currentQueryParameters, materials: ids }, options));
  }

  return (
    <React.Fragment>
      <ProducedProductChart
        fetchUrl={generateProducedReportPath}
        product={product}
        currentQueryParameters={currentQueryParameters}
        headerTextComponent={headerTextComponent}
        onGranularityChange={onGranularityChange}
        granularity={granularity}
      />
      <ConsumedProductChart
        fetchUrl={generateConsumedReportPath}
        product={product}
        consumedProducts={consumedProducts}
        currentQueryParameters={currentQueryParameters}
        granularity={granularity}
        materials={materials}
        onConsumedDropdownApplyClick={onConsumedDropdownApplyClick}
      />
      <SummaryTable
        fetchUrl={generateSummaryTablePath}
        product={product}
        currentQueryParameters={currentQueryParameters}
        headerTextComponent={tableTextComponent}
        granularity={granularity}
      />
    </React.Fragment>
  )
};

ReportContent.propTypes = {
  product: PropTypes.object.isRequired,
  currentQueryParameters: PropTypes.object.isRequired,
  headerTextComponent: PropTypes.any.isRequired,
  tableTextComponent: PropTypes.any.isRequired,
  refreshPageWith: PropTypes.func.isRequired,
  generateProducedReportPath: PropTypes.string.isRequired,
  generateConsumedReportPath: PropTypes.string.isRequired,
  generateSummaryTablePath: PropTypes.string.isRequired,
  consumedProducts: PropTypes.array.isRequired,
};

export default ReportContent;
