
export const isInvalidForQuantity = lineItem => {
  return !(lineItem.quantity >= 0);
};

export const isInvalidForStockOut = lineItem => {
  const stockCount = parseFloat(Number(lineItem.source_external_info.product_stock_count).toFixed(4));
  const quantity = parseFloat(Number(lineItem.quantity).toFixed(4))
  return (quantity > stockCount);
}

export const isInvalid = lineItem => {
  if (Number(lineItem.quantity) === 0) {
    return false;
  }
  return isInvalidForQuantity(lineItem) || isInvalidForStockOut(lineItem);
}

export const isLineItemsInvalid = lineItems => {
  const invalidCount = lineItems.filter(lineItem => isInvalid(lineItem)).length;

  if (invalidCount > 0) {
    return true;
  }

  const emptyLines = lineItems.filter(lineItem => {
    const q = Number(lineItem.quantity);
    return (q === null || q === '' || q === 0);
  });

  return emptyLines.length === lineItems.length;
}