import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';

const SelectInput = (props) => {
  const {
    value, values, onChange, i18nScope
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: i18nScope, locale: localeContext.locale };
  const currentValue = value || "DEFAULT";

  return (
    <select value={currentValue} className="custom-select custom-select-sm ml-2" onChange={(e) => onChange(e.target.value)} data-tid="filterable-value-enum-select">
      <option value="DEFAULT" disabled>{I18n.t('enum.default_option', SCOPE_OPTIONS)}</option>
      {
        Object.keys(values).map((name, i) => {
          return (
            <option key={`enum-name-${i}`} value={values[name]} data-tid={`filterable-value-enum-select-option-${values[name]}`}>
              {I18n.t(`enum.values.${name}`, SCOPE_OPTIONS)}
            </option>
          )
        })
      }
    </select>
  )
};

SelectInput.propTypes = {
  value: PropTypes.any.isRequired,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  i18nScope: PropTypes.string.isRequired
};

export default SelectInput;
