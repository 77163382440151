import React from "react"
import PropTypes from "prop-types"

class PlanRecipe extends React.Component {
  render () {
    const { planRecipe, i, remove, calculatedIdentifier } = this.props;

    return (
      <tr>
        <td>{calculatedIdentifier}</td>
        <td>{planRecipe.recipe.description}</td>
        <td>{planRecipe.product.name}</td>
        <td>{planRecipe.quantity} {planRecipe.product.unit}</td>
        <td>
          <button type="button" className="close text-danger" data-dismiss="alert" aria-label="Close" onClick={remove} data-tid="remove-plan-recipe-btn">
            <span aria-hidden="true">&times;</span>
          </button>

          {/* required inputs for forms */}
          <input type='hidden' value={planRecipe.recipe.id} name={`manufacturing_plan[plan_recipes_attributes][${i}][recipe][id]`} />
          <input type='hidden' value={planRecipe.product.id} name={`manufacturing_plan[plan_recipes_attributes][${i}][product][id]`} />
          <input type='hidden' value={planRecipe.quantity} name={`manufacturing_plan[plan_recipes_attributes][${i}][quantity]`} />
          {/* representational inputs for forms when error*/}
          <input type='hidden' value={planRecipe.recipe.description} name={`manufacturing_plan[plan_recipes_attributes][${i}][recipe][description]`} />
          <input type='hidden' value={planRecipe.product.name} name={`manufacturing_plan[plan_recipes_attributes][${i}][product][name]`} />
          <input type='hidden' value={planRecipe.product.unit} name={`manufacturing_plan[plan_recipes_attributes][${i}][product][unit]`} />
        </td>
      </tr>
    );
  }
}

PlanRecipe.propTypes = {
  planRecipe: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  calculatedIdentifier: PropTypes.number.isRequired,
}

export default PlanRecipe
