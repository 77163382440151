import React, { useState, useEffect, useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import ProductAutocompleteWithCreateModal from '../../Product/ProductAutocompleteWithCreateModal';
import { validProduct, validQuantity } from '../../Helper/Validators/IngredientValidator';
import { unitConversionQueryParams } from '../../Helper/UnitConversionHelpers';

// mainly copied from IngredientForm
const RecordIngredientForm = (props) => {
  const {
    form, ingredient, updateIngredient, index, calculatedIdentifier,
    allowUnitConversion, removeIngredient, renderFormErrors, links, units
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_manufacturing_record.forms.record_ingredient_form', locale: localeContext.locale };
  const { quantity, product } = ingredient;
  const unit = ingredient.used_unit || product.unit || '';

  const onProductSelect = (product) => {
    updateIngredient(index, { ...ingredient, product, used_unit: product.unit });
  };
  const handleQuantity = e => updateIngredient(index, { ...ingredient, quantity: e.target.value });
  const markAsDeleted = () => removeIngredient(index, ingredient);
  const onUnitSelect = e => updateIngredient(index, { ...ingredient, used_unit: e.target.value });

  const defaultUnitConverion = {
    unit: (product.unit || I18n.t('unit', SCOPE_OPTIONS)), quantity: 1, per_unit: 1, key: product.unit,
  };
  const [unitConversions, setUnitConversions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchUnitConversions = async () => {
      if (!allowUnitConversion || product.parasut_id === undefined) {
        setUnitConversions([defaultUnitConverion]);
        return;
      }
      setIsError(false);
      setIsLoading(true);

      try {
        const rawResponse = await fetch(`${links.conversion_details_path}?${unitConversionQueryParams(product)}`, {
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await rawResponse.json();
        // add product current unit as a first element
        response.unshift(defaultUnitConverion);
        setUnitConversions(response);
      } catch (e) {
        setIsError(true);
        setUnitConversions([defaultUnitConverion]);
      }

      setIsLoading(false);
    };

    fetchUnitConversions();
    // do not add defaultUnitConverion as useEffect dependency, it causes infinite loop!!!
  }, [product.parasut_id, links.conversion_details_path, allowUnitConversion]);

  const productInvalid = !validProduct(product);
  const quantityInvalid = !validQuantity(quantity);

  return (
    <React.Fragment>
      <div className={`ingredient-item mt-2 ${ingredient.deleted ? 'd-none' : ''} mx-4 mx-lg-0`}>
        <div className="d-flex flex-wrap row">
          <span className="font-18 font-weight-light text-black-50 col-form-label">{calculatedIdentifier}</span>
          <ProductAutocompleteWithCreateModal links={links} units={units} product={product} onProductSelect={onProductSelect} searchPath={links.product_search_path} topClassNames="flex-grow-1 ml-3" wrapperClassNames="" parasutProductNewUrl={links.parasut_product_new_url} inputDataTid="input-ingredient-autocomplete" inputClassNames={`${renderFormErrors && productInvalid ? 'is-invalid' : ''}`} form={form} createProductPath={links.create_product_path} showBuyingPriceInput={true} inputPlaceholder={I18n.t('auto_complete.placeholder', SCOPE_OPTIONS)}>
            {renderFormErrors && productInvalid && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {I18n.t('auto_complete.feedback', SCOPE_OPTIONS)}
              </div>
            )}
          </ProductAutocompleteWithCreateModal>
          <div className="ml-3" style={{ maxWidth: '250px' }}>
            <div className="input-group">
              <input value={quantity || ''} onChange={handleQuantity} className={`form-control ${renderFormErrors && quantityInvalid && 'is-invalid'}`} type="number" required min="0" step="0.0001" data-tid="input-ingredient-quantity" placeholder={I18n.t('quantity.title', SCOPE_OPTIONS)} />
              {isLoading ? (
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fa fa-cog fa-spin" />
                  </span>
                </div>
              ) : (
                  <select className="custom-select" value={unit} onChange={onUnitSelect} data-tid="input-ingredient-unit-selection">
                    {unitConversions.map(conversion => <option value={conversion.key} key={conversion.key || 'default'}>{conversion.unit}</option>)}
                  </select>
                )}
              <div className="invalid-feedback">
                {I18n.t('quantity.feedback', SCOPE_OPTIONS)}
              </div>
            </div>
          </div>
          <div className="col-form-label ml-3">
            <button type="button" className="close close-danger" data-dismiss="alert" aria-label="Close" onClick={markAsDeleted} data-tid="remove-ingredient-btn">
              <i className="fa fa-trash" style={{ fontSize: '1.1em' }} />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

RecordIngredientForm.propTypes = {
  index: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
  ingredient: PropTypes.object.isRequired,
  updateIngredient: PropTypes.func.isRequired,
  calculatedIdentifier: PropTypes.number,
  allowUnitConversion: PropTypes.bool.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  renderFormErrors: PropTypes.bool.isRequired,
  links: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired
};

export default RecordIngredientForm;
