import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';

const PlanMaterialsTable = (props) => {
  const {
    materials
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.supply_planning.forms.plan_materials_table', locale: localeContext.locale };

  const netRequirementCount = (count, unit, status) => {
    if (status == 'initializing' || status == 'updating') {
      return (<i className="fas fa-spinner fa-spin" />);
    }

    if (status == 'done' && count > 0) {
      return (
        <span className="text-danger">
          {I18n.toNumber(count, { strip_insignificant_zeros: true })} {unit}
        </span>
      );
    }

    return '-';
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="offset-lg-2 col-lg-8"><hr /></div>
        <div className="col-lg-12 d-flex flex-column">
          <span className="font-20 font-weight-light text-black-50 text-center">{I18n.t('title', SCOPE_OPTIONS)}</span>
          <small className="text-center text-black-50">{I18n.t('info', SCOPE_OPTIONS)}</small>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-header d-flex">
          <h5 className="card-title">{I18n.t('table.title', SCOPE_OPTIONS)}</h5>
        </div>
        <div className="card-body">
          <div className="table-responsive mt-2">
            <table className="table table-hover">
              <colgroup>
                <col style={{ width: '5%' }} />
                <col style={{ width: '30%' }} />
                <col style={{ width: '25%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
              </colgroup>
              <thead>
                <tr>
                  <th className="border-0 font-weight-600"></th>
                  <th className="border-0 font-weight-600">{I18n.t('table.product', SCOPE_OPTIONS)}</th>
                  <th className="border-0 font-weight-600">{I18n.t('table.usage_quantity', SCOPE_OPTIONS)}</th>
                  <th className="border-0 font-weight-600">{I18n.t('table.stock_count', SCOPE_OPTIONS)}</th>
                  <th className="border-0 font-weight-600">{I18n.t('table.net_requirement_count', SCOPE_OPTIONS)}</th>
                </tr>
              </thead>
              <tbody>
                {
                  materials.map(({ product, stock_count, usage_quantity, net_requirement_count, calculation_status }, i) => (
                    <tr key={`supply-plan-material-item-${i}`}>
                      <td>{i + 1}</td>
                      <td>
                        <a href={product.show_product_url}>
                          {product.name}
                        </a>
                      </td>
                      <td>{I18n.toNumber(usage_quantity, { strip_insignificant_zeros: true })} {product.unit}</td>
                      <td>{I18n.toNumber(stock_count, { strip_insignificant_zeros: true })} {product.unit}</td>
                      <td>{netRequirementCount(net_requirement_count, product.unit, calculation_status)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

PlanMaterialsTable.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.object.isRequired
  ).isRequired,
};

export default PlanMaterialsTable;
