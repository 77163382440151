import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';

const EmptyReportPlaceholder = () => {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.report.manufacturing_report.empty_report_state', locale: localeContext.locale };

  return (
    <div className="row" style={{ minHeight: '350px' }}>
      <div className="col-12">
        <div className="card bg-transparent border-0">
          <div className="row justify-content-center">
            <div className="col-xl-6 pt-3 text-muted">
              <h3 className="font-weight-normal mb-4"
                style={{ lineHeight: "unset" }}
                dangerouslySetInnerHTML={{ __html: I18n.t('hero_html', SCOPE_OPTIONS) }}
              />
              <ul className="list-unstyled font-14">
                <li>
                  <i className="fas fa-check text-success mr-3"></i>
                  {I18n.t('features.first', SCOPE_OPTIONS)}
                </li>
                <li className="mt-2">
                  <i className="fas fa-check text-success mr-3"></i>
                  {I18n.t('features.second', SCOPE_OPTIONS)}
                </li>
                <li className="mt-2">
                  <i className="fas fa-check text-success mr-3"></i>
                  {I18n.t('features.third', SCOPE_OPTIONS)}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

EmptyReportPlaceholder.propTypes = {};

export default EmptyReportPlaceholder;
