import React from "react"
import PropTypes from "prop-types"
import { Form as ReactForm, Text } from 'react-form'
import PlanRecipe from './FormElement/PlanRecipe'
import PlanRecipeForm from './FormElement/PlanRecipeForm'

class Form extends React.Component {
  constructor(props) {
    super(props);
    // console.log('props', this.props);
  }

  formDefaultValues() {
    return {
      description: this.props.manufacturing_plan.description,
      quantity: this.props.manufacturing_plan.quantity,
      issueDate: this.props.manufacturing_plan.issue_date,
      planRecipes: this.props.manufacturing_plan.plan_recipes
    }
  }

  planRecipesTableBody(formApi) {
    let calculatedIdentifier = 1;
    return formApi.values.planRecipes.map( (planRecipe, i) => (
      <PlanRecipe
        key={`plan-recipe-${i}`}
        planRecipe={planRecipe}
        i={i}
        calculatedIdentifier={calculatedIdentifier++}
        remove={() => (formApi.removeValue('planRecipes', i))} />
    ))
  }

  render () {
    const { form, manufacturing_plan } = this.props;
    return (
      <ReactForm defaultValues={this.formDefaultValues()}>
      { formApi => (
          <form action={form.action} method="post" data-remote={true} data-turbolinks-form={true} data-tid="manufacturing-plan-form" data-element="manufacturing-plan-form" style={{ marginBottom: '250px' }}>
            <input type='hidden' name={form.csrf_param} value={form.csrf_token} />
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name="_method" value={form.method} />

            <div className="form-group row">
              <label htmlFor="manufacturing_plan_description" className="col-3 col-form-label">Üretim Planı Açıklaması</label>
              <div className="col-9">
                <Text field='description' name='manufacturing_plan[description]' className="form-control" data-tid="input-manufacturing-plan-description" />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="manufacturing_plan_issue_date" className="col-3 col-form-label">Üretim Planı Tarihi</label>
              <div className="col-9">
                <Text field='issueDate' name='manufacturing_plan[issue_date]' type="datetime-local" className="form-control" data-tid="input-manufacturing-plan-issue-date"/>
              </div>
            </div>

            <div className='form-group'>
              <span>Reçeteler ve Üretim Miktarları</span>
              { formApi.values.planRecipes && formApi.values.planRecipes.length > 0 &&
                (<div className='table-responsive mt-2'>
                  <table className='table table-hover'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Reçete Adı</th>
                        <th>Ürün</th>
                        <th>Üretilecek Miktar</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.planRecipesTableBody(formApi)}
                    </tbody>
                  </table>
                </div>)
              }
            </div>

            <div className='form-group'>
              <PlanRecipeForm
                searchPath={form.recipe_search_path}
                newRecipePath={form.new_recipe_path}
                addPlanRecipe={(planRecipe) => formApi.addValue('planRecipes', planRecipe) }
              />
            </div>

            <hr />
            <button type="submit" className='btn btn-primary col-3 pull-right'>
              <i className="fa fa-spinner fa-spin mr-1 d-none" data-behavior='loading' />
              Planla
            </button>
          </form>
        )}
      </ReactForm>
    );
  }
}

Form.propTypes = {
  manufacturing_plan: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default Form
