import React from "react"
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from "prop-types"
import Autocomplete from 'react-autocomplete'
import { debounce, isString } from 'lodash'

const SCOPE_OPTIONS = { scope: 'components.manufacturing_plan.recipe_autocomplete' };

class RecipeAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    const recipe = this.props.recipe || {};
    this.state = {
      value: (recipe.description || ''),
      items: [recipe],
      loading: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.recipe !== nextProps.recipe) {
      const recipe = nextProps.recipe || {};
      // console.log('nextProps', nextProps)
      this.setState({ ...this.state, value: recipe.description || '' })
    }
  }

  handleFocusOut(e) {
    if (!this.state.value) {
      // console.log('no value', this.state.value)
      this.handleSelect(null, {})
    }
  }

  handleChange(event, value) {
    this.setState({ value: value, loading: true, items: [] })
    this.onChangeDebounced(value)
  }

  onChangeDebounced = debounce(this.fetchSearchResults, 500)

  fetchSearchResults(value) {
    value = value.split('%').join('');
    fetch(`${this.props.searchPath}?query=${value}`, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json()
    }).then((json) => {
      // console.log('parsed json', json)
      this.setState({ ...this.state, items: json, loading: false });
    }).catch((ex) => {
      // console.log('parsing failed', ex)
    })
  }

  handleSelect(value, item) {
    this.setState({ value: item.description });
    this.props.onRecipeSelect(item);
  }

  getListItemPresenter(item) {
    if (item.product && item.product.name) {
      return `${item.description} / ${item.product.name}`
    } else {
      return item.description
    }
  }

  render() {
    return (
      <LocaleContext.Consumer>
        {({ locale }) => {
          return (<div className={this.props.topClassNames}>
            <Autocomplete
              ref={el => this.input = el}
              value={this.state.value}
              onChange={this.handleChange.bind(this)}
              onSelect={(value, item) => this.handleSelect(value, item)}
              items={this.state.items}
              getItemValue={(item) => item.description}
              inputProps={{ className: `form-control ${this.props.inputClassNames}`, placeholder: I18n.t('search.placeholder', { ...SCOPE_OPTIONS, locale: locale }), 'data-tid': this.props.inputDataTid }}
              wrapperProps={{ className: this.props.wrapperClassNames, style: { display: 'inline', ...this.props.wrapperPropsStyles } }}
              // open={true}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`py-1 px-2 ${isHighlighted ? 'item-highlighted' : ''}`}
                  style={{ background: isHighlighted ? '#4095bf' : 'white' }}
                  key={item.id} data-tid="recipe-autocomplete-list-item">
                  {this.getListItemPresenter(item)}
                </div>
              )}
              renderMenu={(items, value, style) => (
                <div className="react-autocomplete-menu fixed-menu-autocomplete" style={{ minWidth: style.minWidth }}>
                  {value === '' ? (
                    <div className="py-1 px-2">
                      {I18n.t('hint', { ...SCOPE_OPTIONS, locale: locale })}
                    </div>
                  ) : this.state.loading ? (
                    <div className="py-1 px-2">
                      <i className="fa fa-cog fa-spin"></i>
                      <span className="ml-1">{I18n.t('loading', { ...SCOPE_OPTIONS, locale: locale })}</span>
                    </div>
                  ) : items.length === 0 ? (
                    <div className="py-1 px-2">
                      <span>{I18n.t('search.no_result', { ...SCOPE_OPTIONS, locale: locale, value: value })}</span>
                      {isString(this.props.newRecipeUrl) ? (
                        <a href={this.props.newRecipeUrl} target="_blank" rel="noopener noreferrer" data-tid="add-new-recipe-link">
                          {I18n.t('actions.add', { ...SCOPE_OPTIONS, locale: locale })}
                        </a>
                      ) : ''}
                    </div>
                  ) : items}
                </div>
              )}

            />
            {this.props.children}
          </div>)

        }}
      </LocaleContext.Consumer>
    );
  }
}

RecipeAutocomplete.propTypes = {
  recipe: PropTypes.object,
  searchPath: PropTypes.string.isRequired,
  onRecipeSelect: PropTypes.func.isRequired,
  wrapperPropsStyles: PropTypes.object,
  wrapperClassNames: PropTypes.string,
  topClassNames: PropTypes.string,
  newRecipeUrl: PropTypes.string,
  inputDataTid: PropTypes.string,
  inputClassNames: PropTypes.string,
}

export default RecipeAutocomplete
