import React from 'react';
import { I18n } from 'Context/LocaleContext';
import { isEmpty } from 'lodash';

export const isNewUnit = (units, value) => {
  if (isEmpty(value)) {
    return false;
  }

  return units.find((unit) => (unit.value.toLowerCase() === value.trim().toLowerCase()
    || unit.label.toLowerCase() === value.trim().toLowerCase())) === undefined;
};

export const renderInput = (props, isNewUnit, locale) => {
  const SCOPE_OPTIONS = { scope: 'components.helper.autocomplete_render_input_with_badge', locale: locale };

  return (
    <React.Fragment>
      <input {...props} />

      <div className="small mt-1">
        {isNewUnit && (
          <React.Fragment>
            <span className="badge badge-warning text-white">{I18n.t('badge', SCOPE_OPTIONS)}</span>
            <span className="ml-1 text-black-50">{I18n.t('desc', SCOPE_OPTIONS)}</span>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
};