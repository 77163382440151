import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import { isEmpty } from 'lodash';

const StepSummary = (props) => {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.workflow_plan.partials.step_summary', locale: localeContext.locale };

  const { step } = props;
  const renderActualStartDate = !isEmpty(step.actual_start_date);

  const WaitingSummary = () => {
    return (
      <span className="text-muted font-weight-light">{I18n.t('target_start_date', SCOPE_OPTIONS)}: {step.target_start_date}</span>
    )
  }

  const StartedSummary = () => {
    return (
      <span className="text-muted font-weight-light">{I18n.t('actual_start_date', SCOPE_OPTIONS)}: {step.actual_start_date}</span>
    )
  }

  const DoneSummary = () => {
    return (
      <React.Fragment>
        <span className="text-muted font-weight-light">{I18n.t('actual_start_date', SCOPE_OPTIONS)}: {step.actual_start_date}</span>
        <span className="text-muted font-weight-light">{I18n.t('end_date', SCOPE_OPTIONS)}: {step.end_date}</span>
      </React.Fragment>
    )
  }

  const LeadTime = () => {
    return (
      <span className="text-muted font-weight-light">
        {I18n.t('expected_days', SCOPE_OPTIONS)}: {I18n.t('in_days', {...SCOPE_OPTIONS, count: step.lead_time_in_days })}
      </span>
    )
  }

  let summary;
  switch(step.status) {
    case 'waiting':
      summary = <WaitingSummary />;
      break;
    case 'started':
      summary = <StartedSummary />;
      break;
    case 'done':
      summary = <DoneSummary />;
      break;
  }

  return (
    <div className="d-flex flex-column">
      <span className="font-weight-600">{step.name}</span>

      {summary}
      <LeadTime />
    </div>
  );
};

StepSummary.propTypes = {
};

export default StepSummary;
