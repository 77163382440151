import { useReducer } from 'react';

const useFilterManagerReducer = (initialFilterState, emptyFilter) => useReducer((filters, action) => {
  switch (action.type) {
    case 'add':
      return [...filters, emptyFilter];
    case 'update':
      return filters.map((filter, i) => {
        if (i === action.index) {
          return action.filter;
        }
        return filter;
      });
    case 'delete':
      return filters.filter((_, i) => i !== action.index);
    default:
      return filters;
  }
}, initialFilterState);

export default useFilterManagerReducer;
