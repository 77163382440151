import React, { useState, useRef } from 'react';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import { formProp } from '../Helper/CommonProps';
import PlanRecipeList from './Forms/PlanRecipeList';
import PlanExpenditureList from './Forms/PlanExpenditureList';
import useCollectionManagerReducer, { usePlanExpenditureReducer } from './Hooks/UseCollectionManagerReducer';
import { validPlanRecipes, validPlanExpenditures } from '../Helper/Validators/ManufacturingPlanValidator';
import SubmitForm from './Forms/SubmitForm';

const Form = (props) => {
  const { form, links, manufacturing_plan, translation } = props;
  const SCOPE_OPTIONS = { scope: 'components.new_manufacturing_plan.form', locale: translation.locale };
  const [description, setDescription] = useState(manufacturing_plan.description);
  const [issueDate, setIssueDate] = useState(manufacturing_plan.issue_date);

  //
  // PLAN RECIPE RELATED
  //
  const emptyPlanRecipe = { recipe: {}, product: {}, quantity: '' };
  const initialPlanRecipeState = (manufacturing_plan.plan_recipes.length > 0 ? manufacturing_plan.plan_recipes : [emptyPlanRecipe]);
  const [planRecipes, dispatchPlanRecipes] = useCollectionManagerReducer(initialPlanRecipeState, emptyPlanRecipe);

  const addPlanRecipe = () => dispatchPlanRecipes({ type: 'add' });
  const updatePlanRecipe = (index, planRecipe) => dispatchPlanRecipes({ type: 'update', index, planRecipe });
  const removePlanRecipe = (index, planRecipe) => {
    dispatchPlanRecipes({ type: 'update', index, planRecipe: { ...planRecipe, deleted: true } });
  };

  //
  // PLAN EXPENDITURE RELATED
  //
  const emptyPlanExpenditure = { description: '', expected_spend: 0, currency: 'TRY' };
  const initialPlanExpenditureState = (manufacturing_plan.plan_expenditures.length > 0 ? manufacturing_plan.plan_expenditures : []);
  const [planExpenditures, dispatchPlanExpenditures] = usePlanExpenditureReducer(initialPlanExpenditureState, emptyPlanExpenditure);

  const addPlanExpenditure = () => dispatchPlanExpenditures({ type: 'add' });
  const updatePlanExpenditure = (index, planExpenditure) => dispatchPlanExpenditures({ type: 'update', index, planExpenditure });
  const removePlanExpenditure = (index, planExpenditure) => {
    dispatchPlanExpenditures({ type: 'update', index, planExpenditure: { ...planExpenditure, deleted: true } });
  };

  // form & validations
  const [renderFormErrors, setRenderFormErrors] = useState(false);
  const submitFormRef = useRef(null);
  const saveManufacturingPlan = () => {
    setRenderFormErrors(true);
    if (validPlanExpenditures(planExpenditures) && validPlanRecipes(planRecipes)) {
      submitFormRef.current.submit();
      return true;
    }
    return false;
  };
  const [submitting, setSubmitting] = useState(false);
  const onFormSubmit = () => {
    setSubmitting(true);
    const formSaved = saveManufacturingPlan();
    setSubmitting(formSaved);
  };

  return (
    <LocaleContext.Provider value={translation}>
      <div className="row mt-2">
        <div className="col-xl-9 col-12">

          <div className="form-group row">
            <label htmlFor="manufacturing_plan_description" className="col-lg-3 col-12 col-form-label font-weight-light">{I18n.t('description.title', SCOPE_OPTIONS)}</label>
            <div className="col-lg-9 col-12">
              <input value={description || ''} onChange={e => setDescription(e.target.value)} className="form-control" type="text" required data-tid="input-manufacturing-plan-description" placeholder={I18n.t('description.placeholder', SCOPE_OPTIONS)} />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="manufacturing_plan_issue_date" className="col-lg-3 col-12 col-form-label font-weight-light">{I18n.t('issue_date', SCOPE_OPTIONS)}</label>
            <div className="col-lg-9 col-12">
              <input value={issueDate || ''} onChange={e => setIssueDate(e.target.value)} className="form-control" type="datetime-local" required data-tid="input-manufacturing-plan-issue-date" />
            </div>
          </div>

          <PlanRecipeList
            links={links}
            planRecipes={planRecipes}
            addPlanRecipe={addPlanRecipe}
            updatePlanRecipe={updatePlanRecipe}
            removePlanRecipe={removePlanRecipe}
            saveManufacturingPlan={saveManufacturingPlan}
            renderFormErrors={renderFormErrors}
          />

          <PlanExpenditureList
            planExpenditures={planExpenditures}
            renderFormErrors={renderFormErrors}
            addPlanExpenditure={addPlanExpenditure}
            updatePlanExpenditure={updatePlanExpenditure}
            removePlanExpenditure={removePlanExpenditure}
          />

          <div className="row mt-4">
            <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
              <button type="button" className={`btn btn-info btn-lg ${submitting ? 'disabled' : ''}`} onClick={onFormSubmit} disabled={submitting} data-tid="manufacturing-record-submit-btn">
                <i className={`fa fa-spinner fa-spin mr-1 ${submitting ? '' : 'd-none'}`} />
                {I18n.t('actions.plan_it', SCOPE_OPTIONS)}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* for autocomplete list result, we need to expand bottom */}
      <div style={{ marginTop: '250px' }}>
        <SubmitForm
          ref={submitFormRef}
          form={form}
          description={description}
          issueDate={issueDate}
          planRecipes={planRecipes}
          planExpenditures={planExpenditures}
        />
      </div>
    </LocaleContext.Provider>
  );
};

Form.propTypes = {
  manufacturing_plan: PropTypes.object.isRequired,
  form: formProp.isRequired,
  links: PropTypes.shape({
    recipe_search_path: PropTypes.string.isRequired,
    new_recipe_path: PropTypes.string.isRequired,
  }).isRequired,
  translation: LocaleProp.isRequired,
};

export default Form;
