import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';

const PresetAutocomplete = (props) => {
  const {
    placeholder,
    value,
    options,
    onChange,
    onSelect,
    wrapperClassNames,
    wrapperPropsStyles,
    inputDataTid,
    renderInput,
    inputClassNames
  } = props;

  const matchUnitToTerm = (item, value) => {
    return (
      item.value.toLowerCase().indexOf(value.toLowerCase()) !== -1
      || item.label.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  };

  return (
    <Autocomplete
      value={value || ''}
      inputProps={{ className: `form-control ${inputClassNames}`, placeholder, 'data-tid': inputDataTid }}
      wrapperProps={{ className: wrapperClassNames, style: { display: 'inline', ...wrapperPropsStyles } }}
      items={options}
      getItemValue={item => item.value}
      shouldItemRender={matchUnitToTerm}
      // open={true}
      onChange={e => onChange(e.target.value)}
      onSelect={value => onSelect(value)}
      renderMenu={(items, value, style) => (
        <div className={`react-autocomplete-menu fixed-menu-autocomplete ${items.length > 0 ? '' : 'd-none'}`} style={{ minWidth: style.minWidth }}>
          {items}
        </div>
      )}
      renderItem={(item, isHighlighted) => (
        <div
          className={`py-1 px-2 item ${isHighlighted ? 'item-highlighted' : ''}`}
          style={{ background: isHighlighted ? '#4095bf' : 'white' }}
          data-tid="autocomplete-product-unit-list-item"
          key={item.value}
        >
          {item.label}
        </div>
      )}
      renderInput={renderInput}
    />
  );
};

PresetAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  wrapperClassNames: PropTypes.string,
  wrapperPropsStyles: PropTypes.object,
  inputDataTid: PropTypes.string,
  renderInput: PropTypes.func,
  inputClassNames: PropTypes.string
};

export default PresetAutocomplete;
