export const GRANULARITY_OPTIONS = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year'
};

export const DISPLAY_FORMATS = {
  'day': 'D MMMM YYYY dddd',
  'week': 'll',
  'month': 'MMM YYYY',
  'year': 'YYYY'
}