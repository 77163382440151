import React, { useEffect, useState } from 'react';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import VirtualBoard from './VirtualBoard';
import { statusHandler } from '../Helper/RequestHelpers';
import FlashAlert from '../FlashMessage/FlashAlert';

const Show = (props) => {
  const { board, links, translation } = props;
  const [state, setState] = useState(() => board);
  const [flashMessage, setFlashMessage] = useState(null);

  const renderFlashMessage = (type, i18nKey) => {
    const text = I18n.t(i18nKey, { locale: translation.locale });
    const message = { id: Math.random(), type: type, text: text };
    setFlashMessage(message);
  }

  const fetchBoard = () => {
    const { links } = props;
    const { board_path_json } = links;

    if (!board_path_json) {
      return;
    }

    fetch(board_path_json, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(statusHandler).then((response) => {
      return response.json()
    }).then((json) => {
      setState(json.board);
    }).catch((ex) => {
      renderFlashMessage('danger', 'components.board.show.fetch_board_error');
    })
  }

  const fetchCards = async (column) => {
    const { links } = props;
    const { cards_path_json } = links;
    const itemsCount = column.items.length;

    const path = `${cards_path_json}?column_id=${column.originalId}&offset=${itemsCount}`;

    fetch(path, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(statusHandler).then((response) => {
      return response.json()
    }).then((json) => {
      // setState(json.board);
      setColumnCards(column, json.items, json.column.total_cards_count)
    }).catch((ex) => {
      renderFlashMessage('danger', 'components.board.show.fetch_card_error');
    })
  }

  const setColumnCards = (column, cards, totalCount) => {
    let items = column.items.concat(cards);

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [column.id]: {
          ...column,
          total_cards_count: totalCount,
          items
        }
      }
    }
    setState(newState);
  }

  return (
    <LocaleContext.Provider value={translation}>
      {flashMessage && (
        <div className='px-4'>
          <FlashAlert key={flashMessage.id} message={flashMessage} onClose={() => setFlashMessage(null)} />
        </div>
      )}

      <VirtualBoard
        state={state}
        setState={setState}
        fetchBoard={fetchBoard}
        links={links}
        fetchCards={fetchCards}
        renderFlashMessage={renderFlashMessage}
      />
    </LocaleContext.Provider>
  );
};

Show.propTypes = {
  board: PropTypes.object.isRequired,
  feature_flags: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  translation: LocaleProp.isRequired,
};

export default Show;