import React from 'react';
import PropTypes from 'prop-types';
import { formProp } from '../../Helper/CommonProps';

const SubmitForm = React.forwardRef((props, ref) => {
  const {
    form,
    orderNo,
    orderChannel,
    note,
    issueDate,
    requestedDeliveryDate,
    lineItems,
    description,
  } = props;

  const lineItemInputName = index => `customer_order[line_items_attributes][${index}]`;
  const customer = props.customer || {};

  return (
    <React.Fragment>
      <form action={form.action} method="post" data-remote data-turbolinks-form data-tid="customer-order-form" data-element="customer-order-form" ref={ref}>
        <input type="hidden" name={form.csrf_param} value={form.csrf_token} />
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value={form.method} />

        <input type="hidden" value={description || ''} name="customer_order[description]" />
        <input type="hidden" value={orderNo || ''} name="customer_order[order_no]" />
        <input type="hidden" value={orderChannel || ''} name="customer_order[order_channel]" />
        <input type="hidden" value={note || ''} name="customer_order[note]" />
        <input type="hidden" value={issueDate || ''} name="customer_order[issue_date]" />
        <input type="hidden" value={requestedDeliveryDate || ''} name="customer_order[requested_delivery_date]" />

        <input type="hidden" value={customer.id || ''} name="customer_order[customer][id]" />
        <input type="hidden" value={customer.name || ''} name="customer_order[customer][name]" />
        <input type="hidden" value={customer.email || ''} name="customer_order[customer][email]" />
        <input type="hidden" value={customer.phone || ''} name="customer_order[customer][phone]" />
        <input type="hidden" value={customer.tax_office || ''} name="customer_order[customer][tax_office]" />
        <input type="hidden" value={customer.tax_number || ''} name="customer_order[customer][tax_number]" />
        <input type="hidden" value={customer.address || ''} name="customer_order[customer][address]" />
        <input type="hidden" value={customer.parasut_id || ''} name="customer_order[customer][parasut_id]" />
        <input type="hidden" value={customer.is_vendor || ''} name="purchase_order[customer][is_vendor]" />

        {/* filter is used to not to render if lineItem.id null and deleted all together */}
        {lineItems.filter(lineItem => !(lineItem.id == null && lineItem.deleted)).map((lineItem, i) => (
          <React.Fragment key={`line-item-values-${i}`}>
            <input type="hidden" value={lineItem.id || ''} name={`${lineItemInputName(i)}[id]`} />
            <input type="hidden" value={lineItem.product.id || ''} name={`${lineItemInputName(i)}[product][id]`} />
            <input type="hidden" value={lineItem.product.name || ''} name={`${lineItemInputName(i)}[product][name]`} />
            <input type="hidden" value={lineItem.product.unit || ''} name={`${lineItemInputName(i)}[product][unit]`} />
            <input type="hidden" value={lineItem.product.parasut_id || ''} name={`${lineItemInputName(i)}[product][parasut_id]`} />
            <input type="hidden" value={lineItem.product.code || ''} name={`${lineItemInputName(i)}[product][code]`} />
            <input type="hidden" value={lineItem.product.barcode || ''} name={`${lineItemInputName(i)}[product][barcode]`} />
            <input type="hidden" value={lineItem.quantity || ''} name={`${lineItemInputName(i)}[quantity]`} />
            <input type="hidden" value={lineItem.deleted || false} name={`${lineItemInputName(i)}[deleted]`} />
          </React.Fragment>
        ))}
      </form>
    </React.Fragment>
  );
});

SubmitForm.propTypes = {
  form: formProp.isRequired,
  description: PropTypes.string,
  customer: PropTypes.object,
  orderNo: PropTypes.string,
  orderChannel: PropTypes.string,
  note: PropTypes.string,
  issueDate: PropTypes.any,
  requestedDeliveryDate: PropTypes.any,
  lineItems: PropTypes.array.isRequired,
};

export default SubmitForm;
