import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';

const DraftBadge = ({ text }) => {
  return (
    <span className="badge badge-secondary text-white">
      {text}
    </span>
  );
};

const ApprovedBadge = ({ text }) => {
  return (
    <span className="badge badge-primary text-white">
      {text}
    </span>
  );
};

const ShippedBadge = ({ text }) => {
  return (
    <span className="badge badge-info text-white">
      {text}
    </span>
  );
};

const ReceivedBadge = ({ text }) => {
  return (
    <span className="badge badge-success text-white">
      {text}
    </span>
  );
};

const InProgressBadge = ({ text }) => {
  return (
    <span className="badge badge-warning text-white">
      {text}
    </span>
  );
};

const CanceledBadge = ({ text }) => {
  return (
    <span className="badge badge-danger text-white">
      {text}
    </span>
  );
};

const StatusBadge = ({ order }) => {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.supply_planning.partials.status_badge', locale: localeContext.locale };

  if (order.status == 'draft') {
    return <DraftBadge text={I18n.t('statuses.draft', SCOPE_OPTIONS)} />
  }

  if (order.status == 'approved') {
    return <ApprovedBadge text={I18n.t('statuses.approved', SCOPE_OPTIONS)} />
  }

  if (order.status == 'shipped') {
    return <ShippedBadge text={I18n.t('statuses.shipped', SCOPE_OPTIONS)} />
  }

  if (order.status == 'received') {
    return <ReceivedBadge text={I18n.t('statuses.received', SCOPE_OPTIONS)} />
  }

  if (order.status == 'in_progress') {
    return <InProgressBadge text={I18n.t('statuses.in_progress', SCOPE_OPTIONS)} />
  }

  if (order.status == 'canceled') {
    return <CanceledBadge text={I18n.t('statuses.canceled', SCOPE_OPTIONS)} />
  }
};

StatusBadge.propTypes = {
  order: PropTypes.object.isRequired,
};

export default StatusBadge;
