import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import ProductAutocompleteWithCreateModal from '../Product/ProductAutocompleteWithCreateModal';
import WorkflowStepForm from './Forms/WorkflowStepForm';
import useCollectionManagerReducer from '../Helper/Hooks/UseCollectionManagerReducer';
import FlashAlert from '../FlashMessage/FlashAlert';
import { statusHandler } from '../Helper/RequestHelpers';
import { formProp } from '../Helper/CommonProps';
import { validProduct, validSteps, validStep } from '../Helper/Validators/WorkflowValidator';

const Form = (props) => {
  const {
    units,
    links,
    form,
    translation,
  } = props;

  const SCOPE_OPTIONS = { scope: 'components.workflow.form', locale: translation.locale };

  const [flashMessage, setFlashMessage] = useState(null);
  const [renderFormErrors, setRenderFormErrors] = useState(false);
  const [product, setProduct] = useState({});
  const onProductSelect = (product) => {
    setProduct(product)
  }

  // workflow steps
  const emptyStep = { stepName: '', quantity: '' };
  const initialStepsState = [emptyStep, emptyStep, emptyStep, emptyStep, emptyStep, emptyStep, emptyStep, emptyStep, emptyStep];
  const [workflowSteps, dispatchWorkflowSteps] = useCollectionManagerReducer(initialStepsState, emptyStep);
  const addNewStep = () => dispatchWorkflowSteps({ type: 'add' });
  const updateStep = (index, step) => dispatchWorkflowSteps({ type: 'update', index, object: step });
  const deleteStep = (index) => dispatchWorkflowSteps({ type: 'delete', index });

  const productInvalid = !validProduct(product);
  const workflowInvalid = productInvalid && !validSteps(workflowSteps);

  const handleSubmit = () => {
    if (workflowInvalid) {
      setRenderFormErrors(true);
    } else {
      setRenderFormErrors(false);
      saveWorkflow();
    }
  }

  const saveWorkflow = () => {
    const { action, method, csrf_token } = form;
    fetch(action, {
      credentials: 'same-origin',
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token
      },
      body: JSON.stringify({
        workflow: {
          product: product,
          workflow_steps: workflowSteps.map(({ stepName, quantity }) => {
            return { name: stepName, lead_time_in_days: quantity }
          })
        }
      })
    }).then(statusHandler).then((response) => {
      return response.json()
    }).then((json) => {
      window.Turbolinks.visit(json.workflow_show_path);
    }).catch((ex) => {
      if (ex.json && ex.json.error_detail) {
        const message = { id: Math.random(), type: 'danger', text: ex.json.error_detail };
        setFlashMessage(message);
      }
    });
  };

  return (
    <LocaleContext.Provider value={translation}>
      {flashMessage && (
        <div className='px-4'>
          <FlashAlert key={flashMessage.id} message={flashMessage} onClose={() => setFlashMessage(null)} />
        </div>
      )}

      <ProductAutocompleteWithCreateModal
        links={links}
        units={units}
        product={product || {}}
        onProductSelect={onProductSelect}
        searchPath={links.product_search_path}
        topClassNames="flex-grow-1"
        wrapperClassNames=""
        parasutProductNewUrl=""
        inputDataTid="input-workflow-product-autocomplete"
        inputClassNames={`${renderFormErrors && productInvalid ? 'is-invalid' : ''}`}
        form={form}
        createProductPath={links.create_product_path}
        showBuyingPriceInput={false}
        inputPlaceholder={I18n.t('product_placeholder', SCOPE_OPTIONS)}
      >
        {renderFormErrors && productInvalid && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {I18n.t('auto_complete.feedback', SCOPE_OPTIONS)}
          </div>
        )}
      </ProductAutocompleteWithCreateModal>

      <hr />

      <div>
        {
          workflowSteps.map((step, i) => (
            <WorkflowStepForm
              key={`workflow-step-${i}`}
              index={i}
              step={step}
              invalid={!validStep(step)}
              renderFormErrors={renderFormErrors}
              updateStep={updateStep}
              deleteStep={deleteStep}
            />
          ))
        }
      </div>

      <div className="d-flex justify-content-center mt-2">
        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={addNewStep} data-tid="add-new-wokflow-step-btn">
          <i className="fa fa-plus mr-2" />
          {I18n.t('actions.add', SCOPE_OPTIONS)}
        </button>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className={`btn btn-dark btn-lg btn-block`} data-tid="workflow-submit-btn" onClick={handleSubmit}>
            {I18n.t('actions.submit', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>
    </LocaleContext.Provider>
  );
};

Form.propTypes = {
  translation: LocaleProp.isRequired,
  form: formProp.isRequired,
  links: PropTypes.object.isRequired,
};

export default Form;
