import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import LineChart from '../../Dashboard/LineChart';
import { assignColor } from '../../Dashboard/ChartColors';
import { isMobileOnly } from 'react-device-detect';
import qs from 'qs';
import moment from 'moment';

const GRANULARITY_OPTIONS = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year'
};

const DISPLAY_FORMATS = {
  'day': 'D MMMM YYYY dddd',
  'week': 'll',
  'month': 'MMM YYYY',
  'year': 'YYYY'
}

const ReportContent = (props) => {
  const { fetchUrl, headerTextComponent, product, currentQueryParameters, refreshPageWith } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.report.stock_history.report_content', locale: localeContext.locale };

  const decideWhichGranularityOptionSuitsBest = () => {
    const startDate = moment(currentQueryParameters['start_date']);
    const endDate = moment(currentQueryParameters['end_date']);
    const daysBetween = endDate.diff(startDate, 'days');

    if (daysBetween <= 14) {
      return GRANULARITY_OPTIONS.DAY;
    }

    if (daysBetween <= 60) {
      return GRANULARITY_OPTIONS.WEEK;
    }

    if (daysBetween <= 365) {
      return GRANULARITY_OPTIONS.MONTH;
    }

    return GRANULARITY_OPTIONS.YEAR;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const granularity = currentQueryParameters['granularity'] || decideWhichGranularityOptionSuitsBest();
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const chartOptions = {
    aspectRatio: (isMobileOnly ? 1 : 4),
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        title: tooltipItem => moment(tooltipItem[0].label).format(DISPLAY_FORMATS[granularity]),
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label || '';
          const itemData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const unit = itemData.unit || '';
          if (tooltipItem.yLabel) {
            return `${label}: ${I18n.toNumber(tooltipItem.yLabel, { strip_insignificant_zeros: true })} ${unit}`;
          }
        },
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: granularity,
          displayFormats: {
            day: 'D MMM',
          },
          isoWeekday: true,
        },
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{
        gridLines: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          padding: 10,
          maxTicksLimit: 5,
          callback: function(value, index, values) {
            return I18n.toNumber(value, { strip_insignificant_zeros: true });
          }
        }
      }]
    },
    legend: {
      display: false
    }
  };

  const beautifyChartData = (response) => {
    const datasets = [];
    response.datasets.forEach((dataset, i) => {
      const group = {
        fill: false,
        backgroundColor: assignColor(i),
        borderColor: assignColor(i),
        ...dataset,
      };
      datasets.push(group);
    });
    setChartData({ ...response, datasets });
  };

  useEffect(() => {
    const fetchReportData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const startDate = currentQueryParameters['start_date'];
        const endDate = currentQueryParameters['end_date'];
        const rawResponse = await fetch(`${fetchUrl}?product_id=${product.id}&start_date=${startDate}&end_date=${endDate}&granularity=${granularity}`);
        const response = await rawResponse.json();

        beautifyChartData(response);
      } catch (e) {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchReportData();
  }, [fetchUrl]);

  const onGranularityChange = (option) => {
    return refreshPageWith(qs.stringify({ ...currentQueryParameters, granularity: option }));
  }

  const isGranularitySelected = (option) => {
    return (option.toLowerCase() === granularity.toLowerCase());
  }

  return (
    <React.Fragment>
      {
        isLoading ? (
          <div className="row mt-3">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">{I18n.t('loading', SCOPE_OPTIONS)}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
            isError ? (
              <div style={{ minHeight: '350px' }}>
                <small className="text-danger text-monospace">
                  {I18n.t('errors.unknown', SCOPE_OPTIONS)}
                </small>
              </div>
            ) : (
                <React.Fragment>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="card border">
                        <div className="card-header bg-transparent d-flex border-bottom-0 flex-column flex-lg-row">
                          {headerTextComponent && headerTextComponent()}
                          <div className="ml-auto align-self-center">
                            <div className="btn-group btn-group-sm">
                              {Object.values(GRANULARITY_OPTIONS).map((value) => {
                                return (
                                  <button
                                    key={`stock-history-report-granularity-${value}`}
                                    type="button"
                                    className={`btn ${isGranularitySelected(value) ? 'btn-dark' : 'btn-outline-dark'}`}
                                    onClick={() => onGranularityChange(value)}
                                    data-tid={`stock-history-report-granularity-${value}-btn`}>
                                    {I18n.t(`granularity.${value}`, SCOPE_OPTIONS)}
                                  </button>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="card-block p-4">
                          <LineChart {...chartData} options={chartOptions} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <div className="card border">
                        <div className="card-header pb-0 px-0">
                          <div className="table-responsive">
                            <table className="table table-hover mb-0">
                              <colgroup>
                                <col style={{ width: '5%' }} />
                                <col style={{ width: '45%' }} />
                                <col style={{ width: '50%' }} />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th className="border-0 font-weight-600">#</th>
                                  <th className="border-0 font-weight-600">{I18n.t('table_header.date', SCOPE_OPTIONS)}</th>
                                  <th className="border-0 font-weight-600">{I18n.t('table_header.stock_count', SCOPE_OPTIONS)}</th>
                                </tr>
                              </thead>
                              <tbody className="bg-white">
                                {chartData.datasets.length > 0 && chartData.datasets[0].data.map((data, i) => {
                                  return (
                                    <tr key={`char-data-table-row-${i}`}>
                                      <td className="align-middle">{i + 1}</td>
                                      <td className="align-middle">{moment(data.x).format(DISPLAY_FORMATS[granularity])}</td>
                                      <td className="align-middle">{I18n.toNumber(data.y, { strip_insignificant_zeros: true })} {data.unit ?? ''}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )
      }
    </React.Fragment>
  )
};

ReportContent.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  currentQueryParameters: PropTypes.object.isRequired,
  headerTextComponent: PropTypes.func,
  refreshPageWith: PropTypes.func.isRequired,
};

export default ReportContent;
