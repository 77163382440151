import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import useCollectionManagerReducer from '../Hooks/UseCollectionManagerReducer';
import SubmitForm from './SubmitForm';
import { isInvalid, isLineItemsInvalid } from './Validators';
import LineItem from './LineItem';

const Modal = (props) => {
  const { translation, shipment_document, links } = props;
  const SCOPE_OPTIONS = { scope: 'components.customer_order.outflow_shipment_document.modal', locale: translation.locale };

  // line items
  let calculatedIdentifier = 1;
  const initialLineItemsState = (shipment_document.line_items.length > 0 ? shipment_document.line_items : []);
  const [lineItems, dispatchLineItems] = useCollectionManagerReducer(initialLineItemsState, {});
  const updateLineItem = (index, lineItem) => dispatchLineItems({ type: 'update', index, lineItem });

  // form & validations
  const submitFormRef = useRef(null);
  const [submitting, setSubmitting] = useState(false);

  const onFormSubmit = () => {
    setSubmitting(true);
    submitFormRef.current.submit();
  };

  const handleSubmit = () => {
    if (!isLineItemsInvalid(lineItems)) {
      onFormSubmit();
    }
  }

  return (
    <LocaleContext.Provider value={translation}>
      <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header align-items-center d-flex">
            <span className="modal-title font-20">
              {I18n.t('title', SCOPE_OPTIONS)}
            </span>
            <button className="close" aria-label="Close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <p className="card-text">
                      <i className="fas fa-info pr-2" />
                      {I18n.t('description', SCOPE_OPTIONS)}
                    </p>
                  </div>
                </div>

                <div className="card mt-4">
                  <div className="card-header pb-0 px-0 border-bottom-0">
                    <div className="table-responsive">
                      <table className="table table-hover mb-0">
                        <colgroup>
                          <col style={{ width: '5%' }}/>
                          <col style={{ width: '35%' }}/>
                          <col style={{ width: '20%' }}/>
                          <col style={{ width: '20%' }}/>
                          <col style={{ width: '20%' }}/>
                        </colgroup>
                        <thead>
                          <tr>
                            <th className="border-0 font-weight-600">#</th>
                            <th className="border-0 font-weight-600">{I18n.t('table.product_name', SCOPE_OPTIONS)}</th>
                            <th className="border-0 font-weight-600">{I18n.t('table.line_item_quantity', SCOPE_OPTIONS)}</th>
                            <th className="border-0 font-weight-600">{I18n.t('table.quantity', SCOPE_OPTIONS)}</th>
                            <th className="border-0 font-weight-600">{I18n.t('table.will_be_delivered_quantity', SCOPE_OPTIONS)}</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {
                            lineItems.map((lineItem, i) => (
                              <LineItem
                                key={`order-line-item-${i}`}
                                index={i}
                                lineItem={lineItem}
                                calculatedIdentifier={calculatedIdentifier++}
                                updateLineItem={updateLineItem}
                              />
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-outline-secondary" data-dismiss="modal">{I18n.t('actions.close', SCOPE_OPTIONS)}</button>
            <button type="submit" className={`btn btn-info ${submitting ? 'disabled' : ''} ${isLineItemsInvalid(lineItems) ? 'disabled' : ''}`} onClick={handleSubmit} disabled={submitting} data-tid="new-shipment-document-submit-btn">
              <i className={`fa fa-spinner fa-spin mr-1 ${submitting ? '' : 'd-none'}`} />
              {I18n.t('actions.save', SCOPE_OPTIONS)}
            </button>
          </div>
        </div>
      </div>

      {/* for autocomplete list result, we need to expand bottom */}
      <div style={{ marginTop: '250px' }}>
        <SubmitForm
          ref={submitFormRef}
          form={links.create_shipment_document_form.form}
          shipmentDocumentForm={shipment_document}
          lineItems={lineItems}
        />
      </div>
    </LocaleContext.Provider>
  );
}

Modal.propTypes = {
  links: PropTypes.object.isRequired,
  shipment_document: PropTypes.object.isRequired,
  feature_flags: PropTypes.object.isRequired,
  translation: LocaleProp.isRequired,
}

export default Modal;
