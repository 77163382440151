import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import Badge from './Badge';
import StepSummary from './StepSummary';
import StepTimeline from './StepTimeline';
import { statusHandler } from '../../Helper/RequestHelpers';

const StepDetail = (props) => {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.workflow_plan.partials.step_detail', locale: localeContext.locale };

  const { step, index, onStepUpdate } = props;

  const waiting = step.status == 'waiting';
  const started = step.status == 'started';

  const updateStep = (form) => {
    const { action, method, csrf_token } = form;

    fetch(action, {
      credentials: 'same-origin',
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token
      },
    }).then(statusHandler).then((response) => {
      return response.json()
    }).then((json) => {
      onStepUpdate(index, json)
    }).catch((ex) => {
      // renderFlashMessage('danger', 'components.board.reorder.update_column_position_error');
      console.log(ex);
    })
  }

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <div className="d-flex">
                <Badge step={step} index={index+1} />
                <StepSummary step={step} />

                {
                  waiting && (
                    <div className="align-self-center ml-auto">
                      <button className="btn btn-dark btn-sm" onClick={() => updateStep(step.links.start_step.form)}>
                        {I18n.t('start_step', SCOPE_OPTIONS)}
                      </button>
                    </div>
                  )
                }

                {
                  started && (
                    <div className="align-self-center ml-auto">
                      <button className="btn btn-danger btn-sm" onClick={() => updateStep(step.links.stop_step.form)}>
                        {I18n.t('stop_step', SCOPE_OPTIONS)}
                      </button>
                    </div>
                  )
                }
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <StepTimeline step={step} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

StepDetail.propTypes = {
};

export default StepDetail;
