import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formProp } from '../../Helper/CommonProps';

const EditableField = (props) => {
  const {
    children, rawValue, form, fieldName, enabled
  } = props;

  const [editable, setEditable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState(rawValue || 0);
  const inputRef = useRef(null);

  const handleSubmit = () => {
    setIsSubmitting(true);
    return true;
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setEditable(true);
    }
  };

  const handleEditableClick = () => {
    if (enabled) {
      setEditable(true)
    }
  };

  useEffect(() => {
    if (editable) {
      inputRef.current.focus();
    }
  });

  return (
    <React.Fragment>
      {editable ? (
        <form className="form-inline" action={form.action} method={form.method} data-remote data-turbolinks-form data-tid={`cost-analysis-form-${fieldName}`} data-element="plan-recipe-cost-analysis-form" onSubmit={handleSubmit}>
          <input type="hidden" name={form.csrf_param} value={form.csrf_token} />
          <input name="utf8" type="hidden" value="✓" />
          <div className="input-group">
            <input className="form-control form-control-sm text-monospace" type="number" value={value} onChange={e => setValue(e.target.value)} name={`plan_recipe[${fieldName}]`} ref={inputRef} step="0.0000000001" />
            <div className="input-group-append">
              <button className="btn btn-outline-danger btn-sm" type="button" onClick={() => setEditable(false)}>
                <i className="fa fa-times" />
              </button>
              <button className={`btn btn-outline-info btn-sm ${isSubmitting ? 'disabled' : ''}`} type="submit" data-tid="cost-analysis-btn" disabled={isSubmitting}>
                <i className={`fa fa-spinner fa-spin mr-1 ${isSubmitting ? '' : 'd-none'}`} data-behavior="loading" />
                <i className="fa fa-check" />
              </button>
            </div>
          </div>
        </form>
      ) : (
          <div onClick={handleEditableClick} onKeyDown={handleKeyDown} style={{ borderBottom: '1px dashed', cursor: 'pointer' }} role="presentation">
            {children}
          </div>
        )}
    </React.Fragment>
  );
};

EditableField.propTypes = {
  rawValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fieldName: PropTypes.string.isRequired,
  form: formProp.isRequired,
  children: PropTypes.node.isRequired,
  enabled: PropTypes.bool.isRequired
};

export default EditableField;
