import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';

const SubmitForm = React.forwardRef((props, ref) => {
  const {
    form, product, outputQuantity, ingredients,
  } = props;
  const ingredientInputName = index => `recipe[ingredients_attributes][${index}]`;
  const localeContext = useContext(LocaleContext);
  const description = props.description
    || I18n.t('components.new_recipe.forms.submit_form.description', {
      quantity: outputQuantity,
      unit: product.unit || '',
      name: product.name,
      locale: localeContext.locale
    });

  return (
    <React.Fragment>
      <form
        action={form.action}
        method="post"
        data-remote
        data-turbolinks-form
        data-tid="recipe-form"
        data-element="recipe-form"
        ref={ref}
      >
        <input type="hidden" name={form.csrf_param} value={form.csrf_token} />
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value={form.method} />

        <input
          type="hidden"
          value={description || ''}
          name="recipe[description]"
        />
        <input
          type="hidden"
          value={product.id || ''}
          name="recipe[product][id]"
        />
        <input
          type="hidden"
          value={product.name || ''}
          name="recipe[product][name]"
        />
        <input
          type="hidden"
          value={product.unit || ''}
          name="recipe[product][unit]"
        />
        <input
          type="hidden"
          value={product.parasut_id || ''}
          name="recipe[product][parasut_id]"
        />
        <input
          type="hidden"
          value={product.code || ''}
          name="recipe[product][code]"
        />
        <input
          type="hidden"
          value={product.barcode || ''}
          name="recipe[product][barcode]"
        />
        <input
          type="hidden"
          value={outputQuantity || ''}
          name="recipe[output_quantity]"
        />
        {/* filter is used to not to render if ingredient.id null and deleted all together */}
        {ingredients
          .filter(ingredient => !(ingredient.id == null && ingredient.deleted))
          .map((ingredient, i) => (
            <React.Fragment key={`ingredient-values-${i}`}>
              <input
                type="hidden"
                value={ingredient.id || ''}
                name={`${ingredientInputName(i)}[id]`}
              />
              <input
                type="hidden"
                value={ingredient.product.id || ''}
                name={`${ingredientInputName(i)}[product][id]`}
              />
              <input
                type="hidden"
                value={ingredient.product.name || ''}
                name={`${ingredientInputName(i)}[product][name]`}
              />
              <input
                type="hidden"
                value={ingredient.product.unit || ''}
                name={`${ingredientInputName(i)}[product][unit]`}
              />
              <input
                type="hidden"
                value={ingredient.product.parasut_id || ''}
                name={`${ingredientInputName(i)}[product][parasut_id]`}
              />
              <input
                type="hidden"
                value={ingredient.product.code || ''}
                name={`${ingredientInputName(i)}[product][code]`}
              />
              <input
                type="hidden"
                value={ingredient.product.barcode || ''}
                name={`${ingredientInputName(i)}[product][barcode]`}
              />

              <input
                type="hidden"
                value={ingredient.quantity || ''}
                name={`${ingredientInputName(i)}[quantity]`}
              />
              <input
                type="hidden"
                value={ingredient.used_unit || ''}
                name={`${ingredientInputName(i)}[used_unit]`}
              />
              <input
                type="hidden"
                value={ingredient.deleted || false}
                name={`${ingredientInputName(i)}[deleted]`}
              />
            </React.Fragment>
          ))}
      </form>
    </React.Fragment>
  );
});

SubmitForm.propTypes = {
  form: PropTypes.object.isRequired,
  product: PropTypes.object,
  outputQuantity: PropTypes.node,
  ingredients: PropTypes.array.isRequired,
  description: PropTypes.string,
};

export default SubmitForm;
