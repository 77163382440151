import React from "react"
import PropTypes from "prop-types"
import IngredientFormItem from './IngredientFormItem'

class IngredientList extends React.Component {
  calculateTotal(quantity, spentPerUnit) {
    // parseFloat is needed to get rid of trailing zeros
    return parseFloat((quantity * Number(spentPerUnit)).toFixed(4));
  }

  render () {
    const { ingredients, quantity } = this.props;
    return (
      <div>
        <span>Tüketilen Malzemeler</span>
        <div className='table-responsive mt-2'>
          <table className='table table-hover'>
            <thead>
              <tr>
                <th>Malzeme Adı</th>
                <th>Birim Başı Tüketilen</th>
                <th></th>
                <th>Üretilen Mamul Miktarı</th>
                <th></th>
                <th>Toplam Tüketilen</th>
              </tr>
            </thead>
            <tbody>
            { this.props.ingredients.map((ingredient, i) => (
              <IngredientFormItem ingredient={ingredient} i={i} quantity={quantity} key={`ingredient-${i}`} />
            )) }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

IngredientList.propTypes = {
  ingredients: PropTypes.array.isRequired,
  quantity: PropTypes.number.isRequired
}

export default IngredientList
