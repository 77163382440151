import React, { useState, useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import RecordIngredientForm from './RecordIngredientForm';

const EditRecordIngredients = (props) => {
  const {
    links, ingredients, form, addIngredient, updateIngredient, removeIngredient,
    allowUnitConversion, renderFormErrors, saveManufacturingRecord, units
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_manufacturing_record.forms.edit_record_ingredients', locale: localeContext.locale };
  const [loading, setLoading] = useState(false);
  const onFormSubmit = () => {
    setLoading(true);
    const formSaved = saveManufacturingRecord();
    setLoading(formSaved);
  };
  let calculatedIdentifier = 1;

  return (
    <React.Fragment>
      <div className="row">
        <div className="offset-lg-2 col-lg-8"><hr /></div>
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <span className="text-center font-20 text-black-50 font-weight-light">{I18n.t('title', SCOPE_OPTIONS)}</span>
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8">
          {
            ingredients.map((ingredient, i) => (
              <RecordIngredientForm
                key={i}
                index={i}
                form={form}
                ingredient={ingredient}
                updateIngredient={updateIngredient}
                calculatedIdentifier={(ingredient.deleted ? null : calculatedIdentifier++)}
                allowUnitConversion={allowUnitConversion}
                removeIngredient={removeIngredient}
                renderFormErrors={renderFormErrors}
                links={links}
                units={units}
              />
            ))
          }
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className="btn btn-outline-secondary btn-lg mr-4" onClick={addIngredient} data-tid="add-more-ingredient-btn">{I18n.t('actions.add', SCOPE_OPTIONS)}</button>
          <button type="button" className={`btn btn-info btn-lg ${loading ? 'disabled' : ''}`} onClick={onFormSubmit} disabled={loading} data-tid="manufacturing-record-submit-btn">
            <i className={`fa fa-spinner fa-spin mr-1 ${loading ? '' : 'd-none'}`} />
            {I18n.t('actions.save', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

EditRecordIngredients.propTypes = {
  links: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  ingredients: PropTypes.array.isRequired,
  addIngredient: PropTypes.func.isRequired,
  updateIngredient: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  allowUnitConversion: PropTypes.bool.isRequired,
  renderFormErrors: PropTypes.bool.isRequired,
  saveManufacturingRecord: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired
};

export default EditRecordIngredients;
