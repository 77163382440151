import { isEmpty } from 'lodash';

export const validProduct = product => !isEmpty(product);
export const validQuantity = quantity => {
  return ((quantity !== '' && quantity !== 0) && quantity !== null);
};
export const validRecipe = (_recipe, product) => validProduct(product);
export const validPlanRecipes = planRecipes => planRecipes.length > 0 && planRecipes.filter(planRecipe => !planRecipe.deleted).filter(planRecipe => !validRecipe(planRecipe.recipe, planRecipe.product)).length === 0;

export const validInput = input => input !== '';

export const validPlanExpenditure = (planExpenditure) => validInput(planExpenditure.description) && validQuantity(planExpenditure.expected_spend) && validInput(planExpenditure.currency);
export const validPlanExpenditures = (planExpenditures) => {
  if (planExpenditures.length === 0) {
    return true;
  }
  return planExpenditures.length > 0 && planExpenditures.filter(planExpenditure => !planExpenditure.deleted).filter(planExpenditure => !validPlanExpenditure(planExpenditure)).length === 0;
};
