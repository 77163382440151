import React from "react"
import PropTypes from "prop-types"
import ProductAutocomplete from './../../Product/ProductAutocomplete';
import { Form as ReactForm, Text } from 'react-form'
import { isEmpty } from 'lodash'

class IngredientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { product: {}, quantity: '', selectedUnit: {}, conversions: [] }

    this.handleQuantity = this.handleQuantity.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onSelectedUnitChange = this.onSelectedUnitChange.bind(this);
  }

  onProductSelect(product) {
    this.setState({ product, fetchingConversions: true, conversions: [] });
    if(this.props.allowUnitConversion) {
      this.fetchConversionDetails(product);
    } else {
      this.setState({ fetchingConversions: false, selectedUnit: { unit: product.unit } });
    }
  }

  fetchConversionDetails(product) {
    const self = this;
    const productParasutId = product.parasut_id;
    fetch(`${this.props.conversionDetailsPath}?product_parasut_id=${productParasutId}`, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json()
    }).then((json) => {
      const setUnit = { unit: product.unit, quantity: 1, per_unit: 1, key: product.unit };
      // add product current unit as a first element
      json.unshift(setUnit);
      this.setState({ ...this.state, fetchingConversions: false, conversions: json, selectedUnit: setUnit });
    }).catch((ex) => {
      // set selectedUnit to default product unit, if fetching somehow got an error
      this.setState({ ...this.state, fetchingConversions: false, selectedUnit: { unit: this.state.product.unit } });
    })
  }

  handleQuantity(event) {
    this.setState({ 'quantity': event.target.value });
  }

  formValid() {
    const { product, quantity } = this.state;
    if(isEmpty(product)) {
      return 'product'
    } else if(quantity === '') {
      return 'quantity'
    } else if(product.parasut_id === undefined) {
      return 'parasut_id'
    }
    return true;
  }

  handleClick(e) {
    e.preventDefault();
    // bypass if fetching is still active
    if(this.state.fetchingConversions) {
      return;
    }
    if(this.formValid() === true) {
      this.props.addIngredient({
        product: this.state.product,
        quantity: parseFloat(Number(this.state.quantity).toFixed(4)),
        used_unit: this.state.selectedUnit.unit,
      });
      this.resetState();
    } else {
      this.setState({ error: true })
    }
  }

  resetState() {
    this.setState({ product: {}, quantity: '', error: false, selectedUnit: {}, conversions: [] })
  }

  onSelectedUnitChange(event) {
    const selected = this.state.conversions.find(conversion => conversion.key === event.target.value);
    this.setState({ selectedUnit: selected })
  }

  render () {
    const { product, error, selectedUnit, conversions, fetchingConversions } = this.state;
    const { searchPath } = this.props;

    return (
      <ProductAutocomplete product={product} onProductSelect={this.onProductSelect.bind(this)} searchPath={searchPath} topClassNames='row' wrapperClassNames='col-7' parasutProductNewUrl={this.props.parasutProductNewUrl} inputDataTid="input-ingredient-autocomplete">
        <div className="col-5">
          <div className="input-group">
            <input value={this.state.quantity} onChange={this.handleQuantity} className="form-control" placeholder='Miktar' type="number" min="0" step="0.0001" data-tid="input-ingredient-quantity" />
            {conversions.length > 1 ? (
                <select className="custom-select" value={selectedUnit.key} onChange={this.onSelectedUnitChange}>
                  {conversions.map(conversion => {
                    return <option value={conversion.key} key={conversion.key}>{conversion.unit}</option>
                  })}
                </select>
              ) : fetchingConversions ? (
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i className="fa fa-cog fa-spin"></i>
                  </span>
                </div>
              ) : (
                <div className="input-group-append">
                  <span className="input-group-text">{product.unit || 'Birim'}</span>
                </div>
              )
            }
            <a href="#" onClick={this.handleClick} className='btn btn-outline-info ml-2' data-tid="add-new-ingredient-btn">Ekle</a>
          </div>
        </div>
        { error &&
          <div className="col mt-1">
            {this.renderErrorDetail()}
          </div>
        }
      </ProductAutocomplete>
    )
  }

  renderErrorDetail() {
    switch(this.formValid()) {
      case 'product':
        return <span className='text-danger'>Paraşüt'e kayıtlı bir ürün seçmeniz gerekmekte!</span>;
      case 'quantity':
        return <span className='text-danger'>Miktar girmeniz gerekmekte!</span>;
      case 'unit':
        return (
          <span className='text-danger'>
            Seçtiğiniz ürünün Alış / Satış Birimi tanımlı değil. Lütfen <a href={this.props.parasutProductEditUrl.replace('parasut_product_id', this.state.product.parasut_id)} target="_blank" rel="noopener noreferrer" data-tid="update-product-unit-parasut-link">bu adresten</a> ürün için birim tanımlaması yapınız.
          </span>
        )
      case 'parasut_id':
        return <span className='text-danger'>Paraşüt tanımı geçerli değil. Lütfen farklı bir ürün seçiniz.</span>;
      default:
        return '';
    }
  }
}

IngredientForm.propTypes = {
  searchPath: PropTypes.string.isRequired,
  parasutProductEditUrl: PropTypes.string.isRequired,
  parasutProductNewUrl: PropTypes.string.isRequired,
  addIngredient: PropTypes.func.isRequired,
  conversionDetailsPath: PropTypes.string.isRequired,
  allowUnitConversion: PropTypes.bool.isRequired,
}

export default IngredientForm
