import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProductAutocomplete from './ProductAutocomplete';
import CreateNewProductModal from './CreateNewProductModal';
import Filters from '../Filterable/Filters';
import EmptyFilterPlaceholder from './IndexFilter/EmptyFilterPlaceholder';
import LocaleContext, { LocaleProp, I18n } from 'Context/LocaleContext';
import { isEmpty } from 'lodash';

const IndexHeader = (props) => {
  const {
    searchPath, createProductPath, form, translation, columns, currentFilters,
    predicates, currentQueryParameters, filterPath, units, can_create_product, links
  } = props;

  const SCOPE_OPTIONS = { scope: 'components.product.index_header', locale: translation.locale };

  const submitProductRef = useRef(null);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [newProductName, setNewProductName] = useState('');
  const [product, setProduct] = useState({});
  const vendor = product.vendor || {};

  const handleAddNewProduct = (value) => {
    setNewProductName(value);
    setIsModalOpened(true);
  };

  useEffect(() => {
    if (isEmpty(product)) {
      return;
    }

    submitProductRef.current.submit();
  }, [product]);

  return (
    <LocaleContext.Provider value={translation}>
      <div className="d-flex">
        <ProductAutocomplete
          searchPath={searchPath}
          onProductSelect={product => setProduct(product)}
          topClassNames="flex-grow-1"
          parasutProductNewUrl=""
          inputDataTid="product-index-top-bar-autocomplete"
          inputClassNames=""
          renderAddProductModal={true}
          onClickAddNewProduct={value => handleAddNewProduct(value)}
        />
        <div className="mb-1 ml-2">
          <Filters
            columns={columns}
            currentFilters={currentFilters}
            predicates={predicates}
            searchPath={filterPath}
            currentQueryParameters={currentQueryParameters}
            i18nScope='components.product.filterable'
            emptyFilterState={<EmptyFilterPlaceholder />}
          />
        </div>
        <div className="mb-3 ml-2">
          {can_create_product ?
            (<button
              type="button"
              className="btn btn-outline-primary btn-block"
              data-tid="product-index-new-product-btn"
              onClick={() => setIsModalOpened(true)}>{I18n.t('actions.add', SCOPE_OPTIONS)}
            </button>) : (<button
              type="button"
              className="btn btn-outline-primary btn-block"
              data-tid="product-index-new-product-btn"
              data-toggle="tooltip"
              data-original-title={I18n.t('tooltips.not_allowed_to_create', SCOPE_OPTIONS)}
              data-title={I18n.t('tooltips.not_allowed_to_create', SCOPE_OPTIONS)}
            >{I18n.t('actions.add', SCOPE_OPTIONS)}
            </button>
            )
          }
        </div>
      </div>
      {isModalOpened && (
        <CreateNewProductModal
          isModalOpened={isModalOpened}
          setIsModalOpened={modalState => setIsModalOpened(modalState)}
          newProductName={newProductName}
          form={form}
          units={units}
          onProductCreate={product => setProduct(product)}
          createProductPath={createProductPath}
          showBuyingPriceInput={false}
          showBarcodeInput={true}
          links={links}
        />
      )}

      <form ref={submitProductRef} action={form.action} method="post" data-remote={true} data-turbolinks-form={true} data-tid="product-index-search-form">
        <input type='hidden' name={form.csrf_param} value={form.csrf_token} />
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value={form.method} />
        <input type="hidden" name="product[id]" value={product.id} />
        <input type="hidden" name="product[name]" value={product.name} />
        <input type="hidden" name="product[unit]" value={product.unit} />
        <input type="hidden" name="product[parasut_id]" value={product.parasut_id} />
        <input type="hidden" name="product[code]" value={product.code} />
        <input type="hidden" name="product[barcode]" value={product.barcode} />
        <input type="hidden" name="product[vendor][id]" value={vendor.id} />
      </form>
    </LocaleContext.Provider>
  );
};

IndexHeader.propTypes = {
  form: PropTypes.object.isRequired,
  createProductPath: PropTypes.string.isRequired,
  searchPath: PropTypes.string.isRequired,
  filterPath: PropTypes.string.isRequired,
  translation: LocaleProp.isRequired,
  columns: PropTypes.object.isRequired,
  currentFilters: PropTypes.array.isRequired,
  currentQueryParameters: PropTypes.object.isRequired,
  predicates: PropTypes.object.isRequired,
  units: PropTypes.array.isRequired,
  can_create_product: PropTypes.bool.isRequired
};

export default IndexHeader;
