import React from 'react';
import I18n from '../i18n.js';
import PropTypes from 'prop-types';

export { I18n };

export const LocaleProp = PropTypes.shape({
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired,
});

const LocaleContext = React.createContext({});
export const LocaleProvider = LocaleContext.Provider;
export const LocaleConsumer = LocaleContext.Consumer;
export default LocaleContext;
