import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { I18n, LocaleProp } from 'Context/LocaleContext';

const ExchangeRates = (props) => {
  const { currencies, rates, form, translation } = props;
  const renderUSD = currencies.includes('USD');
  const renderEUR = currencies.includes('EUR');
  const renderGBP = currencies.includes('GBP');

  const [usdRate, setUsdRate] = useState(rates.USD || 1);
  const [eurRate, setEurRate] = useState(rates.EUR || 1);
  const [gbpRate, setGbpRate] = useState(rates.GBP || 1);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    setIsSubmitting(true);
    return true;
  };

  return (
    <React.Fragment>
      {currencies.length > 0 && (
        <form className="form-inline" action={form.action} method={form.method} data-remote data-turbolinks-form data-tid="exchange-rate-form" onSubmit={handleSubmit}>
          <input type="hidden" name={form.csrf_param} value={form.csrf_token} />
          <input name="utf8" type="hidden" value="✓" />

          {renderUSD && (
            <div className="form-group mr-2">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">USD/TRY</div>
                </div>
                <input type="number" value={usdRate} onChange={e => setUsdRate(e.target.value)} min="0" step="0.0001" className="form-control" required name="manufacturing_plan[exchange_rates][USD]" />
              </div>
            </div>
          )}

          {renderEUR && (
            <div className="form-group mr-2">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">EUR/TRY</div>
                </div>
                <input type="number" value={eurRate} onChange={e => setEurRate(e.target.value)} min="0" step="0.0001" className="form-control" required name="manufacturing_plan[exchange_rates][EUR]" />
              </div>
            </div>
          )}

          {renderGBP && (
            <div className="form-group mr-2">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">GBP/TRY</div>
                </div>
                <input type="number" value={gbpRate} onChange={e => setGbpRate(e.target.value)} min="0" step="0.0001" className="form-control" required name="manufacturing_plan[exchange_rates][GBP]" />
              </div>
            </div>
          )}

          <button type="submit" className={`btn btn-outline-secondary ${isSubmitting ? 'disabled' : ''}`} data-tid="exchange-rate-submit-btn" disabled={isSubmitting}>
            <i className={`fa fa-spinner fa-spin mr-1 ${isSubmitting ? '' : 'd-none'}`} data-behavior="loading" />
            {I18n.t('components.manufacturing_plan.exchange_rates.actions.save', { locale: translation.locale })}
          </button>
        </form>
      )}
    </React.Fragment>
  );
};

ExchangeRates.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  rates: PropTypes.shape({
    USD: PropTypes.any,
    EUR: PropTypes.any,
    GBP: PropTypes.any,
  }),
  form: PropTypes.shape({
    action: PropTypes.string,
    method: PropTypes.string,
    csrf_param: PropTypes.string,
    csrf_token: PropTypes.string,
  }),
  translation: LocaleProp.isRequired,
};

export default ExchangeRates;
