import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import { isEmpty } from 'lodash';

const StepTimeline = (props) => {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.workflow_plan.partials.step_timeline', locale: localeContext.locale };

  const { step } = props;
  const hasStartedBy = !isEmpty(step.started_by);
  const hasEndedBy = !isEmpty(step.ended_by);

  return (
    <ul className="basic-timeline">
      {hasStartedBy && (
        <li>
          <div className="d-flex">
            <span>{I18n.t('step_started_by', { ...SCOPE_OPTIONS, name: step.started_by.name })}</span>
            <small className="ml-auto mr-2 text-muted flex-shrink-0">{step.actual_start_date}</small>
          </div>
        </li>
      )}

      {hasEndedBy && (
        <li>
          <div className="d-flex">
            <span>{I18n.t('step_ended_by', { ...SCOPE_OPTIONS, name: step.ended_by.name })}</span>
            <small className="ml-auto mr-2 text-muted flex-shrink-0">{step.end_date}</small>
          </div>
        </li>
      )}
    </ul>
  );
};

StepTimeline.propTypes = {
  step: PropTypes.object.isRequired,
};

export default StepTimeline;
