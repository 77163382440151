import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import EditableField from './EditableField';
import { formProp } from '../../Helper/CommonProps';
import { I18n, LocaleProp } from 'Context/LocaleContext';

const CostAnalysisComponent = (props) => {
  const { plan_recipe, manufacturing_plan, form, translation, can_update_cost_calculation } = props;
  const {
    quantity,
    material_cost_per_unit,
    sales_price_per_unit,
    production_cost_per_unit,
    expected_profit_ratio,
    expected_profit_per_unit,
    expected_total_production_cost,
    expected_total_sales_revenue,
    expected_total_profit,
    cost_calculation_error,
    product,
  } = plan_recipe;
  const { name, unit } = product;
  const {
    calculating_recipe_cost,
    expenditure_cost_per_unit,
  } = manufacturing_plan;
  const SCOPE_OPTIONS = { scope: 'components.manufacturing_plan.plan_recipes.cost_analysis_component', locale: translation.locale }

  const currencyFormatter = new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  const materialCostPerUnit = currencyFormatter.format(material_cost_per_unit);
  const salesPricePerUnit = currencyFormatter.format(sales_price_per_unit);
  const productionCostPerUnit = currencyFormatter.format(production_cost_per_unit);
  const expectedProfitPerUnit = currencyFormatter.format(expected_profit_per_unit);
  const expectedTotalProductionCost = currencyFormatter.format(expected_total_production_cost);
  const expectedTotalSalesRevenue = currencyFormatter.format(expected_total_sales_revenue);
  const expectedTotalProfit = currencyFormatter.format(expected_total_profit);
  const formattedQuantity = currencyFormatter.format(quantity);
  const expenditureCostPerUnit = currencyFormatter.format(expenditure_cost_per_unit);

  const percentFormatter = new Intl.NumberFormat('tr-TR', { style: 'percent', minimumFractionDigits: 2 });
  const expectedProfitRatio = percentFormatter.format(expected_profit_ratio);

  const componentRef = useRef(null);

  const translate = (errorKey) => {
    switch (errorKey) {
      case 'exchange_rate_not_defined':
        return I18n.t('errors.exchange_rate_not_defined', SCOPE_OPTIONS);
      case 'undefined_expenditure_cost_per_unit_error':
        return I18n.t('errors.undefined_expenditure_cost_per_unit_error', SCOPE_OPTIONS);
      case 'undefined_total_cost_amount_on_recipe':
        return I18n.t('errors.undefined_total_cost_amount_on_recipe', SCOPE_OPTIONS);
      case 'expenditure_cost_not_try_error':
        return I18n.t('errors.expenditure_cost_not_try_error', SCOPE_OPTIONS);
      default:
        return I18n.t('errors.unknown', SCOPE_OPTIONS);
    }
  };

  useEffect(() => {
    const toolTips = componentRef.current.querySelectorAll('[data-toggle=tooltip]');
    toolTips.forEach(tooltip => new Tooltip(tooltip));
  });

  return (
    <React.Fragment>
      <div className="card shadow border" ref={componentRef}>
        <div className="card-header">
          <div className="card-title">
            <h5 className="text-truncate" data-title={name} data-toggle="tooltip" data-original-title={name}>{name}</h5>
          </div>
        </div>
        {calculating_recipe_cost ? (
          <div className="card-body">
            <div className="d-flex justify-content-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">{I18n.t('loading', SCOPE_OPTIONS)}</span>
              </div>
            </div>
          </div>
        ) : (
            <React.Fragment>
              {cost_calculation_error && (
                <div className="card-body bg-danger text-white">
                  {translate(cost_calculation_error)}
                </div>
              )}
              <div className="card-body">
                <div className="item-analyse-list">
                  <div className="item-analyse-list-item pb-1">
                    <span className="desc">{I18n.t('material_cost_per_unit', SCOPE_OPTIONS)}</span>
                    <span className="cost text-monospace">
                      {materialCostPerUnit}
                      <i className="fa fa-try pl-1" />
                    </span>
                  </div>
                  <div className="item-analyse-list-item pb-1">
                    <span className="desc">{I18n.t('expenditure_cost_per_unit', SCOPE_OPTIONS)}</span>
                    <span className="cost text-monospace">
                      {expenditureCostPerUnit}
                      <i className="fa fa-try pl-1" />
                    </span>
                  </div>
                  <div className="item-analyse-list-item pb-1">
                    <span className="desc">
                      {I18n.t('production_cost_per_unit.description', SCOPE_OPTIONS)}
                      <i className="fa fa-info-circle pl-1" data-title={I18n.t('production_cost_per_unit.title', SCOPE_OPTIONS)} data-toggle="tooltip" data-original-title={I18n.t('production_cost_per_unit.title', SCOPE_OPTIONS)} />
                    </span>
                    <span className="cost text-monospace text-info">
                      {productionCostPerUnit}
                      <i className="fa fa-try pl-1" />
                    </span>
                  </div>
                </div>
                <hr />
                <div className="item-analyse-list">
                  <div className="item-analyse-list-item pb-1">
                    <span className="desc">
                      {I18n.t('expected_profit_ratio.description', SCOPE_OPTIONS)}
                      <i className="fa fa-info-circle pl-1" data-title={I18n.t('expected_profit_ratio.title', SCOPE_OPTIONS)} data-toggle="tooltip" data-original-title={I18n.t('expected_profit_ratio.title', SCOPE_OPTIONS)} />
                    </span>
                    <div className="cost">
                      <EditableField rawValue={expected_profit_ratio * 100} form={form} fieldName="expected_profit_ratio" enabled={can_update_cost_calculation}>
                        <span className="text-monospace">{expectedProfitRatio}</span>
                      </EditableField>
                    </div>
                  </div>
                  <div className="item-analyse-list-item pb-1">
                    <span className="desc">
                      {I18n.t('expected_profit_per_unit', SCOPE_OPTIONS)}
                    </span>
                    <div className="cost">
                      <EditableField rawValue={expected_profit_per_unit} form={form} fieldName="expected_profit_per_unit" enabled={can_update_cost_calculation}>
                        <span className="text-monospace">
                          {expectedProfitPerUnit}
                          <i className="fa fa-try pl-1" />
                        </span>
                      </EditableField>
                    </div>
                  </div>
                  <div className="item-analyse-list-item pb-1">
                    <span className="desc">
                      {I18n.t('sales_per_unit.description', SCOPE_OPTIONS)}
                      <i className="fa fa-info-circle pl-1" data-title={I18n.t('sales_per_unit.title', SCOPE_OPTIONS)} data-toggle="tooltip" data-original-title={I18n.t('sales_per_unit.title', SCOPE_OPTIONS)} />
                    </span>
                    <div className="cost">
                      <EditableField rawValue={sales_price_per_unit} form={form} fieldName="sales_price_per_unit" enabled={can_update_cost_calculation}>
                        <span className="text-monospace text-info">
                          {salesPricePerUnit}
                          <i className="fa fa-try pl-1" />
                        </span>
                      </EditableField>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="item-analyse-list">
                  <div className="item-analyse-list-item pb-1">
                    <span className="desc">{I18n.t('quantity', SCOPE_OPTIONS)}</span>
                    <span className="cost text-monospace">
                      {formattedQuantity}
                      &nbsp;
                      {unit}
                    </span>
                  </div>
                  <div className="item-analyse-list-item pb-1">
                    <span className="desc">{I18n.t('expected_total_production_cost', SCOPE_OPTIONS)}</span>
                    <span className="cost text-monospace">
                      {expectedTotalProductionCost}
                      <i className="fa fa-try pl-1" />
                    </span>
                  </div>
                  <div className="item-analyse-list-item pb-1">
                    <span className="desc">{I18n.t('expected_total_sales_revenue', SCOPE_OPTIONS)}</span>
                    <span className="cost text-monospace">
                      {expectedTotalSalesRevenue}
                      <i className="fa fa-try pl-1" />
                    </span>
                  </div>
                  <div className="item-analyse-list-item pb-1">
                    <span className="desc">{I18n.t('expected_total_profit', SCOPE_OPTIONS)}</span>
                    <span className={`cost text-monospace ${expected_total_profit >= 0 ? 'text-success' : 'text-danger'}`}>
                      {expectedTotalProfit}
                      <i className="fa fa-try pl-1" />
                    </span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
      </div>
    </React.Fragment>
  );
};

CostAnalysisComponent.propTypes = {
  form: formProp.isRequired,
  plan_recipe: PropTypes.object.isRequired,
  manufacturing_plan: PropTypes.shape({
    calculating_recipe_cost: PropTypes.bool.isRequired,
    expenditure_cost_per_unit: PropTypes.string.isRequired,
    expenditure_cost_currency: PropTypes.any,
  }).isRequired,
  translation: LocaleProp.isRequired,
  can_update_cost_calculation: PropTypes.bool.isRequired,
};

export default CostAnalysisComponent;
