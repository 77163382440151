import { useReducer } from 'react';

const useCollectionManagerReducer = (initialState, emptyObject) => useReducer((objects, action) => {
  switch (action.type) {
    case 'add':
      return [...objects, emptyObject];
    case 'update':
      return objects.map((planRecipe, i) => {
        if (i === action.index) {
          return action.planRecipe;
        }
        return planRecipe;
      });
    default:
      return objects;
  }
}, initialState);

export default useCollectionManagerReducer;


// used for planExpenditure, basically copy of the above example. need to find a way to manage them properly
export const usePlanExpenditureReducer = (initialState, emptyObject) => useReducer((objects, action) => {
  switch (action.type) {
    case 'add':
      return [...objects, emptyObject];
    case 'update':
      return objects.map((planExpenditure, i) => {
        if (i === action.index) {
          return action.planExpenditure;
        }
        return planExpenditure;
      });
    default:
      return objects;
  }
}, initialState);
