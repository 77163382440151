import React from 'react';
import PropTypes from 'prop-types';
import { formProp } from '../../Helper/CommonProps';

const SubmitForm = React.forwardRef((props, ref) => {
  const {
    form,
    description,
    issueDate,
    planRecipes,
    planExpenditures,
  } = props;

  const planRecipeInputName = index => `manufacturing_plan[plan_recipes_attributes][${index}]`;
  const planExpenditureInputName = index => `manufacturing_plan[plan_expenditures_attributes][${index}]`;

  return (
    <React.Fragment>
      <form action={form.action} method="post" data-remote data-turbolinks-form data-tid="manufacturing-plan-form" ref={ref}>
        <input type="hidden" name={form.csrf_param} value={form.csrf_token} />
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value={form.method} />

        <input type="hidden" value={description || ''} name="manufacturing_plan[description]" />
        <input type="hidden" value={issueDate} name="manufacturing_plan[issue_date]" />

        {planRecipes.filter(planRecipe => !(planRecipe.id == null && planRecipe.deleted)).map((planRecipe, i) => (
          <React.Fragment key={`plan-recipe-values-${i}`}>
            <input type="hidden" value={planRecipe.recipe.id || ''} name={`${planRecipeInputName(i)}[recipe][id]`} />
            <input type="hidden" value={planRecipe.product.id || ''} name={`${planRecipeInputName(i)}[product][id]`} />
            <input type="hidden" value={planRecipe.quantity || ''} name={`${planRecipeInputName(i)}[quantity]`} />
            {/* representational inputs for forms when error */}
            <input type="hidden" value={planRecipe.recipe.description || ''} name={`${planRecipeInputName(i)}[recipe][description]`} />
            <input type="hidden" value={planRecipe.product.name || ''} name={`${planRecipeInputName(i)}[product][name]`} />
            <input type="hidden" value={planRecipe.product.unit || ''} name={`${planRecipeInputName(i)}[product][unit]`} />
            <input type="hidden" value={planRecipe.deleted || false} name={`${planRecipeInputName(i)}[deleted]`} />
          </React.Fragment>
        ))}

        {planExpenditures.filter(planExpenditure => !(planExpenditure.id == null && planExpenditure.deleted)).map((planExpenditure, i) => (
          <React.Fragment key={`plan-expenditures-values-${i}`}>
            <input type="hidden" value={planExpenditure.description || ''} name={`${planExpenditureInputName(i)}[description]`} />
            <input type="hidden" value={planExpenditure.expected_spend || ''} name={`${planExpenditureInputName(i)}[expected_spend]`} />
            <input type="hidden" value={planExpenditure.currency || ''} name={`${planExpenditureInputName(i)}[currency]`} />
          </React.Fragment>
        ))}
      </form>
    </React.Fragment>
  );
});

SubmitForm.propTypes = {
  form: formProp.isRequired,
  description: PropTypes.any,
  issueDate: PropTypes.any,
  planRecipes: PropTypes.array.isRequired,
  planExpenditures: PropTypes.array.isRequired,
};

export default SubmitForm;
