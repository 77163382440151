import React, { useEffect, useState } from 'react';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import moment from 'moment';
import BlurredGraphImageSrc from 'stats/blurred-graph.png';
import LineChart from './LineChart';
import { assignColor } from './ChartColors';

const MostProductsChart = (props) => {
  const { fetchUrl, goToUrl, translation } = props;
  const SCOPE_OPTIONS = { scope: 'components.dashboard.charts.most_products_chart', locale: translation.locale };
  // not sure this is the right way :/ it must be defined globally
  moment.locale(translation.locale);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const chartOptions = {
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        title: tooltipItem => moment(tooltipItem[0].label).format('D MMMM dddd'),
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label || '';
          const itemData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const unit = itemData.unit || '';
          if (tooltipItem.yLabel > 0) {
            return `${label}: ${tooltipItem.yLabel} ${unit}`;
          }
        },
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'D MMM',
          },
        },
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{
        gridLines: {
          drawBorder: false,
          display: true,
          color: '#afb2c5',
          borderDash: [2, 5],
        },
        ticks: {
          padding: 10
        }
      }]
    },
  };

  const beautifyChartData = (response) => {
    const datasets = [];
    response.datasets.forEach((dataset, i) => {
      // this is where dataset becomes beautiful
      const group = {
        fill: false,
        backgroundColor: assignColor(i),
        borderColor: assignColor(i),
        ...dataset,
      };
      datasets.push(group);
    });
    setChartData({ ...response, datasets });
  };

  useEffect(() => {
    const fetchChartData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const rawResponse = await fetch(fetchUrl);
        const response = await rawResponse.json();
        beautifyChartData(response);
      } catch (e) {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchChartData();
  }, [fetchUrl]);

  let renderedComponent;
  if (chartData.datasets.length === 0) {
    renderedComponent = (
      <React.Fragment>
        <div style={{ position: 'relative' }}>
          <div className="d-flex justify-content-center">
            <img src={BlurredGraphImageSrc} alt="placeholder 960" className="img-fluid" />
          </div>
          <div className="most-products-chart-new-action-btn-wrapper">
            <p className="lead font-weight-normal">{I18n.t('empty', SCOPE_OPTIONS)}</p>
            <a href={goToUrl} className="btn btn-dark" data-tid="most-products-chart-btn">{I18n.t('actions.new_mr_record', SCOPE_OPTIONS)}</a>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    renderedComponent = (
      <LineChart {...chartData} options={chartOptions} />
    );
  }

  return (
    <LocaleContext.Provider value={translation}>
      {
        isLoading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">{I18n.t('loading', SCOPE_OPTIONS)}</span>
            </div>
          </div>
        ) : (
            isError ? (
              <small className="text-danger text-monospace">
                {I18n.t('errors.unknown', SCOPE_OPTIONS)}
              </small>
            ) : (
                <div key={`chart-${Math.random()}`}>{renderedComponent}</div>
              )
          )
      }
    </LocaleContext.Provider>
  );
};

MostProductsChart.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
  goToUrl: PropTypes.string.isRequired,
  translation: LocaleProp.isRequired,
};

export default MostProductsChart;
