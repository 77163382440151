import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import StepListItem from './Partials/StepListItem';
import StepDetail from './Partials/StepDetail';
import useCollectionManagerReducer from '../Helper/Hooks/UseCollectionManagerReducer';

const Show = (props) => {
  const { translation, workflow_plan } = props;
  const { product } = workflow_plan;

  const SCOPE_OPTIONS = { scope: 'components.workflow_plan.show', locale: translation.locale };

  const [workflowSteps, dispatchWorkflowSteps] = useCollectionManagerReducer(workflow_plan.workflow_steps, {});
  const updateStep = (index, step) => dispatchWorkflowSteps({ type: 'update', index, object: step });

  const [selectedStepIndex, setSelectedStepIndex] = useState(0);
  const selectedStep = workflowSteps[selectedStepIndex];

  return (
    <LocaleContext.Provider value={translation}>
      <div className="row">
        <div className="col-12">
          <h2>{I18n.t('title', { ...SCOPE_OPTIONS, name: product.name, quantity: workflow_plan.quantity })}</h2>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-5">
          <div className="list-group list-group-flush border-top border-bottom">
            {
              workflowSteps.map((step, i) => (
                <StepListItem
                  key={`step-list-item${i}`}
                  index={i}
                  step={step}
                  isActive={i==selectedStepIndex}
                  onClick={setSelectedStepIndex}
                />
              ))
            }
          </div>
        </div>

        <div className="col-6">
          <StepDetail step={selectedStep} index={selectedStepIndex} onStepUpdate={updateStep} />
        </div>
      </div>
    </LocaleContext.Provider>
  );
};


Show.propTypes = {
  translation: LocaleProp.isRequired,
};

export default Show;
