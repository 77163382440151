import React, { useState, useRef, useReducer } from 'react';
import PropTypes from 'prop-types';
import SubmitForm from './Forms/SubmitForm';
import LocaleContext, { LocaleProp } from 'Context/LocaleContext';
import ProductForm from './Forms/ProductForm';
import { formProp } from '../Helper/CommonProps';

const EditForm = (props) => {
  const { product, translation, links, units } = props;
  const { form } = links.update_product_path;
  const [formValues, dispatchFormValue] = useReducer((formValues, action) => {
    switch (action.type) {
      case 'update':
        return { ...formValues, ...action.payload };
      default:
        return formValues;
    }
  }, product);
  const submitFormRef = useRef(null);

  const submitProduct = () => {
    submitFormRef.current.submit();
  }

  return (
    <LocaleContext.Provider value={translation}>
      <ProductForm
        product={product}
        formValues={formValues}
        dispatchFormValue={dispatchFormValue}
        submitProduct={submitProduct}
        units={units}
        links={links}
        form={form}
      />
      <SubmitForm
        form={form}
        ref={submitFormRef}
        formValues={formValues}
      />
    </LocaleContext.Provider>
  );
};

EditForm.propTypes = {
  product: PropTypes.object.isRequired,
  links: PropTypes.shape({
    update_product_path: PropTypes.shape({
      form: formProp.isRequired,
    }),
  }).isRequired,
  translation: LocaleProp.isRequired,
  units: PropTypes.array.isRequired,
};

export default EditForm;
