import React from "react"
import PropTypes from "prop-types"
import ParasutIconImageSrc from 'icons/parasut_icon.png'

class ParasutIcon extends React.Component {
  render () {
    return (
      <img className="parasut-link-icon-16" src={ParasutIconImageSrc} alt="Parasut icon" />
    );
  }
}

export default ParasutIcon
