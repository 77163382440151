import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import RecipeAutocomplete from '../../ManufacturingPlan/RecipeAutocomplete';
import { validRecipe, validQuantity } from '../../Helper/Validators/ManufacturingPlanValidator';

const PlanRecipeForm = (props) => {
  const {
    index,
    planRecipe,
    updatePlanRecipe,
    removePlanRecipe,
    calculatedIdentifier,
    renderFormErrors,
    links,
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_manufacturing_plan.forms.plan_recipe_form', locale: localeContext.locale };
  const { quantity, product, recipe } = planRecipe;
  const unit = product.unit || I18n.t('unit', SCOPE_OPTIONS);

  const onRecipeSelect = (recipe) => {
    updatePlanRecipe(index, { ...planRecipe, product: recipe.product, recipe: recipe });
  };
  const handleQuantity = e => updatePlanRecipe(index, { ...planRecipe, quantity: e.target.value });
  const markAsDeleted = () => removePlanRecipe(index, planRecipe);

  const recipeInvalid = !validRecipe(recipe, product);
  const quantityInvalid = !validQuantity(quantity);

  return (
    <div className={`plan-recipe-item mt-2 ${planRecipe.deleted ? 'd-none' : ''}`}>
      <div className="d-flex flex-wrap row">
        <span className="font-18 font-weight-light text-black-50 col-form-label">{calculatedIdentifier}</span>
        <RecipeAutocomplete
          recipe={recipe}
          onRecipeSelect={onRecipeSelect}
          searchPath={links.recipe_search_path}
          topClassNames="flex-grow-1 ml-3"
          wrapperClassNames=""
          inputDataTid="input-plan-recipe-autocomplete"
          newRecipeUrl={links.new_recipe_path}
          inputClassNames={`${renderFormErrors && recipeInvalid ? 'is-invalid' : ''}`}
        >
          {renderFormErrors && recipeInvalid && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {I18n.t('auto_complete.feedback', SCOPE_OPTIONS)}
            </div>
          )}
        </RecipeAutocomplete>
        <div className="ml-3" style={{ maxWidth: '250px' }}>
          <div className="input-group">
            <input value={quantity || ''} onChange={handleQuantity} className={`form-control ${renderFormErrors && quantityInvalid && 'is-invalid'}`} type="number" required min="0" step="0.0001" data-tid="input-plan-recipe-quantity" placeholder={I18n.t('quantity.placeholder', SCOPE_OPTIONS)} />
            <div className="input-group-append">
              <span className="input-group-text">{unit}</span>
            </div>
            <div className="invalid-feedback">
              {I18n.t('quantity.feedback', SCOPE_OPTIONS)}
            </div>
          </div>
        </div>
        <div className="col-form-label ml-3">
          <button type="button" className="close close-danger" data-dismiss="alert" aria-label="Close" onClick={markAsDeleted} data-tid="remove-plan-recipe-btn">
            <i className="fa fa-trash" style={{ fontSize: '1.1em' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

PlanRecipeForm.propTypes = {
  index: PropTypes.number.isRequired,
  planRecipe: PropTypes.shape({
    recipe: PropTypes.object,
    product: PropTypes.object,
    quantity: PropTypes.any,
  }).isRequired,
  updatePlanRecipe: PropTypes.func.isRequired,
  removePlanRecipe: PropTypes.func.isRequired,
  calculatedIdentifier: PropTypes.number,
  renderFormErrors: PropTypes.bool.isRequired,
  links: PropTypes.shape({
    recipe_search_path: PropTypes.string.isRequired,
    new_recipe_path: PropTypes.string.isRequired,
  }).isRequired,
};

export default PlanRecipeForm;
