import React, { useState, useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import CreateColumnModal from './CreateColumnModal';

const NewColumn = (props) => {
  const { board, fetchBoard, links } = props;
  const localeContext = useContext(LocaleContext);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleCreateColumn = () => {
    setIsModalOpened(true);
  }

  return (
    <React.Fragment>
      <div className="column-new mr-3 justify-content-center align-items-center d-flex">
        <div>
          <button type="button" onClick={() => handleCreateColumn(true)} className="btn btn-outline-dark btn-sm">
            <i className="fas fa-plus mr-1" />
            {I18n.t('components.board.elements.new_column.actions.add', { locale: localeContext.locale })}
          </button>
        </div>
      </div>
      {isModalOpened && (
        <CreateColumnModal
          isModalOpened={isModalOpened}
          setIsModalOpened={setIsModalOpened}
          boardId={board.id}
          onUpdate={fetchBoard}
          links={links}
        />
      )}
    </React.Fragment>
  );
}

NewColumn.propTypes = {
  board: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
  fetchBoard: PropTypes.func.isRequired,
}

export default NewColumn;