import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import DateRangePicker from './DateRangePicker';

const PresetDateRangePicker = (props) => {
  const { onDatesChange, presets } = props;

  const renderDatePresets = () => {
    return (
      <div className="d-flex flex-row mt-4">
        {presets.map(({ text, start, end }) => {
          return (
            <button
              key={text}
              className="btn btn-outline-info mb-2 mx-2"
              type="button"
              data-tid={`preset-date-ranger-picker-${text}-preset`}
              onClick={() => onDatesChange({ startDate: start, endDate: end })}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  }

  return (
    <DateRangePicker
      {...props}
      renderCalendarInfo={() => renderDatePresets()}
      customCloseIcon={<i className="fas fa-times text-black-50" />}
    />
  )
}

PresetDateRangePicker.propTypes = {
  presets: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    start: momentPropTypes.momentObj,
    end: momentPropTypes.momentObj,
  })),
}

export default PresetDateRangePicker;
