import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';

const Predicate = (props) => {
  const {
    values, selected, onChange
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.filterable.predicate', locale: localeContext.locale };
  const currentValue = selected || "DEFAULT";

  return (
    <select value={currentValue} className="custom-select custom-select-sm ml-2" onChange={onChange} onSelect={onChange} data-tid="filterable-predicate-select">
      <option value="DEFAULT" disabled>{I18n.t('default_option', SCOPE_OPTIONS)}</option>
      {
        values.map((predicate, i) => {
          return (
            <option key={`predicate-${i}`} value={predicate} data-tid={`filterable-predicate-select-option-${predicate}`}>
              {I18n.t(`values.${predicate}`, SCOPE_OPTIONS)}
            </option>
          )
        })
      }
    </select>
  )
};

Predicate.propTypes = {
  values: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default Predicate;
