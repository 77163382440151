import React from "react"
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from "prop-types"
import Autocomplete from 'react-autocomplete'
import { debounce, isEmpty } from 'lodash'

const SCOPE_OPTIONS = { scope: 'components.customer.customer_autocomplete' };

class CustomerAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    const customer = this.props.customer || {};
    this.state = {
      value: (customer.name || ''),
      items: [customer],
      loading: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.customer !== nextProps.customer) {
      const customer = nextProps.customer || {};
      // console.log('nextProps', nextProps)
      this.setState({ ...this.state, value: customer.name || '' })
    }
  }

  handleFocusOut(e) {
    if (!this.state.value) {
      // console.log('no value', this.state.value)
      this.handleSelect(null, {})
    }
  }

  handleChange(event, value) {
    this.setState({ value: value, loading: true, items: [] })
    this.onChangeDebounced(value)
  }

  onChangeDebounced = debounce(this.fetchSearchResults, 500)

  fetchSearchResults(value) {
    value = value.split('%').join('');
    fetch(`${this.props.searchPath}?query=${value}&filter[is_vendor_eq]=${this.props.onlyVendors}`, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json()
    }).then((json) => {
      // console.log('parsed json', json)
      let parsedResponse = json;
      if (this.props.renderAddCustomerModal) {
        // this item is used as a placeholder in autocomplete list item to trigger new product creation flow
        parsedResponse.push({ addNewPlaceholder: true, name: I18n.t('result.placeholder', { ...SCOPE_OPTIONS, locale: this.context.locale }), id: 'NONE' })
      }
      this.setState({ ...this.state, items: parsedResponse, loading: false });
    }).catch((ex) => {
      // console.log('parsing failed', ex)
    })
  }

  handleSelect(value, item) {
    if (item.addNewPlaceholder) {
      this.input.blur();
      this.props.onClickAddNewCustomer(this.state.value);
      this.setState({ value: '' })
    } else {
      this.setState({ value: item.name });
      this.props.onCustomerSelect(item);
    }
  }

  render() {
    return (
      <LocaleContext.Consumer>
        {({ locale }) => {
          return (<div className={this.props.topClassNames}>
            <Autocomplete
              ref={el => this.input = el}
              value={this.state.value}
              onChange={this.handleChange.bind(this)}
              onSelect={(value, item) => this.handleSelect(value, item)}
              items={this.state.items}
              getItemValue={(item) => item.name}
              inputProps={{ className: `form-control ${this.props.inputClassNames}`, placeholder: I18n.t(this.props.placeholderKey, { locale: locale }), 'data-tid': this.props.inputDataTid }}
              wrapperProps={{ className: this.props.wrapperClassNames, style: { display: 'inline', ...this.props.wrapperPropsStyles } }}
              // open={true}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`py-1 px-2 ${isHighlighted ? 'item-highlighted' : ''}`}
                  style={{ background: isHighlighted ? '#4095bf' : 'white' }}
                  key={item.id} data-tid="autocomplete-list-item">
                  <span>{item.name} {!isEmpty(item.tax_number) && (`(${item.tax_number})`)}</span>
                </div>
              )}
              renderMenu={(items, value, style) => (
                <div className="react-autocomplete-menu fixed-menu-autocomplete" style={{ minWidth: style.minWidth }}>
                  {value === '' ? (
                    <div className="py-1 px-2">
                      {I18n.t('hint', { ...SCOPE_OPTIONS, locale: locale })}
                    </div>
                  ) : this.state.loading ? (
                    <div className="py-1 px-2">
                      <i className="fa fa-cog fa-spin"></i>
                      <span className="ml-1">{I18n.t('loading', { ...SCOPE_OPTIONS, locale: locale })}</span>
                    </div>
                  ) : items}
                </div>
              )}

            />
            {this.props.children}
          </div>)
        }}
      </LocaleContext.Consumer>
    )
  }
}

CustomerAutocomplete.defaultProps = {
  placeholderKey: 'components.customer.customer_autocomplete.search.placeholder',
}

CustomerAutocomplete.propTypes = {
  customer: PropTypes.object,
  searchPath: PropTypes.string.isRequired,
  onCustomerSelect: PropTypes.func.isRequired,
  wrapperPropsStyles: PropTypes.object,
  wrapperClassNames: PropTypes.string,
  topClassNames: PropTypes.string,
  inputDataTid: PropTypes.string,
  inputClassNames: PropTypes.string,
  renderAddCustomerModal: PropTypes.bool,
  onClickAddNewCustomer: PropTypes.func,
  placeholderKey: PropTypes.string,
}

CustomerAutocomplete.contextType = LocaleContext;

export default CustomerAutocomplete;
