import React, { useContext, useState, useEffect } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import ProductAutocompleteWithCreateModal from '../../Product/ProductAutocompleteWithCreateModal';
import searchProducts from './searchProducts';

const RecipeDetails = (props) => {
  const {
    form,
    outputQuantity,
    setOutputQuantity,
    product,
    setProduct,
    description,
    setDescription,
    units,
    links
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_recipe.forms.edit_recipe_form', locale: localeContext.locale };
  const unit = product.unit || I18n.t('unit', SCOPE_OPTIONS);

  // search new product related variables
  const [isSearching, setIsSearching] = useState(false);
  const [isError, setIsError] = useState(false);
  const hasNewProduct = (product && product.id) ? false : true;
  useEffect(() => {
    if (product && product.code) {
      searchProducts(product.code, form.product_search_path, setProduct, setIsSearching, setIsError);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="row mt-5">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <span className="text-center font-20 text-black-50 font-weight-light">
            {I18n.t('description', SCOPE_OPTIONS)}
          </span>
        </div>

        <div className="offset-lg-2 col-lg-8 mt-4">
          <input
            value={description || ''}
            onChange={e => setDescription(e.target.value)}
            className="form-control form-control-lg"
            type="text"
            required
            data-tid="input-recipe-description"
            placeholder={I18n.t('placeholder', SCOPE_OPTIONS)}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8">
          <hr />
        </div>
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <span className="text-center font-20 text-black-50 font-weight-light">
            {I18n.t('product', SCOPE_OPTIONS)}
          </span>
        </div>

        <div className="offset-lg-2 col-lg-8 mt-4">
          <div className="d-flex row justify-content-center">
            <ProductAutocompleteWithCreateModal
              links={links}
              units={units}
              product={product}
              onProductSelect={(product) => {
                setProduct(product);
              }}
              searchPath={form.product_search_path}
              topClassNames="col-lg-7"
              wrapperClassNames=""
              parasutProductNewUrl={form.parasut_product_new_url}
              inputDataTid="input-product-autocomplete"
              inputClassNames="form-control-lg"
              form={form}
              createProductPath={form.create_product_path}
              showBuyingPriceInput={false}
            >
              <div className="small mt-1">
                {isSearching && (
                  <i className="fa fa-spinner fa-spin mr-1"></i>
                )}
                {hasNewProduct ? (
                  <React.Fragment>
                    <span className="badge badge-warning text-white">YENİ</span>
                    <span className="ml-1 text-black-50">Bu ürün yeni oluşturulacak!</span>
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      <span className="badge badge-secondary">KAYITLI</span>
                      <span className="ml-1 text-black-50">Hali hazırda kayıtlı bir ürün</span>
                    </React.Fragment>
                  )
                }
                {isError && (
                  <i className="fa fa-exclamation-triangle text-danger ml-1"></i>
                )}
              </div>
            </ProductAutocompleteWithCreateModal>
            <div className="col-lg-4 col-10">
              <div
                className="input-group"
                data-tid="input-product-output-quantity-group"
              >
                <input
                  value={outputQuantity || ''}
                  onChange={e => setOutputQuantity(e.target.value)}
                  className="form-control form-control-lg"
                  type="number"
                  required
                  min="0"
                  step="0.0001"
                  data-tid="input-product-quantity"
                  placeholder={I18n.t('quantity', SCOPE_OPTIONS)}
                />
                <div className="input-group-append">
                  <span className="input-group-text">{unit}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

RecipeDetails.propTypes = {
  form: PropTypes.object.isRequired,
  product: PropTypes.object,
  outputQuantity: PropTypes.node,
  description: PropTypes.node,
  setOutputQuantity: PropTypes.func.isRequired,
  setProduct: PropTypes.func.isRequired,
  setDescription: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  links: PropTypes.object.isRequired
};

export default RecipeDetails;
