import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RecipeForm from './RecipeImport/RecipeForm';
import FlashAlert from '../FlashMessage/FlashAlert';

const RecipeImportHandler = (props) => {
  const { import_data, form, translation, feature_flags, currentView, units } = props;
  const [cursor, setCursor] = useState({ importIndex: 0, recipe: import_data[0] });

  const renderNextRecipe = () => {
    const importIndex = cursor.importIndex + 1;
    setCursor({ importIndex: importIndex, recipe: import_data[importIndex] });
  }

  const renderPreviousRecipe = () => {
    const importIndex = cursor.importIndex - 1;
    setCursor({ importIndex: importIndex, recipe: import_data[importIndex] });
  }

  const [flashMessage, setFlashMessage] = useState(null);
  const renderFlashMessage = (type, text) => {
    const message = { id: Math.random(), type: type, text: text };
    setFlashMessage(message);
  }

  return (
    <React.Fragment>
      <div className="card border-info mb-3 mx-4">
        <div className="card-body text-info">
          <div className="d-flex align-items-baseline">
            <h5 className="card-title">Recipe Import Handler | Current ({cursor.importIndex}) / Found ({import_data.length})</h5>
            <p className="card-text ml-auto">
              <button onClick={renderPreviousRecipe} className='btn btn-warning mr-2'>Previous</button>
              <button onClick={renderNextRecipe} className='btn btn-info'>Next</button>
            </p>
          </div>
        </div>
      </div>

      {flashMessage && (
        <div className='px-4'>
          <FlashAlert key={flashMessage.id} message={flashMessage} onClose={() => setFlashMessage(null)} />
        </div>
      )}

      {cursor.recipe &&
        <RecipeForm recipe={cursor.recipe} key={`recipe-form-import-${cursor.importIndex}`} form={form} feature_flags={feature_flags} translation={translation} currentView={currentView} onSuccess={renderNextRecipe} renderFlashMessage={renderFlashMessage} units={units} links={links} />
      }
    </React.Fragment>
  )
}

RecipeImportHandler.propTypes = {};

export default RecipeImportHandler;
