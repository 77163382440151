import React from "react"
import PropTypes from "prop-types"
import FlashAlert from './FlashAlert'
import update from 'immutability-helper'

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = { messages: props.messages };
  }

  removeMessage(message) {
    const index = this.state.messages.indexOf(message)
    const messages = update(this.state.messages, { $splice: [[index, 1]] })
    this.setState({ messages });
  }

  render () {
    const { messages } = this.state;

    return (
      messages.map( (message, index) =>
        <FlashAlert key={`${message.id}-${index}`} message={message} onClose={() => this.removeMessage(message)} />
      )
    );
  }
}

List.propTypes = {
  messages: PropTypes.array.isRequired
}

export default List
