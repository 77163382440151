import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import { isEmpty } from 'lodash';
import moment from 'moment';

const AssigneeChanged = ({ text, date }) => {
  return (
    <li>
      <div className="d-flex">
        <span>{text}</span>
        <small className="ml-auto mr-2 text-muted flex-shrink-0">{date}</small>
      </div>
    </li>
  );
};

const StatusChanged = ({ text, date }) => {
  return (
    <li>
      <div className="d-flex">
        <span>{text}</span>
        <small className="ml-auto mr-2 text-muted flex-shrink-0">{date}</small>
      </div>
    </li>
  );
};

const TaskTimeline = (props) => {
  const { logs, translation } = props;

  const localeContext = isEmpty(translation) ? useContext(LocaleContext) : translation;
  const SCOPE_OPTIONS = { scope: 'components.supply_planning.partials.task_timeline', locale: localeContext.locale };
  moment.locale(localeContext.locale);

  if (isEmpty(logs)) {
    return (
      <span className="d-flex justify-content-center mt-2">{I18n.t('no_activity_found', SCOPE_OPTIONS)}</span>
    );
  } else {
    return (
      <ul className="basic-timeline">
        {
          logs.map(({ action, audit_changes, created_at, user }) => {
            if (action == 'assignee_changed') {
              return <AssigneeChanged
                text={I18n.t('task_assigned_to', {
                  ...SCOPE_OPTIONS,
                  name: audit_changes['assignee_name'] || I18n.t('nobody', SCOPE_OPTIONS),
                  user: user ? user.name.split(' ')[0] : ''
                })}
                date={moment(created_at).format('L')} />
            }

            if (action == 'status_changed') {
              return <StatusChanged
                text={I18n.t('status_changed', {
                  ...SCOPE_OPTIONS,
                  from: I18n.t(`statuses.${audit_changes['status_was']}`, SCOPE_OPTIONS),
                  to: I18n.t(`statuses.${audit_changes['status']}`, SCOPE_OPTIONS),
                  user: user ? user.name.split(' ')[0] : ''
                })}
                date={moment(created_at).format('L')} />
            }
          })
        }
      </ul>
    );
  }

};

TaskTimeline.propTypes = {
  logs: PropTypes.array,
  translation: PropTypes.object,
};

TaskTimeline.defaultProps = {
  translation: {},
};

export default TaskTimeline;
