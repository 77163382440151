import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import { validProduct } from '../../Helper/Validators/IngredientValidator';
import ProductAutocompleteWithCreateModal from '../../Product/ProductAutocompleteWithCreateModal';

const RecipeForm = (props) => {
  const {
    form,
    outputQuantity,
    setOutputQuantity,
    product,
    setProduct,
    onClick,
    units,
    links
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_recipe.forms.recipe_form', locale: localeContext.locale };
  const unit = product.unit || I18n.t('unit', SCOPE_OPTIONS);

  return (
    <React.Fragment>
      <div className="row mt-5">
        <div className="offset-lg-4 col-lg-5 d-flex">
          <h1 className="flex-grow-1">{I18n.t('title', SCOPE_OPTIONS)}</h1>
          <a
            href={form.recipes_path}
            className="align-self-center btn"
            data-tid="cancel-from-recipe-product-selection"
          >
            <i className="fa fa-close fa-2x" />
          </a>
        </div>
      </div>

      <div className="row mt-5">
        <div className="offset-lg-4 col-lg-4">
          <p className="text-center font-32 font-weight-light">
            {I18n.t('which_product', SCOPE_OPTIONS)}
          </p>
          <ProductAutocompleteWithCreateModal
            units={units}
            product={product}
            onProductSelect={(product) => {
              setProduct(product);
            }}
            searchPath={form.product_search_path}
            wrapperPropsStyles={{ display: 'block' }}
            parasutProductNewUrl={form.parasut_product_new_url}
            inputDataTid="input-product-autocomplete"
            inputClassNames="form-control-lg"
            form={form}
            createProductPath={form.create_product_path}
            showBuyingPriceInput={false}
            links={links}
          >
            {validProduct(product) && (
              <div className="row mt-4">
                <div
                  className="col input-group"
                  data-tid="input-product-output-quantity-group"
                >
                  <input
                    value={outputQuantity || ''}
                    onChange={e => setOutputQuantity(e.target.value)}
                    className="form-control form-control-lg"
                    type="number"
                    required
                    min="0"
                    step="0.0001"
                    data-tid="input-product-quantity"
                    placeholder={I18n.t('quantity', SCOPE_OPTIONS)}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">{unit}</span>
                  </div>
                </div>
              </div>
            )}
          </ProductAutocompleteWithCreateModal>
        </div>
      </div>

      {validProduct(product) && (
        <div className="row mt-4">
          <div className="offset-lg-4 col-lg-4">
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-info btn-lg"
                onClick={onClick}
                disabled={isEmpty(outputQuantity)}
                data-tid="continue-to-ingredients-list"
              >
                {I18n.t('actions.continue', SCOPE_OPTIONS)}
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

RecipeForm.propTypes = {
  form: PropTypes.object.isRequired,
  product: PropTypes.object,
  outputQuantity: PropTypes.node,
  setOutputQuantity: PropTypes.func.isRequired,
  setProduct: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  links: PropTypes.object.isRequired,
};

export default RecipeForm;
