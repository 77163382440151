import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import BlurredGraphImageSrc from 'stats/consumed-product-blurred-graph.png';
import ConsumedProductsDropdown from '../ConsumedProductsDropdown';
import LineChart from '../../../Dashboard/LineChart';
import { assignColor } from '../../../Dashboard/ChartColors';
import { DISPLAY_FORMATS } from '../../Shared/Constants';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment';
import qs from 'qs';

const ConsumedProductChart = (props) => {
  const { fetchUrl, currentQueryParameters, granularity, product, onConsumedDropdownApplyClick, materials, consumedProducts } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.report.manufacturing_report.report_content.consumed_product_chart', locale: localeContext.locale };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const chartOptions = {
    aspectRatio: (isMobileOnly ? 1 : 4),
    tooltips: {
      mode: 'x',
      intersect: false,
      callbacks: {
        title: tooltipItem => moment(tooltipItem[0].label).format(DISPLAY_FORMATS[granularity]),
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label || '';
          const itemData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const unit = itemData.unit || '';
          if (tooltipItem.yLabel) {
            return `${label}: ${I18n.toNumber(tooltipItem.yLabel, { strip_insignificant_zeros: true })} ${unit}`;
          }
        },
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: granularity,
          displayFormats: {
            day: 'D MMM',
          },
          isoWeekday: true,
        },
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{
        gridLines: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          padding: 10,
          maxTicksLimit: 5,
          callback: function (value, index, values) {
            return I18n.toNumber(value, { strip_insignificant_zeros: true });
          }
        }
      }]
    },
    legend: {
      display: true
    }
  };

  const beautifyChartData = (response) => {
    const datasets = [];
    response.datasets.forEach((dataset, i) => {
      const group = {
        fill: false,
        backgroundColor: assignColor(i),
        borderColor: assignColor(i),
        ...dataset,
      };
      datasets.push(group);
    });
    setChartData({ ...response, datasets });
  };

  useEffect(() => {
    const fetchReportData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const queryParameterObject = {
          product_id: product.id,
          start_date: currentQueryParameters['start_date'],
          end_date: currentQueryParameters['end_date'],
          granularity: granularity,
          materials: materials
        };
        const queryParameters = qs.stringify(queryParameterObject, { arrayFormat: 'comma' });

        const rawResponse = await fetch(`${fetchUrl}?${queryParameters}`);
        const response = await rawResponse.json();

        beautifyChartData(response);
      } catch (e) {
        setIsError(true);
      }
      setIsLoading(false);
    };

    if (materials.length > 0) {
      fetchReportData();
    }
  }, [fetchUrl]);

  let renderedComponent;
  if (materials.length === 0) {
    renderedComponent = (
      <div style={{ position: 'relative' }}>
        <div className="d-flex justify-content-center">
          <img src={BlurredGraphImageSrc} alt="placeholder 960" className="img-fluid" />
        </div>
        <div className="most-products-chart-new-action-btn-wrapper">
          <p className="lead font-weight-normal">{I18n.t('empty', SCOPE_OPTIONS)}</p>
        </div>
      </div>
    );
  } else {
    renderedComponent = (
      <LineChart {...chartData} options={chartOptions} />
    );
  }

  return (
    <React.Fragment>
      {
        isLoading ? (
          <div className="row mt-3">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">{I18n.t('loading', SCOPE_OPTIONS)}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
            isError ? (
              <div style={{ minHeight: '350px' }}>
                <small className="text-danger text-monospace">
                  {I18n.t('errors.unknown', SCOPE_OPTIONS)}
                </small>
              </div>
            ) : (
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="card border">
                      <div className="card-header bg-transparent d-flex border-bottom-0 flex-column flex-lg-row">
                        <h4 className="align-self-start">{I18n.t('choose_consumed_products', SCOPE_OPTIONS)}</h4>
                        <ConsumedProductsDropdown
                          topClassNames='ml-auto align-self-center'
                          data={consumedProducts}
                          selecteds={materials}
                          onApplyClick={onConsumedDropdownApplyClick}
                          showMarkAllCheckbox={true}
                        />
                      </div>
                      <div className="card-block p-4">
                        {renderedComponent}
                      </div>
                    </div>
                  </div>
                </div>
              )
          )
      }
    </React.Fragment>
  )
};

ConsumedProductChart.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
  materials: PropTypes.array.isRequired,
  currentQueryParameters: PropTypes.object.isRequired,
  granularity: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  onConsumedDropdownApplyClick: PropTypes.func.isRequired,
  consumedProducts: PropTypes.array.isRequired,
};

export default ConsumedProductChart;
