import React from "react"
import PropTypes from "prop-types"

class FlashAlert extends React.Component {
  alertClass (type) {
    let classes = {
      error:     'alert-danger',
      danger:    'alert-danger',
      alert:     'alert-danger',
      warning:   'alert-warning',
      notice:    'alert-info',
      info:      'alert-info',
      success:   'alert-success',
      primary:   'alert-primary',
      secondary: 'alert-secondary',
      light:     'alert-light',
      dark:      'alert-dark'
    };
    return classes[type] || classes.success;
  }
  

  render () {
    const { message, onClose } = this.props;

    return (
      <div className={`d-flex alert ${this.alertClass(message.type)}`} role="alert">
        <span className='mr-auto'>
          {message.text}
        </span>
        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={onClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}

FlashAlert.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func
}

export default FlashAlert
