import React from "react"
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from "prop-types"
import Autocomplete from 'react-autocomplete'
import { debounce, isEmpty } from 'lodash'

const SCOPE_OPTIONS = { scope: 'components.product.product_autocomplete' };

class ProductAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    const product = this.props.product || {};
    this.state = {
      value: (product.name || ''),
      items: [product],
      loading: false
    };
  }

  // componentDidMount() {
  // Focus the input and select "world"
  // this.input.focus()
  // this.input.setSelectionRange(6, 11)
  // this.input.
  // }

  componentWillReceiveProps(nextProps) {
    if (this.props.product !== nextProps.product) {
      const product = nextProps.product || {};
      // console.log('nextProps', nextProps)
      this.setState({ ...this.state, value: product.name || '' })
    }
  }

  handleFocusOut(e) {
    if (!this.state.value) {
      // console.log('no value', this.state.value)
      this.handleSelect(null, {})
    }
  }

  handleChange(event, value) {
    this.setState({ value: value, loading: true, items: [] })
    this.onChangeDebounced(value)
  }

  onChangeDebounced = debounce(this.fetchSearchResults, 500)

  fetchSearchResults(value) {
    value = value.split('%').join('');

    // this will prevent replacing the already existing query parameters on searchPath.
    // it will add if it's not exists, it will replace if it has param named query.
    // ex: lvh.me:3000/products/search?only_persistent=true&query=value
    // ex: lvh.me:3000/products/search?query=value
    const searchPathHasQueryParams = this.props.searchPath.indexOf('?') > -1;
    const queryParameterPrefix = searchPathHasQueryParams ? '&' : '?';

    fetch(`${this.props.searchPath}${queryParameterPrefix}query=${value}`, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json()
    }).then((json) => {
      // console.log('parsed json', json)
      let parsedResponse = json;
      if (this.props.renderAddProductModal) {
        // this item is used as a placeholder in autocomplete list item to trigger new product creation flow
        parsedResponse.push({ addNewPlaceholder: true, name: I18n.t('result.placeholder', { ...SCOPE_OPTIONS, locale: this.context.locale }), parasut_id: 'NONE' })
      }
      this.setState({ ...this.state, items: parsedResponse, loading: false });
    }).catch((ex) => {
      // console.log('parsing failed', ex)
    })
  }

  handleSelect(value, item) {
    if (item.addNewPlaceholder) {
      this.input.blur();
      this.props.onClickAddNewProduct(this.state.value);
      this.setState({ value: '' })
    } else {
      this.setState({ value: item.name });
      this.props.onProductSelect(item);
    }
  }

  render() {
    return (
      <LocaleContext.Consumer>
        {({ locale }) => {
          return (<div className={this.props.topClassNames}>
            <Autocomplete
              ref={el => this.input = el}
              value={this.state.value}
              onChange={this.handleChange.bind(this)}
              onSelect={(value, item) => this.handleSelect(value, item)}
              items={this.state.items}
              getItemValue={(item) => item.name}
              inputProps={{ className: `form-control ${this.props.inputClassNames}`, placeholder: (this.props.inputPlaceholder || I18n.t('search.placeholder', { ...SCOPE_OPTIONS, locale: locale })), 'data-tid': this.props.inputDataTid, autoFocus: this.props.autoFocus }}
              wrapperProps={{ className: this.props.wrapperClassNames, style: { display: 'inline', ...this.props.wrapperPropsStyles } }}
              // open={true}
              renderItem={(item, isHighlighted) => (
                <div
                  className={`py-1 px-2 ${isHighlighted ? 'item-highlighted' : ''}`}
                  style={{ background: isHighlighted ? '#4095bf' : 'white' }}
                  key={`${item.id}-${item.parasut_id}-${item.name}`} data-tid="autocomplete-list-item">
                  <span>{item.name} {!isEmpty(item.code) && (`(${item.code})`)}</span>
                </div>
              )}
              renderMenu={(items, value, style) => (
                <div className="react-autocomplete-menu fixed-menu-autocomplete" style={{ minWidth: style.minWidth }}>
                  {value === '' ? (
                    <div className="py-1 px-2">
                      {I18n.t('hint', { ...SCOPE_OPTIONS, locale: locale })}
                    </div>
                  ) : this.state.loading ? (
                    <div className="py-1 px-2">
                      <i className="fa fa-cog fa-spin"></i>
                      <span className="ml-1">{I18n.t('loading', { ...SCOPE_OPTIONS, locale: locale })}</span>
                    </div>
                  ) : items.length === 0 ? (
                    <div className="py-1 px-2">
                      <span>{I18n.t('search.no_result', { ...SCOPE_OPTIONS, locale: locale, value: value })}</span>
                    </div>
                  ) : items}
                </div>
              )}
            />
            {this.props.children}
          </div>)
        }}
      </LocaleContext.Consumer>
    )
  }
}

ProductAutocomplete.propTypes = {
  product: PropTypes.object,
  searchPath: PropTypes.string.isRequired,
  onProductSelect: PropTypes.func.isRequired,
  wrapperPropsStyles: PropTypes.object,
  wrapperClassNames: PropTypes.string,
  topClassNames: PropTypes.string,
  parasutProductNewUrl: PropTypes.string.isRequired,
  inputDataTid: PropTypes.string,
  inputClassNames: PropTypes.string,
  renderAddProductModal: PropTypes.bool,
  onClickAddNewProduct: PropTypes.func,
  inputPlaceholder: PropTypes.string,
}

ProductAutocomplete.defaultProps = {
  autoFocus: false,
};

ProductAutocomplete.contextType = LocaleContext;

export default ProductAutocomplete
