import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import ProductAutocomplete from '../../Product/ProductAutocomplete';
import PresetDateRangePicker from '../Shared/PresetDateRangePicker';
import { validStartDate, validEndDate, validProduct } from '../../Helper/Validators/ManufacturingReportValidator';
import qs from 'qs';

const Header = (props) => {
  const {
    searchPath, currentQueryParameters, loadedProduct, refreshPageWith
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.report.manufacturing_report.header', locale: localeContext.locale };
  moment.locale(localeContext.locale)

  const [product, setProduct] = useState(loadedProduct);
  const [startDate, setStartDate] = useState(currentQueryParameters['start_date'] ? moment(currentQueryParameters['start_date']) : null);
  const [endDate, setEndDate] = useState(currentQueryParameters['end_date'] ? moment(currentQueryParameters['end_date']) : null);

  const invalidParameters = (!validStartDate(startDate) || !validEndDate(endDate) || !validProduct(product));

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const generateReportQuery = () => {
    // remove 'materials' key from the currentQueryParameters object
    // because when user changes his/her product, materials shouldn't be
    // transferred to that object. every material id is unique to that product.
    // as a solution, we delete this key if it exists and user needs to
    // select materials from the dropdown every time the page refreshes.
    const { materials, ...queryParameters } = currentQueryParameters;

    if (invalidParameters) {
      return refreshPageWith(qs.stringify({ ...queryParameters }));
    }

    const reportParameterObject = { product_id: product.id, start_date: startDate.format('YYYY-MM-DD'), end_date: endDate.format('YYYY-MM-DD') };
    return refreshPageWith(qs.stringify({ ...queryParameters, ...reportParameterObject }));
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <div className="d-flex flex-lg-row flex-column">
            <ProductAutocomplete
              searchPath={searchPath}
              product={product}
              onProductSelect={product => setProduct(product)}
              topClassNames="flex-grow-1"
              parasutProductNewUrl=""
              inputDataTid="manufacturing-report-product-autocomplete"
              inputClassNames="form-control-lg mb-1 mb-lg-0"
            />
            <div className="ml-lg-2 mb-1 mb-lg-0">
              <PresetDateRangePicker
                startDate={startDate}
                endDate={endDate}
                onDatesChange={onDatesChange}
              />
            </div>
            <button
              type="button"
              className={`btn btn-outline-secondary ml-lg-2 mb-1 mb-lg-0 ${invalidParameters ? 'disabled' : ''}`}
              disabled={invalidParameters}
              onClick={generateReportQuery}
              data-tid="manufacturing-report-generate-btn">{I18n.t('actions.generate', SCOPE_OPTIONS)}</button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

Header.propTypes = {
  searchPath: PropTypes.string.isRequired,
  loadedProduct: PropTypes.object,
  currentQueryParameters: PropTypes.object.isRequired,
  refreshPageWith: PropTypes.func.isRequired,
};

export default Header;
