import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import "react-dates/initialize";
import { DateRangePicker as ReactDateRangePicker } from 'react-dates';
import { isMobileOnly } from 'react-device-detect';
import { omit } from 'lodash';

const DateRangePicker = (props) => {
  const { startDate, startDateId, endDate, endDateId, onDatesChange,
    required, displayFormat, startDatePlaceholderText, endDatePlaceholderText, numberOfMonths } = props;

  const [focusedInput, setFocusedInput] = useState(null);

  const onFocusChange = (input) => {
    setFocusedInput(input);
  }

  const orientation = isMobileOnly ? 'vertical' : 'horizontal';
  const withFullScreenPortal = isMobileOnly ? true : false;

  // presets is a helper props for the
  // wrapper but are not props on the DateRangePicker itself and
  // thus, have to be omitted.
  const omittedProps = omit(props, ['presets']);

  return (
    <ReactDateRangePicker
      {...omittedProps}
      startDate={startDate}
      startDateId={startDateId}
      endDate={endDate}
      endDateId={endDateId}
      onDatesChange={onDatesChange}
      focusedInput={focusedInput}
      onFocusChange={onFocusChange}
      showClearDates={true}
      reopenPickerOnClearDates={true}
      hideKeyboardShortcutsPanel={true}
      isOutsideRange={() => false}
      required={required}
      displayFormat={displayFormat}
      startDatePlaceholderText={startDatePlaceholderText}
      endDatePlaceholderText={endDatePlaceholderText}
      numberOfMonths={numberOfMonths}
      withFullScreenPortal={withFullScreenPortal}
      orientation={orientation}
    />
  )
}

DateRangePicker.propTypes = {
  startDate: momentPropTypes.momentObj,
  endDate: momentPropTypes.momentObj,
  presets: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    start: momentPropTypes.momentObj,
    end: momentPropTypes.momentObj,
  })),
  required: PropTypes.bool,
  displayFormat: PropTypes.func,
  startDatePlaceholderText: PropTypes.string,
  endDatePlaceholderText: PropTypes.string,
};

DateRangePicker.defaultProps = {
  startDate: null,
  endDate: null,
  presets: [],
  startDateId: 'date-range-picker-start-date-input',
  endDateId: 'date-range-picker-end-date-input',
  required: false,
  displayFormat: () => moment.localeData().longDateFormat('L'),
  startDatePlaceholderText: "Start Date",
  endDatePlaceholderText: "End Date",
  numberOfMonths: 2
};

export default DateRangePicker;
