import React, { useLayoutEffect, useRef } from "react";
import { Droppable } from "react-beautiful-dnd";
import { FixedSizeList as List } from "react-window";
import Row, { LoadMoreComponent, EmptyHelperComponent } from './Row';
import Item from './Item';

const ItemList = React.memo(function ItemList({ column, columnIndex, fetchCards, links }) {
  // There is an issue I have noticed with react-window that when reordered
  // react-window sets the scroll back to 0 but does not update the UI
  // I should raise an issue for this.
  // As a work around I am resetting the scroll to 0
  // on any list that changes it's index
  const listRef = useRef();
  useLayoutEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [columnIndex]);
  const shouldRenderHelp = columnIndex === 0 && column.items.length === 0;

  const RowWithLoading = (props) => {
    const { index } = props
    const shouldLoadMore = index != 0 &&
                           index === column.items.length &&
                           column.items.length < column.total_cards_count &&
                           column.total_cards_count > column.per_page_count;

    return (
      <React.Fragment>
        <Row {...props} />
        {(shouldLoadMore && 
          <LoadMoreComponent
            style={{...props.style, height: 'unset'}}
            index={index}
            column={column}
            fetchCards={fetchCards}
            />
        )}
        {(shouldRenderHelp &&
          <EmptyHelperComponent
            style={{...props.style, height: 'unset'}}
            index={index}
            column={column}
            links={links}
          />
        )}
      </React.Fragment>
    )
  }

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.items[rubric.source.index]}
        />
      )}
    >
      {(provided, snapshot) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        // also, add additional item to render loading button
        const itemCount = snapshot.isUsingPlaceholder || column.total_cards_count > column.items.length || shouldRenderHelp
          ? column.items.length + 1
          : column.items.length;

        return (
          <List
            height={window.innerHeight-170}
            itemCount={itemCount}
            itemSize={120}
            width={300}
            outerRef={provided.innerRef}
            itemData={column.items}
            className="task-list"
            ref={listRef}
          >
            {RowWithLoading}
          </List>
        );
      }}
    </Droppable>
  );
});

export default ItemList;