import React from 'react';
import PropTypes from 'prop-types';

const TextInput = (props) => {
  const {
    value, onChange
  } = props;

  return (
    <input type="text" className="form-control form-control-sm ml-2" value={value} onChange={(e) => onChange(e.target.value)} data-tid="filterable-value-text-input" />
  )
};

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TextInput;
