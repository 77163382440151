import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useCollectionManagerReducer from '../Helper/Hooks/UseCollectionManagerReducer';
import PresetAutocomplete from '../Helper/PresetAutocomplete';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import StockAdjustmentItemForm from './Forms/StockAdjustmentItemForm';
import FlashAlert from '../FlashMessage/FlashAlert';
import { formProp } from '../Helper/CommonProps';
import { statusHandler } from '../Helper/RequestHelpers';
import { validReason, validLineItems } from '../Helper/Validators/StockAdjustmentValidator';

const Form = (props) => {
  const { form, translation, links } = props;

  const SCOPE_OPTIONS = { scope: 'components.stock_adjustment.form', locale: translation.locale };

  const [reason, setReason] = useState(null);
  const [note, setNote] = useState('');

  const [flashMessage, setFlashMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const emptyStockAdjustmentItem = { product: { stock_count: 0 }, qty_change: 1, qty_after: undefined };
  const initialStockAdjustmentItemsState = [emptyStockAdjustmentItem];
  const [stockAdjustmentItems, dispatchStockAdjustmentItems] = useCollectionManagerReducer(initialStockAdjustmentItemsState, emptyStockAdjustmentItem);
  const addNewAdjustmentItem = () => dispatchStockAdjustmentItems({ type: 'add' });
  const updateAdjustmentItem = (index, adjustment) => dispatchStockAdjustmentItems({ type: 'update', index, object: adjustment });
  const deleteAdjustmentItem = (index) => dispatchStockAdjustmentItems({ type: 'delete', index });

  const reasonInvalid = !validReason(reason);
  const stockAdjustmentInvalid = reasonInvalid || !validLineItems(stockAdjustmentItems);

  const handleSubmit = () => {
    if (stockAdjustmentInvalid) {
      setShowError(true);
    } else {
      setShowError(false);
      setLoading(true);
      saveAdjustment();
    }
  };

  const saveAdjustment = () => {
    const { action, method, csrf_token } = form;
    fetch(action, {
      credentials: 'same-origin',
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token
      },
      body: JSON.stringify({
        stock_adjustment: {
          reason: reason,
          note: note,
          line_items: stockAdjustmentItems.map(({ product, qty_change, qty_after }) => {
            return { product, quantity_change: qty_change, quantity_after: qty_after };
          })
        }
      })
    }).then(statusHandler).then((response) => {
      return response.json()
    }).then((json) => {
      setLoading(false);
      setShowError(false);
      window.Turbolinks.visit(json.stock_adjustment_path);
    }).catch((ex) => {
      setLoading(false);
      setShowError(true);
      if (ex.json && ex.json.error_detail) {
        const message = { id: Math.random(), type: 'danger', text: ex.json.error_detail };
        setFlashMessage(message);
      }
    });
  };

  return (
    <LocaleContext.Provider value={translation}>
      {flashMessage && (
        <div className='px-4'>
          <FlashAlert key={flashMessage.id} message={flashMessage} onClose={() => setFlashMessage(null)} />
        </div>
      )}

      <div className="row mt-2">
        <div className="col-xl-9 col-12">
          <div className="form-group row">
            <label className="col-lg-3 col-12 col-form-label font-weight-light">{I18n.t('reason.label', SCOPE_OPTIONS)}</label>
            <div className="col-lg-9 col-12">
              <PresetAutocomplete
                options={I18n.t('reasons', SCOPE_OPTIONS)}
                placeholder={I18n.t('reason.placeholder', SCOPE_OPTIONS)}
                value={reason || ''}
                onChange={value => setReason(value)}
                onSelect={value => setReason(value)}
                inputDataTid='stock-adjustment-reason-input'
              />
              {showError && reasonInvalid && (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  {I18n.t('reason.feedback', SCOPE_OPTIONS)}
                </div>
              )}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-lg-3 col-12 col-form-label font-weight-light">{I18n.t('note.label', SCOPE_OPTIONS)}</label>
            <div className="col-lg-9 col-12">
              <textarea value={note || ''} onChange={e => setNote(e.target.value)} className="form-control" data-tid="input-customer-order-note" placeholder={I18n.t('note.placeholder', SCOPE_OPTIONS)} />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="offset-lg-2 col-lg-8"><hr /></div>
        <div className="col-lg-12 d-flex flex-column">
          <span className="font-20 font-weight-light text-black-50 text-center">{I18n.t('title', SCOPE_OPTIONS)}</span>
          <small className="text-center text-black-50">{I18n.t('info', SCOPE_OPTIONS)}</small>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="card border">
            <div className="card-header pb-0 px-0">
              <div className="table-responsive">
                <table className="table table-hover mb-0">
                  <colgroup>
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '5%' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="border-0 font-weight-600">{I18n.t('table.product_name', SCOPE_OPTIONS)}</th>
                      <th className="border-0 font-weight-600">{I18n.t('table.product_sku', SCOPE_OPTIONS)}</th>
                      <th className="border-0 font-weight-600">{I18n.t('table.qty_change', SCOPE_OPTIONS)}</th>
                      <th className="border-0 font-weight-600">{I18n.t('table.on_hand', SCOPE_OPTIONS)}</th>
                      <th className="border-0 font-weight-600">{I18n.t('table.qty_after', SCOPE_OPTIONS)}</th>
                      <th className="border-0 font-weight-600"></th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {
                      stockAdjustmentItems.map((adjustment, i) => (
                        <StockAdjustmentItemForm
                          key={`stock-adjustment-item-${i}`}
                          index={i}
                          searchPath={links.product_search_path}
                          adjustment={adjustment}
                          showError={showError}
                          addNewAdjustmentItem={addNewAdjustmentItem}
                          updateAdjustmentItem={updateAdjustmentItem}
                          deleteAdjustmentItem={deleteAdjustmentItem}
                        />
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className="btn btn-outline-secondary btn-sm" onClick={addNewAdjustmentItem} data-tid="add-new-stock-adjustment-item-btn">
            <i className="fa fa-plus mr-2" />
            {I18n.t('actions.add', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className={`btn btn-info btn-lg  ${loading ? 'disabled' : ''}`} data-tid="stock-adjustment-create-btn" disabled={loading} onClick={handleSubmit}>
            <i className={`fas fa-spinner fa-spin mr-1 ${loading ? '' : 'd-none'}`} />
            {I18n.t('actions.create', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>
    </LocaleContext.Provider>
  );
};

Form.propTypes = {
  form: formProp.isRequired,
  translation: LocaleProp.isRequired,
  links: PropTypes.shape({
    product_search_path: PropTypes.string.isRequired,
  }).isRequired,
};

export default Form;
