import React, { useEffect, useState } from 'react';
import LocaleContext, { LocaleProp, I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import { statusHandler } from '../../Helper/RequestHelpers';
import useInterval from '../../Helper/Hooks/UseInterval';

const StatusComponent = (props) => {
  const { log, index } = props;
  const parsed_date = I18n.parseDate(log.humanize_time);
  return (
    <React.Fragment>
      <div className={`py-3 d-flex flex-column flex-lg-row justify-content-lg-between ${index !== 0 ? 'border-top' : ''}`}>
        <span className="mb-0">{log.message}</span>
        <span className="text-muted small ml-2 flex-shrink-0" title={I18n.strftime(parsed_date, "%d/%m/%Y %H:%M:%S")}>{I18n.strftime(parsed_date, "%H:%M:%S")}</span>
      </div>
    </React.Fragment>
  );
}

const ImportStatus = (props) => {
  const { translation, links } = props;
  const { import_shopify_integration_path } = links;

  const SCOPE_OPTIONS = { scope: 'components.integration.shopify.import_status', locale: translation.locale };

  // default delay is set to 5 sec to make first request fire.
  const [delay, setDelay] = useState(5000);
  const [statusData, setStatusData] = useState({ done: false, logs: [] });
  const [isError, setIsError] = useState(false);

  const fetchStatus = () => {
    fetch(import_shopify_integration_path, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(statusHandler).then((response) => {
      return response.json()
    }).then((json) => {
      const import_status = json.import_status;
      setStatusData(import_status);
      if (import_status.done) {
        // stop the loop, break the interval!
        setDelay(null);
      }
    }).catch((ex) => {
      // alert(ex);
      setIsError(true);
      // stop the loop, break the interval!
      setDelay(null);
    });
  }

  useInterval(() => {
    fetchStatus();
  }, delay);

  return (
    <LocaleContext.Provider value={translation}>
      <div className="d-flex align-items-baseline">
        <h6 className="border-gray pb-2 mb-2">{I18n.t('header', SCOPE_OPTIONS)}</h6>
        { !statusData.done && (
           <div className="spinner-grow spinner-grow-sm text-success ml-1" role="status">
             <span sr-only="loading"></span>
           </div>
         )}
      </div>
      <div className=" py-3 d-flex justify-content-between">
        { statusData.done ? (
           <p>{I18n.t('imported_description', SCOPE_OPTIONS)}</p>
         ) : (
           <p>{I18n.t('importing_description', SCOPE_OPTIONS)}</p>
         ) }
      </div>
      {
        isError && (
          <div className='py-3 d-flex flex-column flex-lg-row justify-content-lg-between'>
            <span className="mb-0 text-danger">{I18n.t('fetch_error', SCOPE_OPTIONS)}</span>
          </div>
        )
      }
      { statusData.logs.map((log, i) => {
        return <StatusComponent key={`log-${i}`} log={log} index={i} />
      }) }
      { statusData.done && (
        <div className="py-3 border-top d-flex flex-column flex-lg-row justify-content-lg-between">
          <div className="flex-shrink-0 ml-auto">
            <a href={links.products_path} className="btn btn-info btn-sm" data-tid="shopify-show-products-btn">
              {I18n.t('show_products', SCOPE_OPTIONS)}
            </a>
          </div>
        </div>
      )}
    </LocaleContext.Provider>
  );
}

ImportStatus.propTypes = {
  translation: LocaleProp.isRequired,
  links: PropTypes.object.isRequired,
}

export default ImportStatus;
