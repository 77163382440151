import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';

const LineChart = (props) => {
  const { labels, datasets, options } = props;
  const chartRef = useRef(null);
  const defaultOptions = {
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    elements: {
      line: {
        tension: 0.2, // disables bezier curves
      },
    },
  };

  useEffect(() => {
    /* eslint-disable no-new */
    const chartContext = chartRef.current.getContext('2d');
    const chart = new Chart(chartContext, {
      type: 'line',
      data: { labels, datasets },
      options: { ...defaultOptions, ...options },
    });
    return () => {
      chart.destroy();
    }
  }, [labels, datasets, defaultOptions, options]);

  return (
    <canvas ref={chartRef} />
  );
};

LineChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  datasets: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.any,
};

export default LineChart;
