import React, { useState, useRef, useEffect } from 'react';
import LocaleContext, { LocaleProp } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import CompanySelectionForm from './Forms/CompanySelectionForm';
import CredentialsForm from './Forms/CredentialsForm';
import SubmitForm from './Forms/SubmitForm';
import { validExternalId } from '../../Helper/Validators/Integration/AkauntingValidator';
import { formProp } from '../../Helper/CommonProps';

export const STATES = {
  credentialsForm: 'credentialsForm',
  companySelectionForm: 'companySelectionForm',
};

const Form = (props) => {
  const { links, translation } = props;
  const { form } = links.create_akaunting_integration_path;

  const [currentView, setCurrentView] = useState(STATES.credentialsForm);
  const [usingCloudVersion, setUsingCloudVersion] = useState(true);
  const [baseUrl, setBaseUrl] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [externalId, setExternalId] = useState(0);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (companies.length > 0) {
      setExternalId(companies[0].id);
    }
  }, [companies]);

  const submitFormRef = useRef(null);

  const saveIntegration = () => {
    if (validExternalId(externalId, companies)) {
      submitFormRef.current.submit();
      return true;
    }
    return false;
  };

  let renderedComponent;
  switch (currentView) {
    case STATES.credentialsForm:
      renderedComponent = (
        <CredentialsForm
          onClickContinue={() => setCurrentView(STATES.companySelectionForm)}
          usingCloudVersion={usingCloudVersion}
          setUsingCloudVersion={setUsingCloudVersion}
          baseUrl={baseUrl}
          setBaseUrl={setBaseUrl}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          setCompanies={setCompanies}
          links={links}
        />
      );
      break;
    case STATES.companySelectionForm:
      renderedComponent = (
        <CompanySelectionForm
          onClickBack={() => setCurrentView(STATES.credentialsForm)}
          companies={companies}
          externalId={externalId}
          setExternalId={setExternalId}
          saveIntegration={saveIntegration}
        />
      );
      break;
  }

  return (
    <LocaleContext.Provider value={translation}>
      {renderedComponent}

      <div style={{ marginTop: '250px' }}>
        <SubmitForm form={form} ref={submitFormRef} usingCloudVersion={usingCloudVersion} baseUrl={baseUrl} username={username} password={password} externalId={externalId} />
      </div>
    </LocaleContext.Provider>
  );
};

Form.propTypes = {
  links: PropTypes.shape({
    authorize_akaunting_integration_credentials_path: PropTypes.shape({
      form: formProp.isRequired,
    }),
    create_akaunting_integration_path: PropTypes.shape({
      form: formProp.isRequired,
    }),
  }).isRequired,
  translation: LocaleProp.isRequired,
};

export default Form;
