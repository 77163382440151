import React from 'react';
import PropTypes from 'prop-types';
import NumberBadge from './NumberBadge';
import StatusBadge from './StatusBadge';
import TaskSummary from './TaskSummary';

const TaskListItem = (props) => {
  const { isActive, index, task, onClick } = props;
  const { purchase_order } = task;

  return (
    <React.Fragment>
      <div className={`d-flex list-group-item list-group-item-action  border-right cpointer ${isActive ? 'border-left-3 border-left-primary' : 'border-left'}`} onClick={() => onClick(index)}>

        <NumberBadge task={task} index={index + 1} />
        <TaskSummary task={task} />

        <div className="d-flex flex-column ml-auto">
          <StatusBadge order={purchase_order} />

          {isActive && (
            <div className="align-self-end mt-auto">
              <i className="fas fa-chevron-right fa-lg" />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

TaskListItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  task: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default TaskListItem;
