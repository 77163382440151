import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { formProp } from '../../Helper/CommonProps';
import { validProduct, validQuantity } from '../../Helper/Validators/CustomerOrderValidator';
import ProductAutocompleteWithCreateModal from '../../Product/ProductAutocompleteWithCreateModal';
import LocaleContext, { I18n } from 'Context/LocaleContext';

const LineItemForm = (props) => {
  const {
    index,
    calculatedIdentifier,
    lineItem,
    form,
    links,
    updateLineItem,
    removeLineItem,
    renderFormErrors,
    units
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.purchase_order.forms.line_item_form', locale: localeContext.locale };

  const { product, quantity } = lineItem;
  const unit = product.unit || I18n.t('unit', SCOPE_OPTIONS);

  const onProductSelect = (product) => {
    updateLineItem(index, { ...lineItem, product });
  };
  const handleQuantity = e => updateLineItem(index, { ...lineItem, quantity: e.target.value });
  const markAsDeleted = () => removeLineItem(index, lineItem);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const productInvalid = !validProduct(product);
  const quantityInvalid = !validQuantity(quantity);

  return (
    <React.Fragment>
      <div className={`line-item mt-2 ${lineItem.deleted ? 'd-none' : ''} mx-4 mx-lg-0`}>
        <div className="d-flex flex-wrap row">
          <span className="font-18 font-weight-light text-black-50 col-form-label">{calculatedIdentifier}</span>
          <ProductAutocompleteWithCreateModal links={links} units={units} product={product} onProductSelect={onProductSelect} searchPath={links.product_search_path} topClassNames="flex-grow-1 ml-3" wrapperClassNames="" parasutProductNewUrl="" inputDataTid="input-purchase-order-product-autocomplete" inputClassNames={`${renderFormErrors && productInvalid ? 'is-invalid' : ''}`} form={form} createProductPath={links.create_product_path} showBuyingPriceInput={false}>
            {renderFormErrors && productInvalid && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {I18n.t('auto_complete.feedback', SCOPE_OPTIONS)}
              </div>
            )}
          </ProductAutocompleteWithCreateModal>
          <div className="ml-3" style={{ maxWidth: '250px' }}>
            <div className="input-group">
              <input value={quantity || ''} onChange={handleQuantity} className={`form-control ${renderFormErrors && quantityInvalid && 'is-invalid'}`} type="number" required min="0" step="0.0001" data-tid="input-purchase-order-line-item-quantity" placeholder={I18n.t('quantity.placeholder', SCOPE_OPTIONS)} />
              <div className="input-group-append">
                <span className="input-group-text">{unit}</span>
              </div>
              <div className="invalid-feedback">
                {I18n.t('quantity.feedback', SCOPE_OPTIONS)}
              </div>
            </div>
          </div>
          <div className="col-form-label ml-3">
            <button type="button" className="close close-danger" data-dismiss="alert" aria-label="Close" onClick={markAsDeleted} data-tid="remove-purchase-order-line-item-btn">
              <i className="fa fa-trash" style={{ fontSize: '1.1em' }} />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

LineItemForm.propTypes = {
  index: PropTypes.number.isRequired,
  calculatedIdentifier: PropTypes.number,
  lineItem: PropTypes.object.isRequired,
  form: formProp.isRequired,
  links: PropTypes.object.isRequired,
  updateLineItem: PropTypes.func.isRequired,
  removeLineItem: PropTypes.func.isRequired,
  renderFormErrors: PropTypes.bool.isRequired,
  units: PropTypes.array.isRequired,
};

export default LineItemForm;
