import { isEmpty, isNull } from 'lodash';

export const unitConversionQueryParams = (product) => {
  if (!isNull(product.id)) {
    const params = { product_id: product.id };
    return new URLSearchParams(params).toString();
  }

  if (!isEmpty(product.parasut_id)) {
    const params = { product_parasut_id: product.parasut_id };
    return new URLSearchParams(params).toString();
  }
};
