import React, { useState, useRef, useEffect, useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import { map, groupBy, isEmpty } from 'lodash';

const ConsumedProductsDropdown = (props) => {
  const { data, topClassNames, showMarkAllCheckbox, onApplyClick, selecteds } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.report.manufacturing_report.consumed_products_dropdown', locale: localeContext.locale };

  const componentRef = useRef(null);
  const [selectedIds, setSelectedIds] = useState(selecteds);
  const dataGroupedByUnit = groupBy(data, 'unit');

  useEffect(() => {
    const dropdowns = componentRef.current.querySelectorAll('[data-toggle=dropdown]');
    dropdowns.forEach(dropdown => new Dropdown(dropdown));
  });

  const mapProductIdByGroup = (group) => {
    // get product_id's from the data
    // given we have {'Adet': [{ id: 1, name: 'Demir' }, { id: 2, name: 'Bicak' }]}
    // will return [1, 2] based on group name.
    return map(dataGroupedByUnit[group], 'id');
  };

  const pluckProductId = () => {
    // get product_id's from the data
    // given we have {'Adet': [{ id: 1, name: 'Demir' }, { id: 2, name: 'Bicak' }]}
    // will return [1,2]
    return map(data, 'id');
  };

  const handleGroupCheckboxChange = (e) => {
    const productIds = mapProductIdByGroup(e.target.value);

    if (e.target.checked) {
      setSelectedIds([...selectedIds, ...productIds]);
    } else {
      const ids = selectedIds.filter(id => !productIds.includes(id));
      setSelectedIds(ids);
    }
  };

  const handleCheckboxItemChange = (e) => {
    if (e.target.checked) {
      setSelectedIds([...selectedIds, parseInt(e.target.value)]);
    } else {
      const ids = selectedIds.filter(id => id !== parseInt(e.target.value));
      setSelectedIds(ids);
    }
  };

  const isCheckboxItemChecked = (id) => {
    return selectedIds.includes(id);
  };

  const isGroupCheckboxChecked = (group) => {
    const productIds = mapProductIdByGroup(group);
    return productIds.every(id => selectedIds.includes(id));
  };

  const isMarkAllCheckboxChecked = () => {
    const productIds = pluckProductId();
    return productIds.every(id => selectedIds.includes(id));
  };

  const handleMarkAllCheckboxChange = (e) => {
    const productIds = pluckProductId();

    if (e.target.checked) {
      setSelectedIds([...selectedIds, ...productIds]);
    } else {
      const ids = selectedIds.filter(id => !productIds.includes(id));
      setSelectedIds(ids);
    }
  };

  const renderMarkAllCheckbox = () => {
    return (
      <div className="mb-2">
        <input
          className="align-self-start"
          type="checkbox"
          data-tid={`manufacturing-report-checkbox-select-all`}
          checked={isMarkAllCheckboxChecked()}
          onChange={handleMarkAllCheckboxChange}
          id="check-all" />
        <label className="ml-1 mb-0 font-weight-bold" htmlFor="check-all" >{I18n.t('options.select_all', SCOPE_OPTIONS)}</label>
        <div className="dropdown-divider"></div>
      </div>
    )
  };

  return (
    <div className={topClassNames} ref={componentRef}>
      <div className="dropdown">
        <button className="btn btn-outline-secondary dropdown-toggle"
          type="button"
          data-tid="manufacturing-report-consumed-material-dropdown"
          data-toggle="dropdown"
          data-persist="true"
          aria-haspopup="true"
          aria-expanded="false">
          {I18n.t('placeholder', SCOPE_OPTIONS)}
        </button>
        <div className="dropdown-menu mt-2 dropdown-menu-right p-3">
          <div className="overflow-auto" style={{ minWidth: '162px', maxHeight: '260px' }}>
            {showMarkAllCheckbox && renderMarkAllCheckbox()}
            {Object.keys(dataGroupedByUnit).map((group, index) => {
              return (
                <React.Fragment key={`manufacturing-report-checkbox-group-${group}`}>
                  <div className={`${index != 0 ? 'mt-2' : ''}`}>
                    <input
                      className="align-self-start"
                      type="checkbox"
                      data-tid={`manufacturing-report-checkbox-group-${group}`}
                      value={group}
                      checked={isGroupCheckboxChecked(group)}
                      onChange={handleGroupCheckboxChange}
                      id={`group-${group}`} />
                    <label className="ml-1 mb-0 font-weight-bold" htmlFor={`group-${group}`}>{isEmpty(group) ? I18n.t('unit', SCOPE_OPTIONS) : group}</label>
                  </div>
                  <div className="dropdown-divider"></div>
                  {dataGroupedByUnit[group].map(({ id, name }) => {
                    return (
                      <div className="form-check ml-2" key={`manufacturing-report-checkbox-item-${name}`} data-tid={`manufacturing-report-checkbox-item-${name}`}>
                        <input className="form-check-input" type="checkbox" checked={isCheckboxItemChecked(id)} value={id} onChange={handleCheckboxItemChange} id={`product-${id}`} />
                        <label className="form-check-label" htmlFor={`product-${id}`}>{name}</label>
                      </div>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </div>
          <div className="dropdown-divider"></div>
          <div className="d-flex">
            <button className="btn btn-outline-success flex-grow-1" type="button" data-tid="manufacturing-report-consumed-material-dropdown-apply-btn" onClick={() => onApplyClick(selectedIds)}>
              {I18n.t('actions.apply', SCOPE_OPTIONS)}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

ConsumedProductsDropdown.propTypes = {
  data: PropTypes.array.isRequired,
  topClassNames: PropTypes.string,
  showMarkAllCheckbox: PropTypes.bool,
  onApplyClick: PropTypes.func.isRequired,
  selecteds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

ConsumedProductsDropdown.defaultProps = {
  showMarkAllCheckbox: false,
};

export default ConsumedProductsDropdown;
