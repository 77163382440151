import React, { useState, useRef, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { reorderList, updateColumnPosition, updateCardPosition } from "./Reorder";
import Column from './Elements/Column';
import NewColumn from './Elements/NewColumn';

const VirtualBoard = (props) => {
  const { fetchBoard, state, setState, links, fetchCards, renderFlashMessage } = props;

  const boardRef = useRef(null);
  useEffect(() => {
    const dropdowns = boardRef.current.querySelectorAll('[data-toggle=dropdown]');
    dropdowns.forEach(dropdown => new Dropdown(dropdown));
  });

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.type === "column") {
      // if the list is scrolled it looks like there is some strangeness going on
      // with react-window. It looks to be scrolling back to scroll: 0
      // I should log an issue with the project
      const columnOrder = reorderList(
        state.columnOrder,
        result.source.index,
        result.destination.index
      );
      setState({
        ...state,
        columnOrder
      });

      const draggableColumn = state.columns[result.draggableId]
      updateColumnPosition(draggableColumn, { position: result.destination.index }, renderFlashMessage);
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      const column = state.columns[result.source.droppableId];
      const items = reorderList(
        column.items,
        result.source.index,
        result.destination.index
      );

      // updating column entry
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [column.id]: {
            ...column,
            items
          }
        }
      };
      setState(newState);

      const card = column.items[result.source.index];
      updateCardPosition(column, card, result.destination.index, renderFlashMessage);
      return;
    }

    // moving between lists
    const sourceColumn = state.columns[result.source.droppableId];
    const destinationColumn = state.columns[result.destination.droppableId];
    const item = sourceColumn.items[result.source.index];

    // 1. remove item from source column
    const newSourceColumn = {
      ...sourceColumn,
      items: [...sourceColumn.items]
    };
    newSourceColumn.items.splice(result.source.index, 1);

    // 2. insert into destination column
    const newDestinationColumn = {
      ...destinationColumn,
      items: [...destinationColumn.items]
    };
    // in line modification of items
    newDestinationColumn.items.splice(result.destination.index, 0, item);

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newSourceColumn.id]: newSourceColumn,
        [newDestinationColumn.id]: newDestinationColumn
      }
    };

    setState(newState);
    updateCardPosition(newDestinationColumn, item, result.destination.index, renderFlashMessage);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="board-app" ref={boardRef}>
        <Droppable
          droppableId="all-droppables"
          direction="horizontal"
          type="column"
        >
          {provided => (
            <div
              className="board-columns"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {state.columnOrder.map((columnId, index) => (
                <Column
                  key={columnId}
                  column={state.columns[columnId]}
                  index={index}
                  fetchBoard={fetchBoard}
                  fetchCards={fetchCards}
                  links={links}
                />
              ))}
              {provided.placeholder}
              <NewColumn board={state} fetchBoard={fetchBoard} links={links} />
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}

export default VirtualBoard;
