export const statusHandler = async (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    error.json = await response.json();
    throw error;
  }
}
