import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const SubmitForm = React.forwardRef((props, ref) => {
  const {
    form, product, quantity, issueDate, note, ingredients, inflowIngredients,
  } = props;
  const localeContext = useContext(LocaleContext);

  const SCOPE_OPTIONS = { scope: 'components.new_manufacturing_record.forms.submit_form', locale: localeContext.locale };

  const ingredientInputName = index => `manufacturing_record[ingredients_attributes][${index}]`;
  const recipe = props.recipe || {};
  const customerOrderLineItem = props.customerOrderLineItem || {};
  const inflowIngredientInputName = index => `manufacturing_record[inflow_ingredients_attributes][${index}]`;

  const defaultDescription = () => {
    const options = {
      ...SCOPE_OPTIONS,
      quantity: quantity,
      unit: product.unit || '',
      name: product.name,
    }

    const hasNoCustomerOrder = isEmpty(customerOrderLineItem.customer_order);
    if(hasNoCustomerOrder || isEmpty(customerOrderLineItem.customer_order.order_no)) {
      return I18n.t('default_description', options);
    } else {
      return I18n.t('description_with_order_no', {
        ...options,
        orderNo: customerOrderLineItem.customer_order.order_no,
      });
    }
  };

  const description = props.description || defaultDescription();

  return (
    <React.Fragment>
      <form action={form.action} method="post" data-remote data-turbolinks-form data-tid="manufacturing-record-form" data-element="manufacturing-record-form" ref={ref}>
        <input type="hidden" name={form.csrf_param} value={form.csrf_token} />
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value={form.method} />
        <input type="hidden" name="recipe_id" value={recipe.id} />
        <input type="hidden" name="line_item_id" value={customerOrderLineItem.id} />

        <input type="hidden" value={description || ''} name="manufacturing_record[description]" />
        <input type="hidden" value={product.id || ''} name="manufacturing_record[product][id]" />
        <input type="hidden" value={product.name || ''} name="manufacturing_record[product][name]" />
        <input type="hidden" value={product.unit || ''} name="manufacturing_record[product][unit]" />
        <input type="hidden" value={product.parasut_id || ''} name="manufacturing_record[product][parasut_id]" />
        <input type="hidden" value={product.code || ''} name="manufacturing_record[product][code]" />
        <input type="hidden" value={product.barcode || ''} name="manufacturing_record[product][barcode]" />
        <input type="hidden" value={quantity || ''} name="manufacturing_record[quantity]" />
        <input type="hidden" value={issueDate || ''} name="manufacturing_record[issue_date]" />
        <input type="hidden" value={note || ''} name="manufacturing_record[note]" />
        {/* filter is used to not to render if ingredient.id null and deleted all together */}
        {ingredients.filter(ingredient => !(ingredient.id == null && ingredient.deleted)).map((ingredient, i) => (
          <React.Fragment key={`ingredient-values-${i}`}>
            <input type="hidden" value={ingredient.id || ''} name={`${ingredientInputName(i)}[id]`} />
            <input type="hidden" value={ingredient.product.id || ''} name={`${ingredientInputName(i)}[product][id]`} />
            <input type="hidden" value={ingredient.product.name || ''} name={`${ingredientInputName(i)}[product][name]`} />
            <input type="hidden" value={ingredient.product.unit || ''} name={`${ingredientInputName(i)}[product][unit]`} />
            <input type="hidden" value={ingredient.product.parasut_id || ''} name={`${ingredientInputName(i)}[product][parasut_id]`} />
            <input type="hidden" value={ingredient.product.code || ''} name={`${ingredientInputName(i)}[product][code]`} />
            <input type="hidden" value={ingredient.product.barcode || ''} name={`${ingredientInputName(i)}[product][barcode]`} />

            <input type="hidden" value={ingredient.quantity || ''} name={`${ingredientInputName(i)}[quantity]`} />
            <input type="hidden" value={ingredient.used_unit || ''} name={`${ingredientInputName(i)}[used_unit]`} />

            <input type="hidden" value={ingredient.deleted || false} name={`${ingredientInputName(i)}[deleted]`} />
          </React.Fragment>
        ))}
        {/* filter is used to not to render if ingredient.id null and deleted all together */}
        {inflowIngredients.filter(ingredient => !(ingredient.id == null && ingredient.deleted)).map((ingredient, i) => (
          <React.Fragment key={`inflow-ingredient-values-${i}`}>
            <input type="hidden" value={ingredient.id || ''} name={`${inflowIngredientInputName(i)}[id]`} />
            <input type="hidden" value={ingredient.product.id || ''} name={`${inflowIngredientInputName(i)}[product][id]`} />
            <input type="hidden" value={ingredient.product.name || ''} name={`${inflowIngredientInputName(i)}[product][name]`} />
            <input type="hidden" value={ingredient.product.unit || ''} name={`${inflowIngredientInputName(i)}[product][unit]`} />
            <input type="hidden" value={ingredient.product.parasut_id || ''} name={`${inflowIngredientInputName(i)}[product][parasut_id]`} />
            <input type="hidden" value={ingredient.product.code || ''} name={`${inflowIngredientInputName(i)}[product][code]`} />
            <input type="hidden" value={ingredient.product.barcode || ''} name={`${inflowIngredientInputName(i)}[product][barcode]`} />

            <input type="hidden" value={ingredient.quantity || ''} name={`${inflowIngredientInputName(i)}[quantity]`} />
            <input type="hidden" value={ingredient.used_unit || ''} name={`${inflowIngredientInputName(i)}[used_unit]`} />

            <input type="hidden" value={ingredient.deleted || false} name={`${inflowIngredientInputName(i)}[deleted]`} />
          </React.Fragment>
        ))}
      </form>
    </React.Fragment>
  );
});

SubmitForm.propTypes = {
  form: PropTypes.object.isRequired,
  product: PropTypes.object,
  quantity: PropTypes.any,
  issueDate: PropTypes.any,
  note: PropTypes.any,
  ingredients: PropTypes.array.isRequired,
  description: PropTypes.string,
  recipe: PropTypes.object,
  inflowIngredients: PropTypes.array.isRequired,
  customerOrderLineItem: PropTypes.object,
};

export default SubmitForm;
