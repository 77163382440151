import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import AkauntingIcon from '../../../Helper/AkauntingIcon';

const CompanySelectionForm = (props) => {
  const {
    companies,
    externalId,
    setExternalId,
    saveIntegration,
    onClickBack,
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.integration.forms.company_selection_form', locale: localeContext.locale };

  const [submitting, setSubmitting] = useState(false);
  const onFormSubmit = () => {
    setSubmitting(true);
    const formSaved = saveIntegration();
    setSubmitting(formSaved);
  };

  return (
    <React.Fragment>
      <div className="card border">
        <div className="card header">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <div className="mr-3">
                <AkauntingIcon />
              </div>
              <h5>{I18n.t('name', SCOPE_OPTIONS)}</h5>
              <h6 className="ml-auto">{I18n.t('steps', SCOPE_OPTIONS)}</h6>
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-xl-9 col-12">
                <div className="form-group row align-items-center">
                  <label className="col-lg-3 col-12 col-form-label font-weight-light">
                    {I18n.t('company', SCOPE_OPTIONS)}
                  </label>

                  <div className="col-lg-9 col-12">
                    <select className="custom-select form-control mr-2" value={externalId} onChange={e => setExternalId(e.target.value)} data-tid="akaunting-integration-company-selectbox">
                      {companies.map(company => <option key={company.id} value={company.id}>{company.name}</option>)}
                    </select>
                  </div>
                </div>

                <div className="actions row mt-4">
                  <div className="col-lg-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className={`btn btn-outline-light btn-lg text-muted mr-4 ${submitting ? 'disabled' : ''}`}
                      disabled={submitting}
                      onClick={onClickBack}
                      data-tid="return-to-authorize-credentials-button"
                    >
                      {I18n.t('actions.back', SCOPE_OPTIONS)}
                    </button>

                    <button
                      type="submit"
                      className={`btn btn-info btn-lg ${submitting ? 'disabled' : ''}`}
                      onClick={onFormSubmit}
                      disabled={submitting}
                      data-tid="create-akaunting-integration-button"
                    >
                      <i className={`fas fa-spinner fa-spin mr-1 ${submitting ? '' : 'd-none'}`} />
                      {I18n.t('actions.create', SCOPE_OPTIONS)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CompanySelectionForm.propTypes = {
  onClickBack: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  externalId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  setExternalId: PropTypes.func.isRequired,
  saveIntegration: PropTypes.func.isRequired,
};

export default CompanySelectionForm;
