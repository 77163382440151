import React, { useReducer, useState, useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { isEmpty } from 'lodash';

Modal.setAppElement('#react-modal-render-wormhole');

const CreateNewCustomerModal = (props) => {
  const {
    isModalOpened,
    setIsModalOpened,
    newCustomerName,
    form,
    onCustomerCreate,
    createCustomerPath,
    i18nScope,
    isVendor,
    renderContactType
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: i18nScope, locale: localeContext.locale };
  const defaultFormValues = { name: newCustomerName, is_vendor: isVendor };
  const [formValues, dispatchFormValue] = useReducer((formValues, action) => {
    switch (action.type) {
      case 'update':
        return { ...formValues, ...action.payload };
      default:
        return formValues;
    }
  }, defaultFormValues);
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitCustomer = () => {
    setLoading(true);
    const params = {
      customer: {
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
        tax_office: formValues.tax_office,
        tax_number: formValues.tax_number,
        address: formValues.address,
        lead_time: formValues.lead_time,
        is_vendor: formValues.is_vendor
      },
    };
    params[form.csrf_param] = form.csrf_token;
    try {
      fetch(createCustomerPath, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      }).then((response) => {
        setLoading(false);
        return response.json();
      }).then((json) => {
        if (json.customer) {
          setIsModalOpened(false);
          onCustomerCreate(json.customer);
        }
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (isEmpty(formValues.name)) {
      setShowErrors(true);
    } else {
      submitCustomer();
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isModalOpened}
        className="modal show"
        style={{ overlay: {}, content: { display: 'block' } }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title font-20">{I18n.t('title', SCOPE_OPTIONS)}</span>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" data-tid="create-new-customer-modal-dismiss-btn" onClick={() => setIsModalOpened(false)}>
                <span aria-hidden="true"><i className="fa fa-close" /></span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <input value={formValues.name || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { name: e.target.value } })} className={`form-control form-control-lg ${showErrors && isEmpty(formValues.name) ? 'is-invalid' : ''}`} placeholder={I18n.t('name.placeholder', SCOPE_OPTIONS)} required data-tid="input-customer-name" />
                <div className="invalid-feedback">
                  {I18n.t('name.feedback', SCOPE_OPTIONS)}
                </div>
              </div>
              <div className="form-group">
                <input value={formValues.email || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { email: e.target.value } })} className="form-control form-control-lg" placeholder={I18n.t('email', SCOPE_OPTIONS)} />
              </div>
              <div className="form-group">
                <input value={formValues.phone || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { phone: e.target.value } })} className="form-control form-control-lg" placeholder={I18n.t('phone', SCOPE_OPTIONS)} />
              </div>
              <div className="form-group">
                <input value={formValues.tax_office || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { tax_office: e.target.value } })} className="form-control form-control-lg" placeholder={I18n.t('tax_office', SCOPE_OPTIONS)} />
              </div>
              <div className="form-group">
                <input value={formValues.tax_number || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { tax_number: e.target.value } })} className="form-control form-control-lg" placeholder={I18n.t('tax_number', SCOPE_OPTIONS)} />
              </div>
              <div className="form-group">
                <textarea value={formValues.address || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { address: e.target.value } })} className="form-control form-control-lg" placeholder={I18n.t('address', SCOPE_OPTIONS)} />
              </div>
              <div className="form-group">
                <input value={formValues.lead_time || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { lead_time: e.target.value } })} className="form-control form-control-lg" placeholder={I18n.t('lead_time', SCOPE_OPTIONS)} type="number"/>
              </div>
              {renderContactType && (
                <>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="customerRadio" name="is_vendor" value={true} onChange={e => dispatchFormValue({ type: 'update', payload: { is_vendor: e.target.value } })}/>
                    <label class="form-check-label" for="customerRadio">{I18n.t('customer', SCOPE_OPTIONS)}</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="vendorRadio" name="is_vendor" value={false} onChange={e => dispatchFormValue({ type: 'update', payload: { is_vendor: e.target.value } })}/>
                    <label class="form-check-label" for="vendorRadio">{I18n.t('vendor', SCOPE_OPTIONS)}</label>
                  </div>
                </>
              )}
              <div className="form-group d-flex justify-content-end">
                <button type="submit" className={`btn btn-info btn-lg ${loading ? 'disabled' : ''}`} onClick={handleSubmit} disabled={loading} data-tid="new-customer-submit-btn">
                  <i className={`fa fa-spinner fa-spin mr-1 ${loading ? '' : 'd-none'}`} />
                  {I18n.t('actions.save', SCOPE_OPTIONS)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

CreateNewCustomerModal.propTypes = {
  renderContactType: false
}

CreateNewCustomerModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  setIsModalOpened: PropTypes.func.isRequired,
  newCustomerName: PropTypes.string,
  form: PropTypes.object.isRequired,
  onCustomerCreate: PropTypes.func.isRequired,
  createCustomerPath: PropTypes.string.isRequired,
  i18nScope: PropTypes.string.isRequired,
  renderContactType: PropTypes.bool
};

export default CreateNewCustomerModal;
