import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import { formProp } from '../../Helper/CommonProps';
import LineItemForm from './LineItemForm';

const LineItemsList = (props) => {
  const {
    form,
    links,
    lineItems,
    addLineItem,
    updateLineItem,
    removeLineItem,
    renderFormErrors,
    units,
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.customer_order.forms.line_items_list', locale: localeContext.locale };

  let calculatedIdentifier = 1;

  return (
    <React.Fragment>
      <div className="row">
        <div className="offset-lg-2 col-lg-8"><hr /></div>
        <div className="col-lg-12 d-flex flex-column">
          <span className="font-20 font-weight-light text-black-50 text-center">{I18n.t('title', SCOPE_OPTIONS)}</span>
          <small className="text-center text-black-50">{I18n.t('info', SCOPE_OPTIONS)}</small>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          {
            lineItems.map((lineItem, i) => (
              <LineItemForm
                key={i}
                index={i}
                lineItem={lineItem}
                updateLineItem={updateLineItem}
                removeLineItem={removeLineItem}
                calculatedIdentifier={(lineItem.deleted ? null : calculatedIdentifier++)}
                renderFormErrors={renderFormErrors}
                links={links}
                form={form}
                units={units}
              />
            ))
          }
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className="btn btn-outline-secondary btn-sm" onClick={addLineItem} data-tid="add-new-line-item-btn">
            <i className="fa fa-plus mr-2" />
            {I18n.t('actions.add', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

LineItemsList.propTypes = {
  form: formProp.isRequired,
  links: PropTypes.object.isRequired,
  lineItems: PropTypes.array.isRequired,
  addLineItem: PropTypes.func.isRequired,
  updateLineItem: PropTypes.func.isRequired,
  removeLineItem: PropTypes.func.isRequired,
  renderFormErrors: PropTypes.bool.isRequired,
  units: PropTypes.array.isRequired,
};

export default LineItemsList;
