import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';

const GranularityButtons = (props) => {
  const { options, selected, onChange, i18nScope } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: i18nScope, locale: localeContext.locale };

  const isGranularitySelected = (option) => {
    return (option.toLowerCase() === selected.toLowerCase());
  };

  return (
    <div className="ml-auto align-self-center">
      <div className="btn-group btn-group-sm">
        {Object.values(options).map((value) => {
          return (
            <button
              key={`granularity-${value}`}
              type="button"
              className={`btn ${isGranularitySelected(value) ? 'btn-dark' : 'btn-outline-dark'}`}
              onClick={() => onChange(value)}>
              {I18n.t(`granularity.${value}`, SCOPE_OPTIONS)}
            </button>
          )
        })}
      </div>
    </div>
  )
};

GranularityButtons.propTypes = {
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
};

export default GranularityButtons;
