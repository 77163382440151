import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import IngredientForm from '../../NewRecipe/Forms/IngredientForm';
import searchProducts from './searchProducts';

const IngredientFormWithAutoMatchProduct = (props) => {
  const { ingredient, form, updateIngredient, index } = props;
  const hasNewProduct = (ingredient.product && ingredient.product.id) ? false : true;

  const [isSearching, setIsSearching] = useState(false);
  const [isError, setIsError] = useState(false);

  const onProductMatch = (product) => {
    updateIngredient(index, {
      ...ingredient,
      product,
      used_unit: product.unit,
    });
  };

  useEffect(() => {
    if (ingredient.product.code) {
      searchProducts(ingredient.product.code, form.product_search_path, onProductMatch, setIsSearching, setIsError);
    }
  }, []);

  return (
    <IngredientForm {...props}>
      <div className="small mt-1">
        {isSearching && (
          <i className="fa fa-spinner fa-spin mr-1"></i>
        )}
        {hasNewProduct ? (
          <React.Fragment>
            <span className="badge badge-warning text-white">YENİ</span>
            <span className="ml-1 text-black-50">Bu ürün yeni oluşturulacak!</span>
          </React.Fragment>
        ) : (
            <React.Fragment>
              <span className="badge badge-secondary">KAYITLI</span>
              <span className="ml-1 text-black-50">Hali hazırda kayıtlı bir ürün</span>
            </React.Fragment>
          )
        }
        {isError && (
          <i className="fa fa-exclamation-triangle text-danger ml-1"></i>
        )}
      </div>
    </IngredientForm>
  );
};

IngredientFormWithAutoMatchProduct.propTypes = {
  form: PropTypes.object.isRequired,
  ingredient: PropTypes.object.isRequired,
  updateIngredient: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  calculatedIdentifier: PropTypes.number,
  allowUnitConversion: PropTypes.bool.isRequired,
  renderFormErrors: PropTypes.bool.isRequired,
  children: PropTypes.node,
  units: PropTypes.array.isRequired
};

export default IngredientFormWithAutoMatchProduct;