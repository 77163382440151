import React, { useState, useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { isEmpty } from 'lodash';

Modal.setAppElement('#react-modal-render-wormhole');

const EditColumnModal = (props) => {
  const { isModalOpened, setIsModalOpened, column, onUpdate } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.board.elements.edit_column_modal', locale: localeContext.locale };
  const [columnName, setColumnName] = useState(column.name);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const updateColumn = () => {
    const { action, method, csrf_token } = column.links.update.form;
    fetch(action, {
      credentials: 'same-origin',
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token
      },
      body: JSON.stringify({
        column: {
          name: columnName,
        }
      })
    }).then((response) => {
      return response.json()
    }).then((json) => {
      setLoading(false);
      setShowError(false);
      setIsModalOpened(false);
      onUpdate();
    }).catch((ex) => {
      setLoading(false);
      setShowError(true);
    });
  }

  const handleSubmit = () => {
    if (!isEmpty(columnName)) {
      setLoading(true);
      updateColumn();
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isModalOpened}
        className="modal show"
        style={{ overlay: {}, content: { display: 'block' } }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title font-20">{I18n.t('title', SCOPE_OPTIONS)}</span>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" data-tid="edit-column-modal-dismiss-btn" onClick={() => setIsModalOpened(false)}>
                <span aria-hidden="true"><i className="fa fa-close" /></span>
              </button>
            </div>
            <div className="modal-body">
              {showError && (
                <div className="card text-white bg-danger mb-3">
                  <div className="card-body">
                    <span className="card-text">{I18n.t('error', SCOPE_OPTIONS)}</span>
                  </div>
                </div>
              )}
              <div className="form-group">
                <input value={columnName || ''} onChange={e => setColumnName(e.target.value)} className={`form-control form-control-lg ${isEmpty(columnName) ? 'is-invalid' : ''}`} placeholder={I18n.t('name.placeholder', SCOPE_OPTIONS)} required data-tid="input-column-name" />
                <div className="invalid-feedback">
                  {I18n.t('name.feedback', SCOPE_OPTIONS)}
                </div>
              </div>
              <div className="form-group d-flex justify-content-end">
                <button type="submit" className={`btn btn-info btn-lg ${loading ? 'disabled' : ''} ${isEmpty(columnName) ? 'disabled' : ''}`} onClick={handleSubmit} disabled={loading} data-tid="edit-column-submit-btn">
                  <i className={`fas fa-spinner fa-spin mr-1 ${loading ? '' : 'd-none'}`} />
                  {I18n.t('actions.update', SCOPE_OPTIONS)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};


EditColumnModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  setIsModalOpened: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default EditColumnModal;
