import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Column from './Column';
import Predicate from './Predicate';
import TextInput from './Inputs/TextInput';
import DateInput from './Inputs/DateInput';
import NumberInput from './Inputs/NumberInput';
import SelectInput from './Inputs/SelectInput';

const Filter = (props) => {
  const {
    index, columns, predicates, filter, removeFilter, updateFilter, i18nScope
  } = props;

  const handleColumnChange = (e) => {
    updateFilter(index, {
      ...filter,
      name: e.target.value,
      name_with_predicate: `${e.target.value}_`,
      predicate: '',
      value: '',
      name_with_association: e.target.value
    });
  };

  const handlePredicateChange = (e) => {
    updateFilter(index, { ...filter, predicate: e.target.value, name_with_predicate: `${filter.name}_${e.target.value}` });
  };

  const handleValueChange = (value) => {
    updateFilter(index, { ...filter, value: value });
  };

  const handleFilterRemove = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    removeFilter(index);
  };

  const columnType = columns.hasOwnProperty(filter.name_with_association) ? columns[filter.name_with_association].type : undefined;
  const columnPredefinedValues = columns.hasOwnProperty(filter.name_with_association) ? columns[filter.name_with_association].values : [];
  const predicatesFilteredByColumnType = predicates.hasOwnProperty(columnType) ? predicates[columnType] : [];

  let valueComponent;
  switch (columnType) {
    case 'text':
      valueComponent = (
        <TextInput value={filter.value} onChange={handleValueChange} />
      );
      break;
    case 'date':
      valueComponent = (
        <DateInput value={filter.value} onChange={handleValueChange} />
      );
      break;
    case 'number':
      valueComponent = (
        <NumberInput value={filter.value} onChange={handleValueChange} />
      );
      break;
    case 'enum':
      valueComponent = (
        <SelectInput value={filter.value} values={columnPredefinedValues} onChange={handleValueChange} i18nScope={i18nScope} />
      )
      break;
  }

  return (
    <div className='form-inline mb-2 flex-nowrap'>
      <div className="d-flex mx-2">
        <Column values={columns} selected={filter.name_with_association} onChange={handleColumnChange} i18nScope={i18nScope} />
        <Predicate values={predicatesFilteredByColumnType} selected={filter.predicate} onChange={handlePredicateChange} />
        {valueComponent}
      </div>

      <button type="button" className="close ml-auto mr-1" onClick={handleFilterRemove} data-tid="filterable-remove-filter-btn">
        <span>&times;</span>
      </button>
    </div>
  )
};

Filter.propTypes = {
  index: PropTypes.number.isRequired,
  columns: PropTypes.object.isRequired,
  predicates: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  removeFilter: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  i18nScope: PropTypes.string.isRequired,
};

export default Filter;
