import { useReducer } from 'react';

const useIngredientManagerReducer = (initialIngredientState, emptyIngredient) => useReducer((ingredients, action) => {
  switch (action.type) {
    case 'add':
      return [...ingredients, emptyIngredient];
    case 'update':
      return ingredients.map((ingredient, i) => {
        if (i === action.index) {
          return action.ingredient;
        }
        return ingredient;
      });
    default:
      return ingredients;
  }
}, initialIngredientState);

export default useIngredientManagerReducer;
