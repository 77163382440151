import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProductAutocomplete from './ProductAutocomplete';
import CreateNewProductModal from './CreateNewProductModal';

const ProductAutocompleteWithCreateModal = (props) => {
  const {
    children, product, searchPath, onProductSelect,
    wrapperPropsStyles, wrapperClassNames, topClassNames,
    parasutProductNewUrl, inputDataTid, inputClassNames, form, createProductPath, showBuyingPriceInput,
    inputPlaceholder, units, links
  } = props;

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [newProductName, setNewProductName] = useState('');

  const handleAddNewProduct = (value) => {
    setNewProductName(value);
    setIsModalOpened(true);
  };

  return (
    <React.Fragment>
      <ProductAutocomplete
        product={product}
        searchPath={searchPath}
        onProductSelect={onProductSelect}
        wrapperPropsStyles={wrapperPropsStyles}
        wrapperClassNames={wrapperClassNames}
        topClassNames={topClassNames}
        parasutProductNewUrl={parasutProductNewUrl}
        inputDataTid={inputDataTid}
        inputClassNames={inputClassNames}
        renderAddProductModal={true}
        onClickAddNewProduct={value => handleAddNewProduct(value)}
        inputPlaceholder={inputPlaceholder}
      >
        {children}
      </ProductAutocomplete>
      {isModalOpened && (
        <CreateNewProductModal
          isModalOpened={isModalOpened}
          setIsModalOpened={modalState => setIsModalOpened(modalState)}
          newProductName={newProductName}
          form={form}
          onProductCreate={onProductSelect}
          createProductPath={createProductPath}
          showBuyingPriceInput={showBuyingPriceInput}
          units={units}
          links={links}
        />
      )}
    </React.Fragment>
  );
};

ProductAutocompleteWithCreateModal.propTypes = {
  // autocomplete props
  product: PropTypes.object,
  searchPath: PropTypes.string.isRequired,
  onProductSelect: PropTypes.func.isRequired,
  wrapperPropsStyles: PropTypes.object,
  wrapperClassNames: PropTypes.string,
  topClassNames: PropTypes.string,
  parasutProductNewUrl: PropTypes.string.isRequired,
  inputDataTid: PropTypes.string,
  inputClassNames: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  children: PropTypes.node,
  // create new product modal props
  form: PropTypes.object.isRequired,
  createProductPath: PropTypes.string.isRequired,
  showBuyingPriceInput: PropTypes.bool.isRequired,
  units: PropTypes.array.isRequired,
  links: PropTypes.object.isRequired
};

export default ProductAutocompleteWithCreateModal;
