import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';

const LottieWrapper = (props) => {
  const animationRef = useRef(null);
  const { path } = props;

  useEffect(() => {
    lottie.loadAnimation({
      container: animationRef.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: path // the path to the animation json
    });
  });

  return (
    <div ref={animationRef}></div>
  )
}

LottieWrapper.propTypes = {
  path: PropTypes.string.isRequired,
}

export default LottieWrapper;
