import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import { validInput, validQuantity } from '../../Helper/Validators/ManufacturingPlanValidator';

const PlanExpenditureForm = (props) => {
  const {
    index,
    planExpenditure,
    updatePlanExpenditure,
    removePlanExpenditure,
    calculatedIdentifier,
    renderFormErrors,
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_manufacturing_plan.forms.plan_expenditure_form', locale: localeContext.locale };

  const { description, expected_spend, currency } = planExpenditure;

  const handleDescription = e => updatePlanExpenditure(index, { ...planExpenditure, description: e.target.value });
  const handleExpectedSpend = e => updatePlanExpenditure(index, { ...planExpenditure, expected_spend: e.target.value });
  const markAsDeleted = () => removePlanExpenditure(index, planExpenditure);

  const descriptionInvalid = !validInput(description);
  const expectedSpendInvalid = !validQuantity(expected_spend);

  return (
    <div className={`plan-expenditure-item mt-2 ${planExpenditure.deleted ? 'd-none' : ''}`}>
      <div className="d-flex flex-wrap row">
        <span className="font-18 font-weight-light text-black-50 col-form-label">{calculatedIdentifier}</span>
        <div className="flex-grow-1 ml-3">
          <input value={description || ''} onChange={handleDescription} className={`form-control ${renderFormErrors && descriptionInvalid && 'is-invalid'}`} type="text" required data-tid="input-plan-expenditure-description" placeholder={I18n.t('description.placeholder', SCOPE_OPTIONS)} />
          <div className="invalid-feedback">
            {I18n.t('description.feedback', SCOPE_OPTIONS)}
          </div>
        </div>
        <div className="ml-3" style={{ maxWidth: '250px' }}>
          <div className="input-group">
            <input value={expected_spend || ''} onChange={handleExpectedSpend} className={`form-control ${renderFormErrors && expectedSpendInvalid && 'is-invalid'}`} type="number" required min="0" step="0.0001" data-tid="input-plan-recipe-quantity" placeholder={I18n.t('quantity.placeholder', SCOPE_OPTIONS)} />
            <div className="input-group-append">
              <span className="input-group-text">{currency}</span>
            </div>
            <div className="invalid-feedback">
              {I18n.t('quantity.feedback', SCOPE_OPTIONS)}
            </div>
          </div>
        </div>
        <div className="col-form-label ml-3">
          <button type="button" className="close close-danger" data-dismiss="alert" aria-label="Close" onClick={markAsDeleted} data-tid="remove-plan-recipe-btn">
            <i className="fa fa-trash" style={{ fontSize: '1.1em' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

PlanExpenditureForm.propTypes = {
  index: PropTypes.number.isRequired,
  planExpenditure: PropTypes.shape({
    description: PropTypes.string,
    expected_currency: PropTypes.any,
    currency: PropTypes.string,
  }).isRequired,
  updatePlanExpenditure: PropTypes.func.isRequired,
  removePlanExpenditure: PropTypes.func.isRequired,
  calculatedIdentifier: PropTypes.number,
  renderFormErrors: PropTypes.bool.isRequired,
};

export default PlanExpenditureForm;
