import { statusHandler } from '../Helper/RequestHelpers';

export function reorderList(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export const updateColumnPosition = (column, params, renderFlashMessage) => {
  const { action, method, csrf_token } = column.links.update.form;
  const { position } = params;

  fetch(action, {
    credentials: 'same-origin',
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf_token
    },
    body: JSON.stringify({
      column: {
        name: column.name,
        column_order_position: position,
      }
    })
  }).then(statusHandler).then((response) => {
    return response.json()
  }).then((json) => {
    // console.log('parsed json', json)
  }).catch((ex) => {
    renderFlashMessage('danger', 'components.board.reorder.update_column_position_error');
  })
}

export const updateCardPosition = (column, card, position, renderFlashMessage) => {
  const { method, csrf_token } = column.links.update.form;
  const { kanban_card_path } = card.links;

  fetch(kanban_card_path, {
    credentials: 'same-origin',
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf_token
    },
    body: JSON.stringify({
      card: {
        column_id: column.originalId,
        card_order_position: position,
      }
    })
  }).then(statusHandler).then((response) => {
    return response.json()
  }).then((json) => {
    // console.log('parsed json', json)
  }).catch((ex) => {
    renderFlashMessage('danger', 'components.board.reorder.update_card_position_error');
  })
}
