import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import { isEmpty } from 'lodash';
import { validProduct, validInflowIngredients } from '../../Helper/Validators/IngredientValidator';
import { validDate } from '../../Helper/Validators/DateValidator';
import ProductAutocompleteWithCreateModal from '../../Product/ProductAutocompleteWithCreateModal';
import RecordIngredientForm from './RecordIngredientForm';

const RecordDetailForm = (props) => {
  const {
    form, onClick, links, product, setProduct,
    quantity, setQuantity, issueDate, setIssueDate,
    inflowIngredients, addInflow, updateInflow, removeInflow,
    allowUnitConversion, units
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_manufacturing_record.forms.record_detail_form', locale: localeContext.locale };
  const unit = product.unit || I18n.t('unit', SCOPE_OPTIONS);
  let calculatedInflowId = 1;
  const activeInflowCount = inflowIngredients.filter(ingredient => !ingredient.deleted).length;

  const [renderFormErrors, setRenderFormErrors] = useState(false);
  const goToNext = () => {
    setRenderFormErrors(false);
    if (!validInflowIngredients(inflowIngredients)) {
      return setRenderFormErrors(true);
    }

    if (!validDate(issueDate)) {
      return setRenderFormErrors(true);
    }

    return onClick();
  };

  const issueDateInvalid = !validDate(issueDate);

  return (
    <React.Fragment>
      <div className="row mt-5">
        <div className="offset-lg-4 col-lg-5 d-flex">
          <h1 className="flex-grow-1">{I18n.t('title', SCOPE_OPTIONS)}</h1>
          <a href={links.manufacturing_records_path} className="align-self-center btn" data-tid="cancel-from-record-detail-form">
            <i className="fa fa-close fa-2x" />
          </a>
        </div>
      </div>

      <div className="row mt-5">
        <div className="offset-lg-4 col-lg-4">
          <p className="text-center font-32 font-weight-light">{I18n.t('what_is_the_product', SCOPE_OPTIONS)}</p>
          <ProductAutocompleteWithCreateModal links={links} units={units} product={product} onProductSelect={(product) => { setProduct(product); }} searchPath={links.product_search_path} parasutProductNewUrl={links.parasut_product_new_url} inputDataTid="input-product-autocomplete" inputClassNames="form-control-lg" form={form} createProductPath={links.create_product_path} showBuyingPriceInput={false}>
            {validProduct(product) && (
              <React.Fragment>
                <div className="row mt-4">
                  <div className="col input-group" data-tid="input-product-output-quantity-group">
                    <input value={quantity || ''} onChange={e => setQuantity(e.target.value)} className="form-control form-control-lg" type="number" required min="0" step="0.0001" data-tid="input-product-quantity" placeholder={I18n.t('quantity', SCOPE_OPTIONS)} />
                    <div className="input-group-append">
                      <span className="input-group-text">{unit}</span>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">{I18n.t('issue_date.placeholder', SCOPE_OPTIONS)}</div>
                    </div>
                    <input value={issueDate} onChange={e => setIssueDate(e.target.value)} className="form-control form-control-lg" type="datetime-local" required data-tid="input-manufacturing-record-issue-date" />
                    {renderFormErrors && issueDateInvalid && (
                      <div className="invalid-feedback" style={{ display: 'block' }}>
                        {I18n.t('issue_date.feedback', SCOPE_OPTIONS)}
                      </div>
                    )}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col d-flex justify-content-end">
                    <button type="button" onClick={addInflow} className="btn btn-sm btn-outline-secondary" data-toggle="tooltip" data-original-title={I18n.t('inflow_product.description', SCOPE_OPTIONS)} data-tid="add-more-inflow-ingredient-btn">
                      <span className="font-weight-light">
                        <i className="fa fa-plus pr-1" />
                        {I18n.t('inflow_product.actions.add', SCOPE_OPTIONS)}
                      </span>
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </ProductAutocompleteWithCreateModal>
        </div>
      </div>

      {validProduct(product) && (
        <React.Fragment>
          {activeInflowCount > 0 && (
            <div className="row mt-4">
              <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
                <span className="font-20 text-black-50 font-weight-light">{I18n.t('inflow_product.title', SCOPE_OPTIONS)}</span>
              </div>
              <div className="offset-lg-2 col-lg-8">
                {
                  inflowIngredients.map((inflowIngredient, i) => (
                    <RecordIngredientForm
                      key={`inflow-ingredient-${i}`}
                      index={i}
                      form={form}
                      ingredient={inflowIngredient}
                      updateIngredient={updateInflow}
                      calculatedIdentifier={(inflowIngredient.deleted ? null : calculatedInflowId++)}
                      allowUnitConversion={allowUnitConversion}
                      removeIngredient={removeInflow}
                      renderFormErrors={renderFormErrors}
                      links={links}
                      units={units}
                    />
                  ))
                }
              </div>
            </div>
          )}
          <div className="row mt-4">
            <div className="offset-lg-4 col-lg-4">
              <div className="d-flex justify-content-center">
                <button type="button" className="btn btn-info btn-lg" onClick={goToNext} disabled={isEmpty(quantity)} data-tid="continue-to-ingredients-list">
                  {I18n.t('actions.continue', SCOPE_OPTIONS)}
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

RecordDetailForm.propTypes = {
  form: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  links: PropTypes.object.isRequired,
  product: PropTypes.object,
  setProduct: PropTypes.func.isRequired,
  quantity: PropTypes.node,
  setQuantity: PropTypes.func.isRequired,
  issueDate: PropTypes.node,
  setIssueDate: PropTypes.func.isRequired,
  allowUnitConversion: PropTypes.bool.isRequired,
  inflowIngredients: PropTypes.array.isRequired,
  addInflow: PropTypes.func.isRequired,
  updateInflow: PropTypes.func.isRequired,
  removeInflow: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired
};

export default RecordDetailForm;
