import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useCollectionManagerReducer from '../Helper/Hooks/UseCollectionManagerReducer';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import { formProp } from '../Helper/CommonProps';
import FlashAlert from '../FlashMessage/FlashAlert';
import SupplyPlanItemList from './Forms/SupplyPlanItemList';
import { statusHandler } from '../Helper/RequestHelpers';
import { validLineItems, validGoalDate, validName } from '../Helper/Validators/SupplyPlanningValidator';

const Form = (props) => {
  const { form, translation, links } = props;

  const SCOPE_OPTIONS = { scope: 'components.supply_planning.form', locale: translation.locale };

  const [flashMessage, setFlashMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [renderFormErrors, setRenderFormErrors] = useState(false);
  const [planName, setPlanName] = useState(null);
  const [goalDate, setGoalDate] = useState(null);

  const emptySupplyPlanItem = { product: {}, recipe: {}, quantity: '' };
  const initialSupplyPlanItemsState = [emptySupplyPlanItem];
  const [supplyPlanItems, dispatchSupplyPlanItems] = useCollectionManagerReducer(initialSupplyPlanItemsState, emptySupplyPlanItem);
  const addNewPlanItem = () => dispatchSupplyPlanItems({ type: 'add' });
  const updatePlanItem = (index, planItem) => dispatchSupplyPlanItems({ type: 'update', index, object: planItem });
  const deletePlanItem = (index) => dispatchSupplyPlanItems({ type: 'delete', index });

  const goalDateInvalid = !validGoalDate(goalDate);
  const nameInvalid = !validName(planName);
  const supplyPlanningInvalid = !validLineItems(supplyPlanItems) || goalDateInvalid || nameInvalid;

  const handleSubmit = () => {
    if (supplyPlanningInvalid) {
      setRenderFormErrors(true);
    } else {
      setLoading(true);
      setRenderFormErrors(false);
      saveSupplyPlanning();
    }
  }

  const saveSupplyPlanning = () => {
    const { action, method, csrf_token } = form;
    fetch(action, {
      credentials: 'same-origin',
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token
      },
      body: JSON.stringify({
        supply_planning: {
          name: planName,
          goal_date: goalDate,
          line_items: supplyPlanItems.map(({ product, recipe, quantity }) => {
            return {
              quantity: quantity,
              recipe: { id: recipe.id, description: recipe.description },
              product: { id: product.id, name: product.name, unit: product.unit }
            }
          })
        }
      })
    }).then(statusHandler).then((response) => {
      return response.json()
    }).then((json) => {
      setLoading(false);
      setRenderFormErrors(false);
      window.Turbolinks.visit(json.supply_planning_materials_path);
    }).catch((ex) => {
      setLoading(false);
      setRenderFormErrors(true);
      if (ex.json && ex.json.error_detail) {
        const message = { id: Math.random(), type: 'danger', text: ex.json.error_detail };
        setFlashMessage(message);
      }
    });
  };

  return (
    <LocaleContext.Provider value={translation}>
      {flashMessage && (
        <div className='px-4'>
          <FlashAlert key={flashMessage.id} message={flashMessage} onClose={() => setFlashMessage(null)} />
        </div>
      )}

      <div className="row mt-2">
        <div className="col-12">
          <div className="form-row mx-2">
            <div className="form-group row col-lg-6 col-12">
              <label className="col-lg-2 col-12 col-form-label font-weight-light">{I18n.t('plan_name.label', SCOPE_OPTIONS)}</label>
              <div className="col-lg-10 col-12">
                <input value={planName || ''} onChange={e => setPlanName(e.target.value)} className={`form-control ${renderFormErrors && nameInvalid && 'is-invalid'}`} data-tid="input-supply-planning-plan-name" required />
                <div className="invalid-feedback">
                  {I18n.t('plan_name.feedback', SCOPE_OPTIONS)}
                </div>
              </div>
            </div>
            <div className="form-group row col-lg-6 col-12 ml-lg-2">
              <label className="col-lg-2 col-12 col-form-label font-weight-light">{I18n.t('goal_date.label', SCOPE_OPTIONS)}</label>
              <div className="col-lg-10 col-12">
                <input type="date" value={goalDate || ''} onChange={(e) => setGoalDate(e.target.value)} className={`form-control ${renderFormErrors && goalDateInvalid && 'is-invalid'}`} data-tid="input-supply-planning-goal-date" required />
                <small className="form-text text-muted">{I18n.t('goal_date.info', SCOPE_OPTIONS)}</small>
                <div className="invalid-feedback">
                  {I18n.t('goal_date.feedback', SCOPE_OPTIONS)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SupplyPlanItemList
        supplyPlanItems={supplyPlanItems}
        addNewPlanItem={addNewPlanItem}
        updatePlanItem={updatePlanItem}
        deletePlanItem={deletePlanItem}
        links={links}
        renderFormErrors={renderFormErrors}
      />

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className={`btn btn-info btn-lg ${loading ? 'disabled' : ''}`} data-tid="supply-planning-create-btn" disabled={loading} onClick={handleSubmit}>
            <i className={`fas fa-spinner fa-spin mr-1 ${loading ? '' : 'd-none'}`} />
            {I18n.t('actions.create', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>

    </LocaleContext.Provider>
  );
};

Form.propTypes = {
  form: formProp.isRequired,
  translation: LocaleProp.isRequired,
  links: PropTypes.shape({
    recipe_search_path: PropTypes.string.isRequired,
    new_recipe_path: PropTypes.string.isRequired,
  }).isRequired,
};

export default Form;
