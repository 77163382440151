import React, { useState, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import RecipeForm from './Forms/RecipeForm';
import Ingredients from './Forms/Ingredients';
import SubmitForm from './Forms/SubmitForm';
import { validIngredients } from '../Helper/Validators/IngredientValidator';
import LocaleContext, { LocaleProp } from 'Context/LocaleContext';

export const STATES = {
  recipeForm: 'recipeForm',
  ingredientsForm: 'ingredientsForm',
};

const Form = (props) => {
  const {
    form, recipe, feature_flags, currentView: propCurrentView, translation, units, links
  } = props;
  const [currentView, setCurrentView] = useState(propCurrentView || STATES.recipeForm);
  //
  // RECIPE BASE INFO
  //
  const [product, setProduct] = useState(recipe.product || {});
  const [outputQuantity, setOutputQuantity] = useState(recipe.output_quantity);
  //
  // INGREDIENTS RELATED
  //
  const emptyIngredient = { product: {}, quantity: '', used_unit: '' };
  const initialIngredientState = (recipe.ingredients.length > 0 ? recipe.ingredients : [emptyIngredient]);
  const [ingredients, dispatchIngredients] = useReducer((ingredients, action) => {
    switch (action.type) {
      case 'add':
        return [...ingredients, emptyIngredient];
      case 'update':
        return ingredients.map((ingredient, i) => {
          if (i === action.index) {
            return action.ingredient;
          }
          return ingredient;
        });
      case 'remove':
        const newIngredients = [...ingredients];
        newIngredients.splice(action.index, 1);
        return newIngredients;
      default:
        return ingredients;
    }
  }, initialIngredientState);
  const addIngredient = () => dispatchIngredients({ type: 'add' });
  const updateIngredient = (index, ingredient) => dispatchIngredients({ type: 'update', index, ingredient });
  const removeIngredient = (index, ingredient) => {
    dispatchIngredients({ type: 'update', index, ingredient: { ...ingredient, deleted: true } });
  };

  // form & validations
  const [renderFormErrors, setRenderFormErrors] = useState(false);
  const submitFormRef = useRef(null);
  const saveRecipe = () => {
    setRenderFormErrors(true);
    if (validIngredients(ingredients)) {
      submitFormRef.current.submit();
      return true;
    }
    return false;
  };

  let renderedComponent;
  switch (currentView) {
    case STATES.recipeForm:
      renderedComponent = (
        <RecipeForm
          onClick={() => setCurrentView(STATES.ingredientsForm)}
          recipe={recipe}
          product={product}
          setProduct={setProduct}
          outputQuantity={outputQuantity}
          setOutputQuantity={setOutputQuantity}
          form={form}
          units={units}
          links={links}
        />
      );
      break;
    case STATES.ingredientsForm:
      renderedComponent = (
        <Ingredients
          onClick={() => setCurrentView(STATES.recipeForm)}
          ingredients={ingredients}
          addIngredient={addIngredient}
          updateIngredient={updateIngredient}
          product={product}
          outputQuantity={outputQuantity}
          form={form}
          saveRecipe={saveRecipe}
          allowUnitConversion={feature_flags.feature_conversion}
          removeIngredient={removeIngredient}
          renderFormErrors={renderFormErrors}
          units={units}
          links={links}
        />
      );
      break;
  }

  return (
    <LocaleContext.Provider value={translation}>
      {renderedComponent}

      {/* for autocomplete list result, we need to expand bottom */}
      <div style={{ marginTop: '250px' }}>
        <SubmitForm form={form} product={product} outputQuantity={outputQuantity} ingredients={ingredients} ref={submitFormRef} description={recipe.description} />
      </div>
    </LocaleContext.Provider>
  );
};

Form.propTypes = {
  recipe: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  feature_flags: PropTypes.object.isRequired,
  currentView: PropTypes.oneOf(Object.keys(STATES)),
  translation: LocaleProp.isRequired,
  units: PropTypes.array.isRequired,
  links: PropTypes.object.isRequired,
};

export default Form;
