import React from 'react';
import PropTypes from 'prop-types';

const SubmitForm = React.forwardRef((props, ref) => {
  const {
    form, usingCloudVersion, baseUrl, username, password, externalId,
  } = props;

  return (
    <React.Fragment>
      <form
        action={form.action}
        method={form.method}
        data-remote
        data-turbolinks-form
        data-tid="create-akaunting-integration-form"
        data-element="create-akaunting-integration-form"
        ref={ref}
      >
        <input type="hidden" name={form.csrf_param} value={form.csrf_token} />
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value={form.method} />

        <input
          type="hidden"
          value={usingCloudVersion || ''}
          name="integration[using_cloud_version]"
        />
        <input
          type="hidden"
          value={baseUrl || ''}
          name="integration[base_url]"
        />
        <input
          type="hidden"
          value={username || ''}
          name="integration[username]"
        />
        <input
          type="hidden"
          value={password || ''}
          name="integration[password]"
        />
        <input
          type="hidden"
          value={externalId || ''}
          name="integration[external_id]"
        />
      </form>
    </React.Fragment>
  );
});

SubmitForm.propTypes = {
  form: PropTypes.object.isRequired,
  usingCloudVersion: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  externalId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default SubmitForm;
