import React from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import Header from './ManufacturingReport/Header';
import EmptyReportPlaceholder from './ManufacturingReport/EmptyReportPlaceholder';
import ReportContent from './ManufacturingReport/ReportContent';
import moment from 'moment';
import { isEmpty } from 'lodash';

const ManufacturingReport = (props) => {
  const {
    searchPath, generateProducedReportPath, generateConsumedReportPath, generateSummaryTablePath,
    translation, currentQueryParameters, product, consumedProducts
  } = props;

  const SCOPE_OPTIONS = { scope: 'components.report.manufacturing_report', locale: translation.locale };
  moment.locale(translation.locale);

  const refreshPageWith = (queryParams) => {
    window.Turbolinks.visit(`${window.location.pathname}?${queryParams}`)
  };

  const hasMandatoryQueryParameters = currentQueryParameters.hasOwnProperty('product_id') &&
    currentQueryParameters.hasOwnProperty('start_date') &&
    currentQueryParameters.hasOwnProperty('end_date');

  const showReportContent = hasMandatoryQueryParameters && !isEmpty(product);
  const showEmptyPlaceholder = !showReportContent;

  const reportTitle = (
    <div className="d-lg-flex justify-content-center">
      <h4>{I18n.t('title', {
        ...SCOPE_OPTIONS,
        start_date: moment(currentQueryParameters['start_date']).format('L'),
        end_date: moment(currentQueryParameters['end_date']).format('L'),
        product_code: isEmpty(product.code) ? '' : `(${product.code})`,
        product_name: product.name
      })}</h4>
    </div>
  );

  const tableTitle = (
    <h4 className="my-4">{I18n.t('table_title', {
      ...SCOPE_OPTIONS,
      start_date: moment(currentQueryParameters['start_date']).format('L'),
      end_date: moment(currentQueryParameters['end_date']).format('L'),
    })}</h4>
  );

  return (
    <LocaleContext.Provider value={translation}>
      <Header
        searchPath={searchPath}
        loadedProduct={product}
        currentQueryParameters={currentQueryParameters}
        refreshPageWith={refreshPageWith}
      />

      {showEmptyPlaceholder && <EmptyReportPlaceholder />}
      {showReportContent && (
        <ReportContent
          headerTextComponent={reportTitle}
          tableTextComponent={tableTitle}
          generateProducedReportPath={generateProducedReportPath}
          generateConsumedReportPath={generateConsumedReportPath}
          generateSummaryTablePath={generateSummaryTablePath}
          product={product}
          consumedProducts={consumedProducts}
          currentQueryParameters={currentQueryParameters}
          refreshPageWith={refreshPageWith}
        />
      )}
    </LocaleContext.Provider>
  )
};

ManufacturingReport.propTypes = {
  searchPath: PropTypes.string.isRequired,
  generateProducedReportPath: PropTypes.string.isRequired,
  generateConsumedReportPath: PropTypes.string.isRequired,
  generateSummaryTablePath: PropTypes.string.isRequired,
  product: PropTypes.object,
  translation: LocaleProp.isRequired,
  consumedProducts: PropTypes.array,
};

ManufacturingReport.defaultProps = {
  product: {},
  consumedProducts: []
};

export default ManufacturingReport;
