import React, { useState, useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import LocaleContext, { I18n } from 'Context/LocaleContext';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import ItemList from './ItemList';
import EditColumnModal from './EditColumnModal';
import DestroyColumnModal from './DestroyColumnModal';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: ${({ isDragging }) =>
    isDragging ? colors.G50 : colors.N30};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${colors.G50};
  }
`;

const Title = styled.h4`
  padding: 8px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  font-size: 14px;

  &:focus {
    outline: 2px solid ${colors.P100};
    outline-offset: 2px;
  }
`;

const Column = React.memo(function Column({ column, index, fetchBoard, fetchCards, links }) {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.board.elements.column', locale: localeContext.locale };
  const [editModalOpened, setEditModalOpened] = useState(false);
  const [destroyModalOpened, setDestroyModalOpened] = useState(false);

  const handleEditColumn = () => {
    setEditModalOpened(true);
  }

  const handleDestroy = () => {
    setDestroyModalOpened(true)
  }

  return (
    <React.Fragment>
      <Draggable draggableId={column.id} index={index}>
        {(provided, snapshot) => (
          <div
            className="board-column mr-3"
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <Header isDragging={snapshot.isDragging}>
              <Title
                isDragging={snapshot.isDragging}
                {...provided.dragHandleProps}
              >
                {column.name}
              </Title>
              <div className="dropdown">
                <i className="fas fa-ellipsis-h mr-3 text-black-50 dropdown-icon" data-toggle="dropdown" />
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#" data-tid="edit-column-dropdown-btn" onClick={handleEditColumn}>{I18n.t('actions.edit', SCOPE_OPTIONS)}</a>
                  <a className="dropdown-item" href="#" data-tid="destroy-column-dropdown-btn" onClick={handleDestroy}>{I18n.t('actions.destroy', SCOPE_OPTIONS)}</a>
                </div>
              </div>

            </Header>
            <ItemList column={column} columnIndex={index} fetchCards={fetchCards} links={links} />
          </div>
        )}
      </Draggable>
      {editModalOpened && (
        <EditColumnModal
          isModalOpened={editModalOpened}
          setIsModalOpened={setEditModalOpened}
          column={column}
          onUpdate={fetchBoard}
        />
      )}
      {destroyModalOpened && (
        <DestroyColumnModal
          isModalOpened={destroyModalOpened}
          setIsModalOpened={setDestroyModalOpened}
          column={column}
          onUpdate={fetchBoard}
        />
      )}
    </React.Fragment>
  );
});

export default Column;