import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import PresetAutocomplete from '../../Helper/PresetAutocomplete';
import { validName } from '../../Helper/Validators/ProductValidator';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import { renderInput, isNewUnit } from '../../Helper/AutocompleteRenderInputWithBadge';
import CustomerAutocompleteWithCreateModal from '../../Customer/CustomerAutocompleteWithCreateModal';
import { isEmpty } from 'lodash';

const ProductForm = (props) => {
  const { product, formValues, dispatchFormValue, submitProduct, units, form, links, } = props;
  const { name, code, unit, barcode, vendor = {} } = formValues;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.product.forms.product_form', locale: localeContext.locale };

  const [loading, setLoading] = useState(false);
  const [renderFormErrors, setRenderFormErrors] = useState(false);

  const nameInvalid = !validName(name);

  const onFormSubmit = () => {
    setRenderFormErrors(true);
    if (validName(name)) {
      setLoading(true);
      submitProduct();
    }
  };

  return (
    <React.Fragment>
      <div className="card border">
        <div className="card header">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <h5>{product.name}</h5>
            </div>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-xl-10 col-12">
                <div className="form-group row">
                  <label className="col-lg-3 col-12 col-form-label">{I18n.t('name.label', SCOPE_OPTIONS)}</label>
                  <div className="col-lg-9 col-12">
                    <input type="text" value={name || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { name: e.target.value } })} className={`form-control ${renderFormErrors && nameInvalid ? 'is-invalid' : ''}`} data-tid="input-product-name" required />
                    <div className="invalid-feedback">
                      {I18n.t('name.invalid-feedback', SCOPE_OPTIONS)}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-12 col-form-label">{I18n.t('unit.label', SCOPE_OPTIONS)}</label>
                  <div className="col-lg-9 col-12">
                    <PresetAutocomplete
                      options={units}
                      placeholder={I18n.t('unit.placeholder', SCOPE_OPTIONS)}
                      value={unit || ''}
                      onChange={value => dispatchFormValue({ type: 'update', payload: { unit: value } })}
                      onSelect={value => dispatchFormValue({ type: 'update', payload: { unit: value } })}
                      inputDataTid='select-product-unit'
                      renderInput={props => renderInput(props, isNewUnit(units, unit), localeContext.locale)}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-12 col-form-label">{I18n.t('stock_code.label', SCOPE_OPTIONS)}</label>
                  <div className="col-lg-9 col-12">
                    <input type="text" className={'form-control'} value={code || ''} data-tid="input-product-stock-code" onChange={e => dispatchFormValue({ type: 'update', payload: { code: e.target.value } })} />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-lg-3 col-12 col-form-label">{I18n.t('barcode.label', SCOPE_OPTIONS)}</label>
                  <div className="col-lg-9 col-12">
                    <input type="text" className={'form-control'} value={barcode || ''} data-tid="input-product-barcode" onChange={e => dispatchFormValue({ type: 'update', payload: { barcode: e.target.value } })} />
                  </div>
                </div>

              <div className="form-group row">
                <label className="col-lg-3 col-12 col-form-label">{I18n.t('vendor.label', SCOPE_OPTIONS)}</label>
                <div className="col-lg-9 col-12">
                  <CustomerAutocompleteWithCreateModal
                    customer={vendor}
                    searchPath={links.customer_search_path}
                    onCustomerSelect={e => dispatchFormValue({ type: 'update', payload: { vendor: e } })}
                    inputDataTid='input-customer-order-customer-autocomplete'
                    renderAddCustomerModal={true}
                    form={form}
                    createCustomerPath={links.create_customer_path}
                    placeholderKey='components.product.forms.product_form.vendor.placeholder'
                    isVendor={true}
                  >
                    {!isEmpty(vendor) && (
                      <div className="d-flex justify-content-end">
                        <button onClick={() => dispatchFormValue({ type: 'update', payload: { vendor: null } })} className="btn btn-link"><small>{I18n.t('vendor.clear', SCOPE_OPTIONS)}</small></button>
                      </div>
                    )}
                  </CustomerAutocompleteWithCreateModal>
                </div>
              </div>

                <div className="actions row mt-4">
                  <div className="col-lg-12 d-flex justify-content-end">
                    <button
                      type="submit"
                      className={`btn btn-info btn-lg ${loading ? 'disabled' : ''}`}
                      onClick={onFormSubmit}
                      disabled={loading}
                      data-tid="update-product-btn"
                    >
                      <i className={`fas fa-spinner fa-spin mr-1 ${loading ? '' : 'd-none'}`} />
                      {I18n.t('actions.update', SCOPE_OPTIONS)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ProductForm.propTypes = {
  formValues: PropTypes.object.isRequired,
  dispatchFormValue: PropTypes.func.isRequired,
  submitProduct: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
};

export default ProductForm;
