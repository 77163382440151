import React, { useState, useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import RecordIngredientForm from './RecordIngredientForm';

const RecordIngredients = (props) => {
  const {
    note, setNote, links, onClick, quantity, product,
    ingredients, form, addIngredient, updateIngredient, removeIngredient,
    allowUnitConversion, renderFormErrors, saveManufacturingRecord, units
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.new_manufacturing_record.forms.record_ingredients', locale: localeContext.locale };
  const [showNote, setShowNote] = useState(false);
  const [loading, setLoading] = useState(false);
  const onFormSubmit = () => {
    setLoading(true);
    const formSaved = saveManufacturingRecord();
    setLoading(formSaved);
  };
  let calculatedIdentifier = 1;

  return (
    <React.Fragment>
      <div className="row mt-5">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-between">
          <button type="button" onClick={onClick} className="align-self-center btn" data-tid="back-to-record-detail-form">
            <i className="fa fa-arrow-left fa-2x" />
          </button>
          <h1>{I18n.t('title', SCOPE_OPTIONS)}</h1>
          <a href={links.manufacturing_records_path} className="align-self-center btn" data-confirm={I18n.t('actions.cancel_alert', SCOPE_OPTIONS)} data-tid="cancel-from-record-ingredients-list">
            <i className="fa fa-close fa-2x" />
          </a>
        </div>
      </div>

      <div className="row mt-5">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <span className="text-center font-32 font-weight-light">
            {quantity}
            {' '}
            {product.unit}
            {' '}
            {product.name}
          </span>
        </div>
        <div className="offset-lg-2 col-lg-8"><hr /></div>
      </div>

      <div className="row">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-end">
          <button type="button" onClick={() => setShowNote(true)} className={`btn btn-outline-secondary btn-sm ${showNote ? 'd-none' : ''}`}>
            <span className="font-weight-light">{I18n.t('note.actions.add', SCOPE_OPTIONS)}</span>
          </button>
        </div>
      </div>

      <div className="row">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-end">
          <textarea value={note || ''} onChange={e => setNote(e.target.value)} className={`form-control form-control-lg mb-4 ${showNote ? '' : 'd-none'}`} data-tid="input-manufacturing-record-note" placeholder={I18n.t('note.placeholder', SCOPE_OPTIONS)} />
        </div>

        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <span className="text-center font-18 text-black-50 font-weight-light">{I18n.t('ingredient.title', SCOPE_OPTIONS)}</span>
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8">
          {
            ingredients.map((ingredient, i) => (
              <RecordIngredientForm
                key={`ingredient-${i}`}
                index={i}
                form={form}
                ingredient={ingredient}
                updateIngredient={updateIngredient}
                calculatedIdentifier={(ingredient.deleted ? null : calculatedIdentifier++)}
                allowUnitConversion={allowUnitConversion}
                removeIngredient={removeIngredient}
                renderFormErrors={renderFormErrors}
                links={links}
                units={units}
              />
            ))
          }
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className="btn btn-outline-secondary btn-lg mr-4" onClick={addIngredient} data-tid="add-more-ingredient-btn">{I18n.t('ingredient.actions.add', SCOPE_OPTIONS)}</button>
          <button type="button" className={`btn btn-info btn-lg ${loading ? 'disabled' : ''}`} onClick={onFormSubmit} disabled={loading} data-tid="manufacturing-record-submit-btn">
            <i className={`fa fa-spinner fa-spin mr-1 ${loading ? '' : 'd-none'}`} />
            {I18n.t('actions.save', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

RecordIngredients.propTypes = {
  onClick: PropTypes.func.isRequired,
  note: PropTypes.node,
  setNote: PropTypes.func.isRequired,
  links: PropTypes.object.isRequired,
  quantity: PropTypes.node,
  product: PropTypes.object,
  form: PropTypes.object.isRequired,
  ingredients: PropTypes.array.isRequired,
  addIngredient: PropTypes.func.isRequired,
  updateIngredient: PropTypes.func.isRequired,
  removeIngredient: PropTypes.func.isRequired,
  allowUnitConversion: PropTypes.bool.isRequired,
  renderFormErrors: PropTypes.bool.isRequired,
  saveManufacturingRecord: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
};

export default RecordIngredients;
