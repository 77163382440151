import React from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import moment from 'moment';
import Header from './StockHistory/Header';
import ReportContent from './StockHistory/ReportContent';
import EmptyReportPlaceholder from './StockHistory/EmptyReportPlaceholder';
import { isEmpty } from 'lodash';

const StockHistory = (props) => {
  const {
    searchPath, generateReportPath, translation, currentQueryParameters, product
  } = props;

  const SCOPE_OPTIONS = { scope: 'components.report.stock_history', locale: translation.locale };

  const refreshPageWith = (queryParams) => {
    window.Turbolinks.visit(`${window.location.pathname}?${queryParams}`)
  };

  const hasMandatoryQueryParameters = currentQueryParameters.hasOwnProperty('product_id') &&
    currentQueryParameters.hasOwnProperty('start_date') &&
    currentQueryParameters.hasOwnProperty('end_date');

  const showReportContent = hasMandatoryQueryParameters && !isEmpty(product);
  const showEmptyPlaceholder = !showReportContent;

  const renderReportTitle = () => {
    return (
      <div className="d-lg-flex justify-content-center">
        <h4>{I18n.t('title', {
          ...SCOPE_OPTIONS,
          start_date: moment(currentQueryParameters['start_date']).format('L'),
          end_date: moment(currentQueryParameters['end_date']).format('L'),
          product_code: isEmpty(product.code) ? '' : `(${product.code})`,
          product_name: product.name
        })}</h4>
      </div>
    );
  }

  return (
    <LocaleContext.Provider value={translation}>
      <Header
        searchPath={searchPath}
        loadedProduct={product}
        currentQueryParameters={currentQueryParameters}
        refreshPageWith={refreshPageWith}
      />

      {showEmptyPlaceholder && <EmptyReportPlaceholder />}
      {showReportContent && (
        <React.Fragment>
          <ReportContent
            headerTextComponent={renderReportTitle}
            fetchUrl={generateReportPath}
            product={product}
            currentQueryParameters={currentQueryParameters}
            refreshPageWith={refreshPageWith}
          />
        </React.Fragment>
      )}
    </LocaleContext.Provider>
  )
};

StockHistory.propTypes = {
  searchPath: PropTypes.string.isRequired,
  generateReportPath: PropTypes.string.isRequired,
  product: PropTypes.object,
  translation: LocaleProp.isRequired,
};

export default StockHistory;
