import React from "react"
import PropTypes from "prop-types"
import ParasutIcon from './ParasutIcon'

class ParasutIconLink extends React.Component {
  render () {
    return (
      <a target="_blank" rel="noopener" className="mr-2" title="Paraşüt'e git" href={this.props.parasut_url} data-tid="open-parasut-product-icon">
        <ParasutIcon />
      </a>
    );
  }
}

ParasutIconLink.propTypes = {
  parasut_url: PropTypes.string.isRequired
}

export default ParasutIconLink
