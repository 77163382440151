import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formProp } from '../Helper/CommonProps';
import useCollectionManagerReducer from './Hooks/UseCollectionManagerReducer';
import { validLineItems } from '../Helper/Validators/CustomerOrderValidator';
import LineItemsList from './Forms/LineItemsList';
import SubmitForm from './Forms/SubmitForm';
import PresetAutocomplete from '../Helper/PresetAutocomplete';
import CustomerAutocompleteWithCreateModal from '../Customer/CustomerAutocompleteWithCreateModal';
import { isEmpty } from 'lodash';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';

const Form = (props) => {
  const { customer_order, form, links, edit_form, translation, units } = props;
  const SCOPE_OPTIONS = { scope: 'components.customer_order.form', locale: translation.locale };

  const [description, setDescription] = useState(customer_order.description);
  const [orderNo, setOrderNo] = useState(customer_order.order_no);
  const [orderChannel, setOrderChannel] = useState(customer_order.order_channel);
  const [note, setNote] = useState(customer_order.note);
  const [issueDate, setIssueDate] = useState(customer_order.issue_date);
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState(customer_order.requested_delivery_date);
  const [customer, setCustomer] = useState(customer_order.customer || {});

  // line items
  const emptyLineItem = { product: {}, quantity: '' };
  const initialLineItemsState = (customer_order.line_items.length > 0 ? customer_order.line_items : [emptyLineItem]);
  const [lineItems, dispatchLineItems] = useCollectionManagerReducer(initialLineItemsState, emptyLineItem);

  const addLineItem = () => dispatchLineItems({ type: 'add' });
  const updateLineItem = (index, lineItem) => dispatchLineItems({ type: 'update', index, lineItem });
  const removeLineItem = (index, lineItem) => {
    dispatchLineItems({ type: 'update', index, lineItem: { ...lineItem, deleted: true } });
  };

  // form & validations
  const [renderFormErrors, setRenderFormErrors] = useState(false);
  const submitFormRef = useRef(null);
  const saveCustomerOrder = () => {
    setRenderFormErrors(true);
    if (validLineItems(lineItems)) {
      submitFormRef.current.submit();
      return true;
    }
    return false;
  };

  const [submitting, setSubmitting] = useState(false);
  const onFormSubmit = () => {
    setSubmitting(true);
    const formSaved = saveCustomerOrder();
    setSubmitting(formSaved);
  };

  // form visible/hide elements
  const [showNote, setShowNote] = useState(note ? true : false);
  const [showRequestedDeliveryDate, setShowRequestedDeliveryDate] = useState(requestedDeliveryDate ? true : false);
  const [showOrderNo, setShowOrderNo] = useState(orderNo ? true : false);

  const componentRef = useRef(null);
  useEffect(() => {
    const dropdowns = componentRef.current.querySelectorAll('[data-toggle=dropdown]');
    dropdowns.forEach(dropdown => new Dropdown(dropdown));
  });

  return (
    <LocaleContext.Provider value={translation}>
      <div className="row" ref={componentRef}>
        <div className="col-xl-9 col-12 d-flex justify-content-end">
          <div className={`dropdown ${showNote && showRequestedDeliveryDate && showOrderNo ? 'd-none' : ''}`}>
            <button className="btn btn-outline-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{I18n.t('actions.additional_info', SCOPE_OPTIONS)}</button>
            <div className="dropdown-menu mt-2 dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <button className={`dropdown-item ${showNote ? 'd-none' : ''}`} onClick={() => setShowNote(true)}>{I18n.t('options.note', SCOPE_OPTIONS)}</button>
              <button className={`dropdown-item ${showRequestedDeliveryDate ? 'd-none' : ''}`} onClick={() => setShowRequestedDeliveryDate(true)}>{I18n.t('options.delivery_date', SCOPE_OPTIONS)}</button>
              <button className={`dropdown-item ${showOrderNo ? 'd-none' : ''}`} onClick={() => setShowOrderNo(true)}>{I18n.t('options.order_no', SCOPE_OPTIONS)}</button>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-xl-9 col-12">

          <div className="form-group row">
            <label htmlFor="customer_order_description" className="col-lg-3 col-12 col-form-label font-weight-light">{I18n.t('description.label', SCOPE_OPTIONS)}</label>
            <div className="col-lg-9 col-12">
              <input value={description || ''} onChange={e => setDescription(e.target.value)} className="form-control" type="text" required data-tid="input-customer-order-description" placeholder={I18n.t('description.placeholder', SCOPE_OPTIONS)} />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="customer_order_issue_date" className="col-lg-3 col-12 col-form-label font-weight-light">{I18n.t('issue_date', SCOPE_OPTIONS)}</label>
            <div className="col-lg-9 col-12">
              <input value={issueDate || ''} onChange={e => setIssueDate(e.target.value)} className="form-control" type="date" required data-tid="input-customer-order-issue-date" />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="customer_order_order_channel" className="col-lg-3 col-12 col-form-label font-weight-light">{I18n.t('order_channel.label', SCOPE_OPTIONS)}</label>
            <div className="col-lg-9 col-12">
              <PresetAutocomplete
                options={I18n.t('channels', SCOPE_OPTIONS)}
                placeholder={I18n.t('order_channel.placeholder', SCOPE_OPTIONS)}
                value={orderChannel || ''}
                onChange={value => setOrderChannel(value)}
                onSelect={value => setOrderChannel(value)}
                inputDataTid='input-customer-order-order-channel'
              />
            </div>
          </div>

          <div className={`form-group row ${showRequestedDeliveryDate ? '' : 'd-none'}`}>
            <label htmlFor="customer_order_requested_delivery_date" className="col-lg-3 col-12 col-form-label font-weight-light">{I18n.t('delivery_date', SCOPE_OPTIONS)}</label>
            <div className="col-lg-9 col-12">
              <input value={requestedDeliveryDate || ''} onChange={e => setRequestedDeliveryDate(e.target.value)} className="form-control" type="date" required data-tid="input-customer-requested-delivery-date" />
            </div>
          </div>

          <div className={`form-group row ${showOrderNo ? '' : 'd-none'}`}>
            <label htmlFor="customer_order_order_no" className="col-lg-3 col-12 col-form-label font-weight-light">{I18n.t('order_no.label', SCOPE_OPTIONS)}</label>
            <div className="col-lg-9 col-12">
              <input value={orderNo || ''} onChange={e => setOrderNo(e.target.value)} className="form-control" type="text" required data-tid="input-customer-order-order-no" placeholder={I18n.t('order_no.placeholder', SCOPE_OPTIONS)} />
            </div>
          </div>

          <div className={`form-group row ${showNote ? '' : 'd-none'}`}>
            <label htmlFor="customer_order_note" className="col-lg-3 col-12 col-form-label font-weight-light">{I18n.t('note.label', SCOPE_OPTIONS)}</label>
            <div className="col-lg-9 col-12">
              <textarea value={note || ''} onChange={e => setNote(e.target.value)} className="form-control" data-tid="input-customer-order-note" placeholder={I18n.t('note.placeholder', SCOPE_OPTIONS)} />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="customer_order_customer" className="col-lg-3 col-12 col-form-label font-weight-light">{I18n.t('customer.label', SCOPE_OPTIONS)}</label>
            <div className="col-lg-9 col-12">
              <CustomerAutocompleteWithCreateModal
                customer={customer}
                searchPath={links.customer_search_path}
                onCustomerSelect={setCustomer}
                inputDataTid='input-customer-order-customer-autocomplete'
                renderAddCustomerModal={true}
                form={form}
                createCustomerPath={links.create_customer_path}
                isVendor={false}
              >
                {!isEmpty(customer) && (
                  <div className="d-flex justify-content-end">
                    <button onClick={() => setCustomer(null)} className="btn btn-link"><small>{I18n.t('customer.actions.clear', SCOPE_OPTIONS)}</small></button>
                  </div>
                )}
              </CustomerAutocompleteWithCreateModal>
            </div>
          </div>

          <LineItemsList
            form={form}
            links={links}
            lineItems={lineItems}
            addLineItem={addLineItem}
            updateLineItem={updateLineItem}
            removeLineItem={removeLineItem}
            renderFormErrors={renderFormErrors}
            units={units}
          />

          <div className="row mt-4">
            <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
              <button type="button" className={`btn btn-info btn-lg ${submitting ? 'disabled' : ''}`} onClick={onFormSubmit} disabled={submitting} data-tid="customer-order-submit-btn">
                <i className={`fa fa-spinner fa-spin mr-1 ${submitting ? '' : 'd-none'}`} />
                {edit_form ? I18n.t('actions.update', SCOPE_OPTIONS) : I18n.t('actions.create', SCOPE_OPTIONS)}
              </button>
            </div>
          </div>

        </div>
      </div>

      {/* for autocomplete list result, we need to expand bottom */}
      <div style={{ marginTop: '250px' }}>
        <SubmitForm
          ref={submitFormRef}
          form={form}
          description={description}
          issueDate={issueDate}
          orderNo={orderNo}
          orderChannel={orderChannel}
          note={note}
          requestedDeliveryDate={requestedDeliveryDate}
          customer={customer}
          lineItems={lineItems}
        />
      </div>
    </LocaleContext.Provider>
  );
};

Form.propTypes = {
  customer_order: PropTypes.object.isRequired,
  form: formProp.isRequired,
  links: PropTypes.shape({
    product_search_path: PropTypes.string.isRequired,
    create_product_path: PropTypes.string.isRequired,
  }).isRequired,
  edit_form: PropTypes.bool,
  translation: LocaleProp.isRequired,
  units: PropTypes.array.isRequired,
};

export default Form;
