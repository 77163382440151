import React, { useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';

const EmptyFilterPlaceholder = (props) => {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.customer_order.emptyFilterState', locale: localeContext.locale };

  return (
    <React.Fragment>
      <div>{I18n.t('use_filter_to', SCOPE_OPTIONS)}</div>
      <span>⌗</span>&ensp;{I18n.t('filter_channel', SCOPE_OPTIONS)}<br />
      <span>⛳</span>&ensp;{I18n.t('filter_issue_date', SCOPE_OPTIONS)}<br />
      <span>📝</span>&ensp;{I18n.t('filter_note', SCOPE_OPTIONS)}<br />
    </React.Fragment>
  )
};

EmptyFilterPlaceholder.propTypes = {};

export default EmptyFilterPlaceholder;
