import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import { formProp } from '../Helper/CommonProps';
import WorkflowStep from './Forms/WorkflowStep';
import { statusHandler } from '../Helper/RequestHelpers';
import FlashAlert from '../FlashMessage/FlashAlert';

const Show = (props) => {
  const {
    form,
    translation,
    product,
    earliestDate,
    steps,
  } = props;

  const [flashMessage, setFlashMessage] = useState(null);
  const [quantity, setQuantity] = useState();
  const [endDate, setEndDate] = useState(earliestDate)

  const SCOPE_OPTIONS = { scope: 'components.workflow.show', locale: translation.locale };

  const createWorkflowPlan = () => {
    const { action, method, csrf_token } = form;
    fetch(action, {
      credentials: 'same-origin',
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token
      },
      body: JSON.stringify({
        product_id: product.id,
        workflow_plan: {
          target_end_date: endDate,
          quantity: quantity
        }
      })
    }).then(statusHandler).then((response) => {
      return response.json()
    }).then((json) => {
      window.Turbolinks.visit(json.workflow_plan_path);
    }).catch((ex) => {
      if (ex.json && ex.json.error_detail) {
        const message = { id: Math.random(), type: 'danger', text: ex.json.error_detail };
        setFlashMessage(message);
      }
    });
  };

  return (
    <LocaleContext.Provider value={translation}>
      {flashMessage && (
        <div className='px-4'>
          <FlashAlert key={flashMessage.id} message={flashMessage} onClose={() => setFlashMessage(null)} />
        </div>
      )}

      <div className="row mt-0 mt-sm-3">
        <div className="col-10 col-lg-8 offset-1 offset-lg-2">
          <h4 className="font-weight-normal mb-3">
            {I18n.t('title', { ...SCOPE_OPTIONS, name: product.name })}
          </h4>
          <p>
            {I18n.t('description', SCOPE_OPTIONS)}
          </p>

          <div className="form-group row">
            <div className="col-lg-6 col-12">
              <input value={quantity || ''} onChange={e => setQuantity(e.target.value)} className={`form-control`} type="number" required min="0" step="1" data-tid="input-workflow-quantity" placeholder={I18n.t('quantity_placeholder', SCOPE_OPTIONS)} />
            </div>
            <div className="col-lg-6 col-12">
              <input value={endDate || ''} onChange={e => setEndDate(e.target.value)} className="form-control" type="date" required data-tid="input-workflow-end-date" />
            </div>
          </div>

          <small>{I18n.t('end_date_helper', SCOPE_OPTIONS)}</small>

          <div className="mt-4 history-timeline history-timeline-one-side" data-timeline-axis-style="dashed" data-timeline-content="axis">
            {
              steps.map((step, i) => (
                <WorkflowStep
                  key={`workflow-step-${i}`}
                  index={i}
                  step={step}
                  lastStepIndex={steps.length - 1}
                  endDate={endDate}
                />
              ))
            }
          </div>

          <div className="mt-5">
            <span>{I18n.t('explore_more', SCOPE_OPTIONS)}</span>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="offset-lg-2 col-lg-8 d-flex justify-content-center">
          <button type="button" className={`btn btn-dark btn-lg btn-block`} data-tid="workflow-plan-submit-btn" onClick={createWorkflowPlan}>
            {I18n.t('submit_btn', SCOPE_OPTIONS)}
          </button>
        </div>
      </div>
    </LocaleContext.Provider>
  );
};

Show.propTypes = {
  translation: LocaleProp.isRequired,
  form: formProp.isRequired,
};

export default Show;
