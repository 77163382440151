import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import NumberBadge from './NumberBadge';
import StatusBadge from './StatusBadge';
import TaskTimeline from './TaskTimeline';

const TaskDetail = (props) => {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.supply_planning.partials.task_detail', locale: localeContext.locale };

  const { task, index, onTaskUpdate } = props;
  const { purchase_order } = task;

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <NumberBadge task={task} index={index + 1} />
                <span className="font-weight-600">{task.name}</span>
                <div className="ml-auto">
                  <StatusBadge order={purchase_order} />
                </div>
              </div>
              <hr />
            </div>
          </div>

          <div className="row mt-1">
            <div className="col-lg-8 col-12 border-right">
              <TaskTimeline logs={purchase_order.audit_logs} />
            </div>
            <div className="col-lg-4 col-12">
              <div className="mx-1 my-1">
                <a href={purchase_order.show_path} className="btn btn-block btn-outline-dark">
                  {I18n.t('actions.detail', SCOPE_OPTIONS)}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

TaskDetail.propTypes = {
  index: PropTypes.number.isRequired,
  task: PropTypes.object.isRequired,
  onTaskUpdate: PropTypes.func.isRequired
};

export default TaskDetail;
