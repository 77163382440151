import React, { useState, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import RecipeDetails from './RecipeDetails';
import RecipeIngredients from './RecipeIngredients';
import SubmitForm from '../../NewRecipe/Forms/SubmitForm';
import LocaleContext, { LocaleProp } from 'Context/LocaleContext';
import { statusHandler } from '../../Helper/RequestHelpers';

const RecipeForm = (props) => {
  const {
    form, recipe, feature_flags, translation, onSuccess, cursor, renderFlashMessage, units, links
  } = props;

  //
  // RECIPE BASE INFO
  //
  const [product, setProduct] = useState(recipe.product || {});
  const [outputQuantity, setOutputQuantity] = useState(recipe.output_quantity);
  const [description, setDescription] = useState(recipe.description);

  //
  // INGREDIENTS RELATED
  //
  const emptyIngredient = { product: {}, quantity: '', used_unit: '' };
  const initialIngredientState = (recipe.ingredients.length > 0 ? recipe.ingredients : [emptyIngredient]);
  const [ingredients, dispatchIngredients] = useReducer((ingredients, action) => {
    switch (action.type) {
      case 'add':
        return [...ingredients, emptyIngredient];
      case 'update':
        return ingredients.map((ingredient, i) => {
          if (i === action.index) {
            return action.ingredient;
          }
          return ingredient;
        });
      case 'remove': {
        const newIngredients = [...ingredients];
        newIngredients.splice(action.index, 1);
        return newIngredients;
      }
      default:
        return ingredients;
    }
  }, initialIngredientState);
  const addIngredient = () => dispatchIngredients({ type: 'add' });
  const updateIngredient = (index, ingredient) => dispatchIngredients({ type: 'update', index, ingredient });
  const removeIngredient = (index, ingredient) => {
    dispatchIngredients({ type: 'update', index, ingredient: { ...ingredient, deleted: true } });
  };

  // form & validations
  const [renderFormErrors, setRenderFormErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveRecipe = () => {
    // do some client side validation
    setLoading(true);
    const { action, method, csrf_token } = form;
    fetch(action, {
      credentials: 'same-origin',
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token
      },
      body: JSON.stringify({
        recipe: {
          description: description,
          output_quantity: outputQuantity,
          product: {
            id: product.id,
            name: product.name,
            unit: product.unit,
            code: product.code,
            parasut_id: product.parasut_id
          },
          ingredients: ingredients.filter(ingredient => !(ingredient.id == null && ingredient.deleted)).map((ingredient, i) => {
            return {
              product: {
                id: ingredient.product.id,
                name: ingredient.product.name,
                unit: ingredient.product.unit,
                code: ingredient.product.code,
                parasut_id: ingredient.product.parasut_id
              },
              quantity: ingredient.quantity,
              used_unit: ingredient.used_unit,
              deleted: ingredient.deleted || false
            }
          })
        }
      })
    }).then(statusHandler).then((response) => {
      return response.json()
    }).then((json) => {
      setLoading(false);
      // console.log(json);
      setLoading(false);
      renderFlashMessage('success', 'saved!');
      onSuccess();
    }).catch((ex) => {
      console.log(ex);
      setLoading(false);
      if (ex.json && ex.json.errors) {
        const errors = ex.json.errors.map((message, i) => `${message.title} ${message.detail}`)
        renderFlashMessage('danger', errors);
      }
    });
  };

  return (
    <LocaleContext.Provider value={translation}>
      <RecipeDetails
        recipe={recipe}
        product={product}
        setProduct={setProduct}
        outputQuantity={outputQuantity}
        setOutputQuantity={setOutputQuantity}
        description={description}
        setDescription={setDescription}
        form={form}
        units={units}
        links={links}
      />
      <RecipeIngredients
        ingredients={ingredients}
        addIngredient={addIngredient}
        updateIngredient={updateIngredient}
        form={form}
        onFormSubmit={saveRecipe}
        allowUnitConversion={feature_flags.feature_conversion}
        removeIngredient={removeIngredient}
        renderFormErrors={renderFormErrors}
        loading={loading}
        units={units}
      />
      {/* for autocomplete list result, we need to expand bottom */}
      <div style={{ marginTop: '250px' }}>
      </div>
    </LocaleContext.Provider>
  );
};

RecipeForm.propTypes = {
  recipe: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  feature_flags: PropTypes.object.isRequired,
  translation: LocaleProp.isRequired,
  renderFlashMessage: PropTypes.func.isRequired,
  units: PropTypes.array.isRequired,
  links: PropTypes.object.isRequired
}

export default RecipeForm;
