import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import TaskListItem from './Partials/TaskListItem';
import TaskDetail from './Partials/TaskDetail';
import useCollectionManagerReducer from '../Helper/Hooks/UseCollectionManagerReducer';
import moment from 'moment';

const Show = (props) => {
  const { translation, supply_plan } = props;

  const SCOPE_OPTIONS = { scope: 'components.supply_planning.show', locale: translation.locale };
  moment.locale(translation.locale);

  const [tasks, dispatchTasks] = useCollectionManagerReducer(supply_plan.tasks, {});
  const updateTask = (index, task) => dispatchTasks({ type: 'update', index, object: task });

  const [selectedTaskIndex, setSelectedTaskIndex] = useState(0);
  const selectedTask = tasks[selectedTaskIndex];

  return (
    <LocaleContext.Provider value={translation}>
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center border-bottom">
            <h4 className="align-self-start">{supply_plan.name}</h4>

            <span className="ml-auto font-weight-600">
              {I18n.t('created_at', SCOPE_OPTIONS)}&nbsp;
              <span className="font-weight-light">
                {moment(supply_plan.created_at).format('L')}
              </span>
            </span>

            <span className="ml-3 font-weight-600">
              {I18n.t('goal_date', SCOPE_OPTIONS)}&nbsp;
              <span className="font-weight-light">
                {moment(supply_plan.goal_date).format('L')}
              </span>
            </span>
            {
              supply_plan.due_days > 0 && (
                <span className="ml-3 font-weight-600">
                  {I18n.t('possible_end_date', SCOPE_OPTIONS)}&nbsp;
                  <span className="font-weight-light">
                    {moment(supply_plan.possible_end_date).format('L')}&nbsp;
                    <span className="text-danger">
                      ({I18n.t('due_days', { ...SCOPE_OPTIONS, count: supply_plan.due_days })})
                    </span>
                  </span>
                </span>
              )
            }
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-5">
          <div className="list-group list-group-flush border-top border-bottom">
            {
              tasks.map((task, i) => (
                <TaskListItem
                  key={`task-list-item-${i}`}
                  index={i}
                  task={task}
                  isActive={i == selectedTaskIndex}
                  onClick={setSelectedTaskIndex}
                />
              ))
            }
          </div>
        </div>

        <div className="col-7">
          <TaskDetail plan={supply_plan} task={selectedTask} index={selectedTaskIndex} onTaskUpdate={updateTask} />
        </div>
      </div>
    </LocaleContext.Provider>
  );
};

Show.propTypes = {
  translation: LocaleProp.isRequired,
  supply_plan: PropTypes.object.isRequired
};

export default Show;
