import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import moment from 'moment';

const TaskSummary = (props) => {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.supply_planning.partials.task_summary', locale: localeContext.locale };
  moment.locale(localeContext.locale);

  const { task } = props;

  const WaitingSummary = () => {
    return (
      <React.Fragment>
        <span className="text-muted font-weight-light">{I18n.t('target_start_date', SCOPE_OPTIONS)}: {moment(task.target_start_date).format('L')}</span>
        <span className="text-muted font-weight-light">
          {I18n.t('possible_start_date', SCOPE_OPTIONS)}: {moment(task.possible_start_date).format('L')}
          {moment(task.possible_start_date).isAfter(moment(task.target_start_date)) && (
            <span className="text-danger font-weight-light ml-1">
              ({I18n.t('delay', { ...SCOPE_OPTIONS, count: moment(task.possible_start_date).diff(moment(task.target_start_date), 'days') })})
            </span>
          )}
        </span>
      </React.Fragment>
    )
  }

  const StartedSummary = () => {
    const calculatedDate = moment(task.possible_start_date).add(task.lead_time_in_days, 'days');

    return (
      <React.Fragment>
        <span className="text-muted font-weight-light">{I18n.t('actual_start_date', SCOPE_OPTIONS)}: {moment(task.actual_start_date).format('L')}</span>
        <span className="text-muted font-weight-light">
          {I18n.t('possible_end_date', SCOPE_OPTIONS)}: {moment(task.possible_end_date).format('L')}
          {calculatedDate.isBefore(moment(task.possible_end_date)) && (
            <span className="text-danger font-weight-light ml-1">
              ({I18n.t('delay', { ...SCOPE_OPTIONS, count: moment(task.possible_end_date).diff(calculatedDate, 'days') })})
            </span>
          )}
        </span>
      </React.Fragment>
    )
  }

  const DoneSummary = () => {
    return (
      <React.Fragment>
        <span className="text-muted font-weight-light">{I18n.t('actual_start_date', SCOPE_OPTIONS)}: {moment(task.actual_start_date).format('L')}</span>
        <span className="text-muted font-weight-light">{I18n.t('actual_end_date', SCOPE_OPTIONS)}: {moment(task.actual_end_date).format('L')}</span>
      </React.Fragment>
    )
  }

  const LeadTime = () => {
    return (
      <span className="text-muted font-weight-light">
        {I18n.t('expected_days', SCOPE_OPTIONS)}: {I18n.t('in_days', { ...SCOPE_OPTIONS, count: task.lead_time_in_days })}
      </span>
    )
  }

  let summary;
  switch (task.status) {
    case 'waiting':
      summary = <WaitingSummary />;
      break;
    case 'started':
      summary = <StartedSummary />;
      break;
    case 'done':
      summary = <DoneSummary />;
      break;
  }

  return (
    <div className="d-flex flex-column">
      <span className="font-weight-600">{task.name}</span>

      {summary}
      <LeadTime />
    </div>
  );
};

TaskSummary.propTypes = {
  task: PropTypes.object.isRequired
};

export default TaskSummary;
