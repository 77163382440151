import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import RecipeAutocomplete from '../../ManufacturingPlan/RecipeAutocomplete';
import { validProduct, validQuantity } from '../../Helper/Validators/SupplyPlanningValidator';

const SupplyPlanItemForm = (props) => {
  const {
    index,
    planItem,
    updatePlanItem,
    deletePlanItem,
    links,
    renderFormErrors,
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.supply_planning.forms.supply_planning_line_item_form', locale: localeContext.locale };

  const handleRecipeSelection = (recipe) => {
    updatePlanItem(index, { ...planItem, product: recipe.product, recipe: recipe });
  };

  const handleQuantity = (e) => {
    updatePlanItem(index, { ...planItem, quantity: e.target.value });
  };

  const recipeInvalid = !validProduct(planItem.product);
  const quantityInvalid = !validQuantity(planItem.quantity);

  return (
    <div className="supply-plan-item mt-2">
      <div className="d-flex flex-wrap row">
        <span className="font-18 font-weight-light text-black-50 col-form-label">{index + 1}</span>
        <RecipeAutocomplete
          recipe={planItem.recipe}
          onRecipeSelect={handleRecipeSelection}
          searchPath={links.recipe_search_path}
          topClassNames="flex-grow-1 ml-3"
          wrapperClassNames=""
          inputDataTid="supply-plan-item-product-autocomplete"
          newRecipeUrl={links.new_recipe_path}
          inputClassNames="form-control"
        >
          {renderFormErrors && recipeInvalid && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {I18n.t('recipe.feedback', SCOPE_OPTIONS)}
            </div>
          )}
        </RecipeAutocomplete>
        <div className="ml-3" style={{ maxWidth: '250px' }}>
          <div className="input-group">
            <input value={planItem.quantity || ''} onChange={handleQuantity} className={`form-control ${renderFormErrors && quantityInvalid && 'is-invalid'}`} type="number" required min="0" step="1" data-tid="input-supply-plan-item-quantity" placeholder={I18n.t('quantity.placeholder', SCOPE_OPTIONS)} />
            <div className="input-group-append">
              <span className="input-group-text">{planItem.product.unit || I18n.t('unit', SCOPE_OPTIONS)}</span>
            </div>
            <div className="invalid-feedback">
              {I18n.t('quantity.feedback', SCOPE_OPTIONS)}
            </div>
          </div>
        </div>
        <div className="col-form-label ml-3">
          <button type="button" className="close close-danger" data-dismiss="alert" aria-label="Close" onClick={() => deletePlanItem(index)} data-tid="remove-supply-plan-item-btn">
            <i className="fas fa-times" />
          </button>
        </div>
      </div>
    </div>
  );
};

SupplyPlanItemForm.propTypes = {
  index: PropTypes.number.isRequired,
  planItem: PropTypes.object.isRequired,
  updatePlanItem: PropTypes.func.isRequired,
  deletePlanItem: PropTypes.func.isRequired,
  links: PropTypes.shape({
    recipe_search_path: PropTypes.string.isRequired,
    new_recipe_path: PropTypes.string.isRequired,
  }).isRequired,
  renderFormErrors: PropTypes.bool.isRequired
};

export default SupplyPlanItemForm;
