import React from "react"
import PropTypes from "prop-types"
import { NestedForm, Text } from 'react-form'
import ProductAutocomplete from './../../Product/ProductAutocomplete';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = { product: props.product || {} }
  }

  onProductSelect(product) {
    this.setState({ product })
  }

  render () {
    const { searchPath } = this.props;
    const { product } = this.state;

    return (
      <ProductAutocomplete product={product} onProductSelect={this.onProductSelect.bind(this)} searchPath={searchPath} wrapperPropsStyles={{ display: 'block' }} parasutProductNewUrl={this.props.parasutProductNewUrl} inputDataTid="input-product-autocomplete" >
        <input type='hidden' value={product.id || ''} name='recipe[product][id]' />
        <input type='hidden' value={product.name || ''} name='recipe[product][name]' />
        <input type='hidden' value={product.unit || ''} name='recipe[product][unit]' />
        <input type='hidden' value={product.parasut_id || ''} name='recipe[product][parasut_id]' />
        <div className="form-group row mt-3">
          <div className="col-2 d-flex justify-content-end">
            <label htmlFor="recipe_output_quantity" className="col-form-label">Miktar</label>
          </div>
          <div className="col-10 input-group" data-tid="input-product-output-quantity-group">
            <Text field='output_quantity' name='recipe[output_quantity]' className="form-control" type="number" required={true} min="0" step="0.0001" data-tid="input-product-quantity" />
            <div className="input-group-append">
              <span className="input-group-text">{product.unit || 'Birim'}</span>
            </div>
          </div>
        </div>
      </ProductAutocomplete>
    );
  }
}

Product.propTypes = {
  product: PropTypes.object,
  searchPath: PropTypes.string.isRequired,
  parasutProductNewUrl: PropTypes.string.isRequired
}

export default Product
