import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import { DISPLAY_FORMATS } from '../../Shared/Constants';
import moment from 'moment';

const SummaryTable = (props) => {
  const { fetchUrl, product, currentQueryParameters, headerTextComponent, granularity } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.report.manufacturing_report.report_content.summary_table', locale: localeContext.locale };
  moment.locale(localeContext.locale);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tableData, setTableData] = useState({ data: [], nextPage: 0 });
  const [currentPage, setCurrentPage] = useState(1); // initial page number

  const fetchTableData = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const startDate = currentQueryParameters['start_date'];
      const endDate = currentQueryParameters['end_date'];
      const rawResponse = await fetch(`${fetchUrl}?product_id=${product.id}&start_date=${startDate}&end_date=${endDate}&page=${currentPage}&granularity=${granularity}`);
      const response = await rawResponse.json();

      setTableData({ data: [...tableData.data, ...response.data], nextPage: response.next_page });
    } catch (e) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const loadNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    fetchTableData();
  }, [fetchUrl, currentPage]);

  const showLoadMoreButton = tableData.nextPage !== null && currentPage < tableData.nextPage;

  const isFirstOccurrence = (index, date) => {
    if (index == 0) {
      return true;
    }

    const previousIndex = index - 1;
    return !moment(tableData.data[previousIndex].date).isSame(date, granularity);
  };

  return (
    <React.Fragment>
      {
        isError ? (
          <div style={{ minHeight: '350px' }}>
            <small className="text-danger text-monospace">
              {I18n.t('errors.unknown', SCOPE_OPTIONS)}
            </small>
          </div>
        ) : (
            <React.Fragment>
              {headerTextComponent && headerTextComponent}
              <div className="row mt-3">
                <div className="col-12">
                  <div className="card border">
                    <div className="card-header pb-0 px-0">
                      <div className="table-responsive">
                        <table className="table table-hover mb-0">
                          <colgroup>
                            <col style={{ width: '5%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                          </colgroup>
                          <thead>
                            <tr>
                              <th className="border-0 font-weight-600">#</th>
                              <th className="border-0 font-weight-600">{I18n.t('headers.date', SCOPE_OPTIONS)}</th>
                              <th className="border-0 font-weight-600">{I18n.t('headers.product', SCOPE_OPTIONS)}</th>
                              <th className="border-0 font-weight-600">{I18n.t('headers.unit', SCOPE_OPTIONS)}</th>
                              <th className="border-0 font-weight-600">{I18n.t('headers.consumed', SCOPE_OPTIONS)}</th>
                              <th className="border-0 font-weight-600">{I18n.t('headers.produced', SCOPE_OPTIONS)}</th>
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {tableData.data.map(({ date, quantity, product, unit, produced }, i) => {
                              return (
                                <tr key={`summary-table-row-${i}`}>
                                  <td className="align-middle">{i + 1}</td>
                                  <td className="align-middle">{isFirstOccurrence(i, date) ? moment(date).format(DISPLAY_FORMATS[granularity]) : ''}</td>
                                  <td className="align-middle">{product}</td>
                                  <td className="align-middle">{unit}</td>
                                  <td className={`align-middle ${produced ? '' : 'text-danger'}`}>{produced ? 0 : I18n.toNumber(quantity, { strip_insignificant_zeros: true })}</td>
                                  <td className={`align-middle ${produced ? 'text-success' : ''}`}>{produced ? I18n.toNumber(quantity, { strip_insignificant_zeros: true }) : 0}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {isLoading && (
                      <div className="card-footer d-flex justify-content-center">
                        <div className="spinner-border text-info" role="status">
                          <span className="sr-only">{I18n.t('loading', SCOPE_OPTIONS)}</span>
                        </div>
                      </div>
                    )}
                    {showLoadMoreButton && (
                      <div className="card-footer d-flex">
                        <button className="btn btn-outline-secondary mx-auto" type="button" data-tid="manufacturing-report-summary-table-load-more-btn" onClick={() => loadNextPage()}>
                          {I18n.t('actions.load_more', SCOPE_OPTIONS)}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
      }
    </React.Fragment>
  )
};

SummaryTable.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  currentQueryParameters: PropTypes.object.isRequired,
  headerTextComponent: PropTypes.any.isRequired,
  granularity: PropTypes.string.isRequired,
};

export default SummaryTable;
