import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import LineChart from '../../../Dashboard/LineChart';
import GranularityButtons from '../../Shared/GranularityButtons';
import { assignColor } from '../../../Dashboard/ChartColors';
import { GRANULARITY_OPTIONS, DISPLAY_FORMATS } from '../../Shared/Constants';
import { isMobileOnly } from 'react-device-detect';
import moment from 'moment';

const ProducedProductChart = (props) => {
  const { fetchUrl, product, headerTextComponent, currentQueryParameters, onGranularityChange, granularity } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.report.manufacturing_report.report_content.produced_product_chart', locale: localeContext.locale };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const chartOptions = {
    aspectRatio: (isMobileOnly ? 1 : 4),
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        title: tooltipItem => moment(tooltipItem[0].label).format(DISPLAY_FORMATS[granularity]),
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label || '';
          const itemData = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          const unit = itemData.unit || '';
          if (tooltipItem.yLabel) {
            return `${label}: ${I18n.toNumber(tooltipItem.yLabel, { strip_insignificant_zeros: true })} ${unit}`;
          }
        },
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: granularity,
          displayFormats: {
            day: 'D MMM',
          },
          isoWeekday: true,
        },
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{
        gridLines: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          padding: 10,
          maxTicksLimit: 5,
          callback: function (value, index, values) {
            return I18n.toNumber(value, { strip_insignificant_zeros: true });
          }
        }
      }]
    },
    legend: {
      display: false
    }
  };

  const beautifyChartData = (response) => {
    const datasets = [];
    response.datasets.forEach((dataset, i) => {
      const group = {
        fill: false,
        backgroundColor: assignColor(i),
        borderColor: assignColor(i),
        ...dataset,
      };
      datasets.push(group);
    });
    setChartData({ ...response, datasets });
  };

  useEffect(() => {
    const fetchReportData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const startDate = currentQueryParameters['start_date'];
        const endDate = currentQueryParameters['end_date'];
        const rawResponse = await fetch(`${fetchUrl}?product_id=${product.id}&start_date=${startDate}&end_date=${endDate}&granularity=${granularity}`);
        const response = await rawResponse.json();

        beautifyChartData(response);
      } catch (e) {
        setIsError(true);
      }
      setIsLoading(false);
    };

    fetchReportData();
  }, [fetchUrl]);

  return (
    <React.Fragment>
      {
        isLoading ? (
          <div className="row mt-3">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-info" role="status">
                  <span className="sr-only">{I18n.t('loading', SCOPE_OPTIONS)}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
            isError ? (
              <div style={{ minHeight: '350px' }}>
                <small className="text-danger text-monospace">
                  {I18n.t('errors.unknown', SCOPE_OPTIONS)}
                </small>
              </div>
            ) : (
                <div className="row mt-3">
                  <div className="col-12">
                    <div className="card border">
                      <div className="card-header bg-transparent d-flex border-bottom-0 flex-column flex-lg-row">
                        {headerTextComponent && headerTextComponent}
                        <GranularityButtons
                          options={GRANULARITY_OPTIONS}
                          selected={granularity}
                          onChange={onGranularityChange}
                          i18nScope='components.report.manufacturing_report.report_content'
                        />
                      </div>
                      <div className="card-block p-4">
                        <LineChart {...chartData} options={chartOptions} />
                      </div>
                    </div>
                  </div>
                </div>
              )
          )
      }
    </React.Fragment>
  )
};

ProducedProductChart.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  currentQueryParameters: PropTypes.object.isRequired,
  headerTextComponent: PropTypes.any.isRequired,
  onGranularityChange: PropTypes.func.isRequired,
  granularity: PropTypes.string.isRequired,
};

export default ProducedProductChart;
