import React, { useState, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import EditRecipeForm from './Forms/EditRecipeForm';
import EditIngredients from './Forms/EditIngredients';
import SubmitForm from './Forms/SubmitForm';
import { validIngredients } from '../Helper/Validators/IngredientValidator';
import LocaleContext, { LocaleProp } from 'Context/LocaleContext';

const EditForm = (props) => {
  const {
    form, recipe, feature_flags, recipe_path, translation, units, links
  } = props;
  //
  // RECIPE BASE INFO
  //
  const [product, setProduct] = useState(recipe.product || {});
  const [outputQuantity, setOutputQuantity] = useState(recipe.output_quantity);
  const [description, setDescription] = useState(recipe.description);
  //
  // INGREDIENTS RELATED
  //
  const emptyIngredient = { product: {}, quantity: '', used_unit: '' };
  const initialIngredientState = (recipe.ingredients.length > 0 ? recipe.ingredients : [emptyIngredient]);
  const [ingredients, dispatchIngredients] = useReducer((ingredients, action) => {
    switch (action.type) {
      case 'add':
        return [...ingredients, emptyIngredient];
      case 'update':
        return ingredients.map((ingredient, i) => {
          if (i === action.index) {
            return action.ingredient;
          }
          return ingredient;
        });
      case 'remove': {
        const newIngredients = [...ingredients];
        newIngredients.splice(action.index, 1);
        return newIngredients;
      }
      default:
        return ingredients;
    }
  }, initialIngredientState);
  const addIngredient = () => dispatchIngredients({ type: 'add' });
  const updateIngredient = (index, ingredient) => dispatchIngredients({ type: 'update', index, ingredient });
  const removeIngredient = (index, ingredient) => {
    dispatchIngredients({ type: 'update', index, ingredient: { ...ingredient, deleted: true } });
  };

  // form & validations
  const [renderFormErrors, setRenderFormErrors] = useState(false);
  const submitFormRef = useRef(null);
  const saveRecipe = () => {
    setRenderFormErrors(true);
    if (validIngredients(ingredients)) {
      submitFormRef.current.submit();
      return true;
    }
    return false;
  };

  const recipeComponent = (
    <EditRecipeForm
      recipe={recipe}
      product={product}
      setProduct={setProduct}
      outputQuantity={outputQuantity}
      setOutputQuantity={setOutputQuantity}
      description={description}
      setDescription={setDescription}
      form={form}
      recipePath={recipe_path}
      units={units}
      links={links}
    />
  );
  const ingredientsComponent = (
    <EditIngredients
      ingredients={ingredients}
      addIngredient={addIngredient}
      updateIngredient={updateIngredient}
      form={form}
      saveRecipe={saveRecipe}
      allowUnitConversion={feature_flags.feature_conversion}
      removeIngredient={removeIngredient}
      renderFormErrors={renderFormErrors}
      units={units}
      links={links}
    />
  );

  return (
    <LocaleContext.Provider value={translation}>
      {recipeComponent}
      {ingredientsComponent}
      {/* for autocomplete list result, we need to expand bottom */}
      <div style={{ marginTop: '250px' }}>
        <SubmitForm
          form={form}
          product={product}
          outputQuantity={outputQuantity}
          ingredients={ingredients}
          ref={submitFormRef}
          description={description}
        />
      </div>
    </LocaleContext.Provider>
  );
};

EditForm.propTypes = {
  recipe: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  feature_flags: PropTypes.object.isRequired,
  recipe_path: PropTypes.string.isRequired,
  translation: LocaleProp.isRequired,
  units: PropTypes.array.isRequired,
  links: PropTypes.object.isRequired
};

export default EditForm;
