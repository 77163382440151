import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const DoneBadge = ({index}) => {
  return(
    <div className="avatar avatar-sm mr-3 align-self-center">
      <span className="avatar-title rounded-circle bg-success">{index}</span>
    </div>
  );
};

const CurrentBadge = ({index}) => {
  return(
    <div className="avatar avatar-sm mr-3 align-self-center">
      <span className="avatar-title bg-warning rounded-circle">{index}</span>
    </div>
  );
};

const WaitingBadge = ({index}) => {
  return(
    <div className="avatar avatar-sm mr-3 align-self-center">
      <span className="avatar-title bg-white badge-custom-outline-dark text-dark rounded-circle">{index}</span>
    </div>
  );
};

const Badge = ({step, index}) => {
  if(step.status == 'done') {
    return <DoneBadge index={index} />
  }

  if(step.status == 'started') {
    return <CurrentBadge index={index} />
  }

  if(step.status == 'waiting') {
    return <WaitingBadge index={index} />
  }
};

Badge.propTypes = {
  step: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default Badge;
