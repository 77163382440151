import React, { useReducer, useState, useContext } from 'react';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { isEmpty } from 'lodash';
import Autocomplete from 'react-autocomplete';
import { renderInput, isNewUnit } from '../Helper/AutocompleteRenderInputWithBadge';
import CustomerAutocompleteWithCreateModal from '../Customer/CustomerAutocompleteWithCreateModal';

Modal.setAppElement('#react-modal-render-wormhole');

const currencyOptions = [
  { label: 'TRY', value: 'TRY', symbol: '₺' },
  { label: 'USD', value: 'USD', symbol: '$' },
  { label: 'EUR', value: 'EUR', symbol: '€' },
  { label: 'GBP', value: 'GBP', symbol: '£' },
];

const CreateNewProductModal = (props) => {
  const {
    isModalOpened, setIsModalOpened, newProductName, form, onProductCreate,
    createProductPath, showBuyingPriceInput, showBarcodeInput, units, links
  } = props;
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.product.create_new_product_modal', locale: localeContext.locale };
  const defaultFormValues = { name: newProductName, buying_currency: 'TRY' };
  const [formValues, dispatchFormValue] = useReducer((formValues, action) => {
    switch (action.type) {
      case 'update':
        return { ...formValues, ...action.payload };
      default:
        return formValues;
    }
  }, defaultFormValues);
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitProduct = () => {
    setLoading(true);
    const params = {
      product: {
        name: formValues.name,
        unit: formValues.unit,
        code: formValues.code,
        barcode: formValues.barcode,
        buying_price: formValues.buying_price,
        buying_currency: formValues.buying_currency,
        vendor: formValues.vendor,
      },
    };
    params[form.csrf_param] = form.csrf_token;
    try {
      fetch(createProductPath, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      }).then((response) => {
        setLoading(false);
        return response.json();
      }).then((json) => {
        if (json.product) {
          setIsModalOpened(false);
          onProductCreate(json.product);
        }
      });
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    if (isEmpty(formValues.name)) {
      setShowErrors(true);
    } else {
      submitProduct();
    }
  };

  const matchUnitToTerm = (item, value) => {
    return (
      item.value.toLowerCase().indexOf(value.toLowerCase()) !== -1
      || item.label.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isModalOpened}
        className="modal show"
        style={{ overlay: {}, content: { display: 'block' } }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title font-20">{I18n.t('title', SCOPE_OPTIONS)}</span>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" data-tid="create-new-product-modal-dismiss-btn" onClick={() => setIsModalOpened(false)}>
                <span aria-hidden="true"><i className="fa fa-close" /></span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <input value={formValues.name || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { name: e.target.value } })} className={`form-control form-control-lg ${showErrors && isEmpty(formValues.name) ? 'is-invalid' : ''}`} placeholder={I18n.t('name.placeholder', SCOPE_OPTIONS)} required data-tid="input-product-name" />
                <div className="invalid-feedback">
                  {I18n.t('name.feedback', SCOPE_OPTIONS)}
                </div>
              </div>
              <div className="form-group">
                <Autocomplete
                  value={formValues.unit || ''}
                  inputProps={{ className: 'form-control form-control-lg', placeholder: I18n.t('unit.placeholder', SCOPE_OPTIONS) }}
                  wrapperStyle={{ position: 'relative', display: 'inline' }}
                  items={units}
                  getItemValue={(item) => item.value}
                  shouldItemRender={matchUnitToTerm}
                  // open={true}
                  onChange={(e) => dispatchFormValue({ type: 'update', payload: { unit: e.target.value } })}
                  onSelect={value => dispatchFormValue({ type: 'update', payload: { unit: value } })}
                  renderMenu={(items, value, style) => (
                    <div className={`react-autocomplete-menu ${items.length > 0 ? '' : 'd-none'}`} style={style}>
                      {items}
                    </div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className={`py-1 px-2 item ${isHighlighted ? 'item-highlighted' : ''}`}
                      style={{ background: isHighlighted ? '#4095bf' : 'white' }}
                      data-tid="autocomplete-product-unit-list-item"
                      key={item.value}
                    >
                      {item.label}
                    </div>
                  )}
                  renderInput={props => renderInput(props, isNewUnit(units, formValues.unit), localeContext.locale)}
                />
              </div>
              <div className="form-group">
                <input value={formValues.code || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { code: e.target.value } })} className="form-control form-control-lg" placeholder={I18n.t('stock_code.placeholder', SCOPE_OPTIONS)} />
              </div>
              {showBuyingPriceInput && (
                <div className="form-group">
                  <div className="input-group">
                    <input value={formValues.buying_price || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { buying_price: e.target.value } })} className="form-control form-control-lg col-7" placeholder={I18n.t('buying_price.placeholder', SCOPE_OPTIONS)} type="number" min="0" step="0.0001" data-tid="input-product-buying-price" />
                    <select value={formValues.buying_currency} onChange={e => dispatchFormValue({ type: 'update', payload: { buying_currency: e.target.value } })} className="custom-select custom-select-lg col-5" data-tid="select-product-buying-currency">
                      {currencyOptions.map((currencyObject) => {
                        return <option value={currencyObject.value} key={currencyObject.value}>{currencyObject.label}</option>
                      })}
                    </select>
                  </div>
                </div>
              )}
              {showBarcodeInput && (
                <div className="form-group">
                  <input value={formValues.barcode || ''} onChange={e => dispatchFormValue({ type: 'update', payload: { barcode: e.target.value } })} className="form-control form-control-lg" placeholder={I18n.t('barcode.placeholder', SCOPE_OPTIONS)} data-tid="input-product-barcode" />
                </div>
              )}

              <div className="form-group">
                <CustomerAutocompleteWithCreateModal
                  customer={formValues.vendor}
                  searchPath={links.customer_search_path}
                  onCustomerSelect={e => dispatchFormValue({ type: 'update', payload: { vendor: e } })}
                  inputDataTid='input-customer-order-customer-autocomplete'
                  renderAddCustomerModal={true}
                  form={form}
                  createCustomerPath={links.create_customer_path}
                  inputClassNames='form-control-lg'
                  placeholderKey='components.product.create_new_product_modal.vendor.placeholder'
                  isVendor={true}
                >
                  {!isEmpty(formValues.vendor) && (
                    <div className="d-flex justify-content-end">
                      <button onClick={() => dispatchFormValue({ type: 'update', payload: { vendor: null } })} className="btn btn-link"><small>{I18n.t('vendor.clear', SCOPE_OPTIONS)}</small></button>
                    </div>
                  )}
                </CustomerAutocompleteWithCreateModal>
              </div>


              <div className="form-group d-flex justify-content-end">
                <button type="submit" className={`btn btn-info btn-lg ${loading ? 'disabled' : ''}`} onClick={handleSubmit} disabled={loading} data-tid="new-product-submit-btn">
                  <i className={`fa fa-spinner fa-spin mr-1 ${loading ? '' : 'd-none'}`} />
                  {I18n.t('actions.save', SCOPE_OPTIONS)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

CreateNewProductModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  setIsModalOpened: PropTypes.func.isRequired,
  newProductName: PropTypes.string,
  form: PropTypes.object.isRequired,
  onProductCreate: PropTypes.func.isRequired,
  createProductPath: PropTypes.string.isRequired,
  showBuyingPriceInput: PropTypes.bool.isRequired,
  showBarcodeInput: PropTypes.bool,
  units: PropTypes.array.isRequired,
  links: PropTypes.object
};

CreateNewProductModal.defaultProps = {
  showBarcodeInput: false,
};

export default CreateNewProductModal;
