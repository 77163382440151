import React from 'react';
import PropTypes from 'prop-types';

const SubmitForm = React.forwardRef((props, ref) => {
  const {
    form, formValues: { name, unit, code, barcode, parasut_id: parasutId, vendor }
  } = props;

  const formVendor = vendor || {};

  return (
    <React.Fragment>
      <form action={form.action} method="post" data-remote data-turbolinks-form data-tid="product-update-form" ref={ref}>
        <input type="hidden" name={form.csrf_param} value={form.csrf_token} />
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value={form.method} />
        <input type="hidden" value={name || ''} name="product[name]" />
        <input type="hidden" value={unit || ''} name="product[unit]" />
        <input type="hidden" value={code || ''} name="product[code]" />
        <input type="hidden" value={barcode || ''} name="product[barcode]" />
        <input type="hidden" value={parasutId || ''} name="product[parasut_id]" />

        <input type="hidden" value={formVendor.id || ''} name="product[vendor][id]" />
        <input type="hidden" value={formVendor.name || ''} name="product[vendor][name]" />
        <input type="hidden" value={formVendor.email || ''} name="product[vendor][email]" />
        <input type="hidden" value={formVendor.phone || ''} name="product[vendor][phone]" />
        <input type="hidden" value={formVendor.tax_office || ''} name="product[vendor][tax_office]" />
        <input type="hidden" value={formVendor.tax_number || ''} name="product[vendor][tax_number]" />
        <input type="hidden" value={formVendor.address || ''} name="product[vendor][address]" />
        <input type="hidden" value={formVendor.parasut_id || ''} name="product[vendor][parasut_id]" />
        <input type="hidden" value={formVendor.is_vendor || ''} name="product[vendor][is_vendor]" />
      </form>
    </React.Fragment>
  );
});

SubmitForm.propTypes = {
  form: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default SubmitForm;
