import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import Filter from './Filter';
import useFilterManagerReducer from './Hooks/UseFilterManagerReducer'
import { validFilter } from '../Helper/Validators/FilterValidator';
import qs from 'qs';

const Filters = (props) => {
  const {
    columns, currentFilters, predicates, searchPath, currentQueryParameters, i18nScope, emptyFilterState
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.filterable.filters', locale: localeContext.locale };

  // filter reducer
  const emptyFilter = { name: '', name_with_predicate: '', value: '', predicate: '', name_with_association: '', association: '' };
  const initialFilterState = (currentFilters.length > 0 ? currentFilters : []);
  const [filters, dispatchFilters] = useFilterManagerReducer(initialFilterState, emptyFilter);
  const addFilter = () => dispatchFilters({ type: 'add' });
  const updateFilter = (index, filter) => dispatchFilters({ type: 'update', index, filter: filter });
  const removeFilter = (index) => { dispatchFilters({ type: 'delete', index }); };

  const componentRef = useRef(null);
  useEffect(() => {
    const dropdowns = componentRef.current.querySelectorAll('[data-toggle=dropdown]');
    dropdowns.forEach(dropdown => new Dropdown(dropdown));
  });

  const generateFilterQuery = () => {
    const validated = filters.filter(f => validFilter(f));
    const params = validated.reduce(
      (o, f) => Object.assign(o, { [f.name_with_predicate]: f.value }), {});

    return qs.stringify({ ...currentQueryParameters, filter: { ...params }, page: 1 });
  };

  return (
    <div className="row" ref={componentRef}>
      <div className="col-12">
        <div className="dropdown">
          <button className={`btn ${currentFilters.length > 0 ? 'btn-success' : 'btn-outline-secondary'} dropdown-toggle`}
            type="button"
            data-tid="filterable-toggle-filter-btn"
            data-toggle="dropdown"
            data-persist="true"
            aria-haspopup="true"
            aria-expanded="false">
            <i className="fas fa-filter mr-1" />
            {I18n.t('filter', SCOPE_OPTIONS)}
            {currentFilters.length > 0 && (<span className="badge badge-light ml-1">{currentFilters.length}</span>)}
          </button>
          <div className="dropdown-menu mt-2 dropdown-menu-right p-3" aria-labelledby="dropdownMenuButton">
            <div className="filter-dropdown-size">
              {filters.map((filter, i) =>
                <Filter
                  key={`filter-${i}`}
                  index={i}
                  filter={filter}
                  columns={columns}
                  i18nScope={i18nScope}
                  predicates={predicates}
                  updateFilter={updateFilter}
                  removeFilter={removeFilter}
                />
              )}
              {filters.length == 0 && (emptyFilterState)}
              <hr />
              <div className="d-flex justify-content-between align-items-center mt-2">
                <button type="button" className="btn btn-sm btn-outline-dark" onClick={addFilter} data-tid="filterable-add-filter-btn">
                  <i className="fa fa-plus" />&nbsp;{I18n.t('actions.add', SCOPE_OPTIONS)}
                </button>
                <a href={`${searchPath}?${generateFilterQuery()}`} className="btn btn-sm btn-success" data-tid="filterable-apply-filters-btn">
                  {I18n.t('actions.apply', SCOPE_OPTIONS)}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

Filters.propTypes = {
  columns: PropTypes.object.isRequired,
  currentFilters: PropTypes.array.isRequired,
  currentQueryParameters: PropTypes.object.isRequired,
  predicates: PropTypes.object.isRequired,
  searchPath: PropTypes.string.isRequired,
  i18nScope: PropTypes.string.isRequired,
  emptyFilterState: PropTypes.element,
};

export default Filters;
