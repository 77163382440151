import { isEmpty } from 'lodash';

export const validUsername = username => !isEmpty(username);
export const validPassword = password => !isEmpty(password);
export const validExternalId = (externalId, companies) => externalId !== '' && externalId !== 0 && companies.findIndex(x => x.id === externalId) !== -1;
export const validUrl = (url, usingCloudVersion) => {
  if (usingCloudVersion) {
    return true;
  }
  return isUrl(url);
};

const isUrl = string => {
  try { return Boolean(new URL(string)); }
  catch (e) { return false; }
}
