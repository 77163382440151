import React, { useContext } from "react";
import LocaleContext, { I18n } from 'Context/LocaleContext';
import moment from 'moment';

const ManufacrutingRecordCard = (props) => {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.board.elements.cards.manufacturing_record', locale: localeContext.locale };
  moment.locale(localeContext.locale);

  const { item } = props;
  const record = item.manufacturingRecord;

  const renderedText = (desc, length) => {
    if (desc && desc.length > length) {
      // Return str truncated with '...' concatenated to the end of str.
      return desc.slice(0, length - 5) + '...'
    }
    return desc;
  }

  let statusIcon;
  if (record.parasut_status === 'not_send') {
    statusIcon = (
      <span className="badge badge-secondary text-white" title={I18n.t('statuses.not_send',SCOPE_OPTIONS)}>
        <i className="fas fa-clock" />
      </span>
    )
  } else if (record.parasut_status === 'in_progress') {
    statusIcon = (
      <span className="badge badge-warning text-white" title={I18n.t('statuses.in_progress',SCOPE_OPTIONS)}>
        <i className="fas fa-angle-double-right" />
      </span>
    )
  } else if (record.parasut_status === 'sent') {
    statusIcon = (
      <span className="badge badge-success text-white" title={I18n.t('statuses.sent',SCOPE_OPTIONS)}>
        <i className="fas fa-check" />
      </span>
    )
  }

  const bodyText = [record.quantity, record.product.unit, record.product.name].join(' ');

  return (
    <div className="d-flex w-100">
      <div className="mx-2">{statusIcon}</div>
      <div className="d-flex flex-column w-100">
        <div className="d-flex w-100">
          <a href={record.link} target="_blank" rel="noopener noreferrer" className="font-weight-600" title={record.description}>
            {renderedText(record.description, 55)}
          </a>
          <div className="mr-3 ml-auto">
            <i className="fas fa-ellipsis-h fa-sm text-black-50 dropdown-icon d-none" />
          </div>
        </div>
        <div title={bodyText}>{renderedText(bodyText, 55)}</div>
        <div className="mt-auto mb-2 d-flex">
          <small className="mr-2">#{record.id}</small>
          <small>{moment(record.issue_date).format('ll')}</small>
          {record.created_by && record.created_by.name && (
            <small className="ml-auto mr-2 text-truncate" title={
              I18n.t('created_by', {...SCOPE_OPTIONS, name: record.created_by.name})
            }>
              {renderedText(record.created_by.name, 20)}
            </small>
          )}
        </div>
      </div>
    </div>
  );
}

export default ManufacrutingRecordCard;
