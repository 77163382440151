import React from "react";
import ManufacturingRecordCard from './Cards/ManufacturingRecordCard';

function getStyle({ draggableStyle, virtualStyle, isDragging }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...virtualStyle,
    ...draggableStyle
  };

  // Being lazy: this is defined in our css file
  const grid = 8;

  // when dragging we want to use the draggable style for placement, otherwise use the virtual style
  const result = {
    ...combined,
    height: isDragging ? combined.height : combined.height - grid,
    left: isDragging ? combined.left : combined.left + grid,
    width: isDragging
      ? draggableStyle.width
      : `calc(${combined.width} - ${grid * 2}px)`,
    marginBottom: grid
  };

  return result;
}

const Item = ({ provided, item, style, isDragging }) => {
  let renderedComponent;
  switch (item.cardType) {
    case 'ManufacturingRecord':
      renderedComponent = (
        <ManufacturingRecordCard item={item} />
      );
      break;
    // case 'Card':
    //   renderedComponent = (<p></p>);
    //   break;
  }

  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({
        draggableStyle: provided.draggableProps.style,
        virtualStyle: style,
        isDragging
      })}
      className={`board-list-item d-flex pt-1 ${isDragging ? "is-dragging" : ""}`}
    >
      {renderedComponent}
    </div>
  );
}

export default Item;