import React from 'react';
import PropTypes from 'prop-types';

const DoneBadge = ({ index }) => {
  return (
    <div className="avatar avatar-sm mr-3 align-self-center">
      <span className="avatar-title rounded-circle bg-success">{index}</span>
    </div>
  );
};

const CurrentBadge = ({ index }) => {
  return (
    <div className="avatar avatar-sm mr-3 align-self-center">
      <span className="avatar-title bg-warning rounded-circle">{index}</span>
    </div>
  );
};

const WaitingBadge = ({ index }) => {
  return (
    <div className="avatar avatar-sm mr-3 align-self-center">
      <span className="avatar-title bg-white badge-custom-outline-dark text-dark rounded-circle">{index}</span>
    </div>
  );
};

const NumberBadge = ({ task, index }) => {
  if (task.status == 'done') {
    return <DoneBadge index={index} />
  }

  if (task.status == 'started') {
    return <CurrentBadge index={index} />
  }

  if (task.status == 'waiting') {
    return <WaitingBadge index={index} />
  }
};

NumberBadge.propTypes = {
  task: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default NumberBadge;
