import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocaleContext, { I18n } from 'Context/LocaleContext';

const EmptyFilterPlaceholder = (props) => {
  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.product.emptyFilterState', locale: localeContext.locale };

  return (
    <React.Fragment>
      <div>{I18n.t('use_filter_to', SCOPE_OPTIONS)}</div>
      <span>🏛</span>&ensp;{I18n.t('filter_name', SCOPE_OPTIONS)}<br />
      <span>⛳️</span>&ensp;{I18n.t('filter_stock_count', SCOPE_OPTIONS)}<br />
      <span>🧾</span>&ensp;{I18n.t('filter_stock_code', SCOPE_OPTIONS)}<br />
    </React.Fragment>
  )
};

EmptyFilterPlaceholder.propTypes = {};

export default EmptyFilterPlaceholder;
