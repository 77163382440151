const searchProducts = async (code, searchPath, productMatchCallback, setIsSearching, setIsError) => {
  setIsError(false);
  setIsSearching(true);

  try {
    const value = code.split('%').join('');
    const searchPathHasQueryParams = searchPath.indexOf('?') > -1;
    const queryParameterPrefix = searchPathHasQueryParams ? '&' : '?';

    fetch(`${searchPath}${queryParameterPrefix}query=${value}`, {
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json()
    }).then((json) => {
      json.some(searchResult => {
        if(searchResult.code === code) {
          productMatchCallback(searchResult);
          return true;
        }
      });
      setIsSearching(false);
    }).catch((ex) => {
      // console.log('parsing failed', ex)
      setIsSearching(false);
      setIsError(true);
    })
  } catch(e) {
    setIsError(true);
    setIsSearching(false);
  }
}

export default searchProducts;
