import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import RecordDetailForm from './Forms/RecordDetailForm';
import RecordIngredients from './Forms/RecordIngredients';
import SubmitForm from './Forms/SubmitForm';
import { validIngredients } from '../Helper/Validators/IngredientValidator';
import { validDate } from '../Helper/Validators/DateValidator';
import useIngredientManagerReducer from './Hooks/UseIngredientManagerReducer';
import LocaleContext, { LocaleProp } from 'Context/LocaleContext';

export const STATES = {
  recordDetailForm: 'recordDetailForm',
  recordIngredientsForm: 'recordIngredientsForm',
};

const Form = (props) => {
  const {
    form, manufacturing_record, recipe, feature_flags, currentView: propCurrentView, links,
    customer_order_line_item, translation, units
  } = props;
  const [currentView, setCurrentView] = useState(propCurrentView || STATES.recordDetailForm);

  //
  // MANUFACTURING RECORD BASE INFO
  //
  const [product, setProduct] = useState(manufacturing_record.product || {});
  const [quantity, setQuantity] = useState(manufacturing_record.quantity);
  const [issueDate, setIssueDate] = useState(manufacturing_record.issue_date);
  const [note, setNote] = useState(manufacturing_record.note);
  const calculateIngredientQuantity = (quantity, spentPerUnit) => parseFloat((quantity * Number(spentPerUnit)).toFixed(4));
  const updateQuantity = (quantity) => {
    if (recipe) {
      ingredients.forEach((ingredient, i) => {
        // this will only update ingredients come from recipe.
        if (ingredient.spent_per_used_unit) {
          updateIngredient(i, {
            ...ingredient, quantity: calculateIngredientQuantity(quantity, ingredient.spent_per_used_unit),
          });
        }
      });
    }
    setQuantity(quantity);
  };
  //
  // MANUFACTURING RECORD RELATED
  //
  const emptyIngredient = { product: {}, quantity: '', used_unit: '' };

  // INGREDIENTS
  const initialIngredientState = (manufacturing_record.ingredients.length > 0 ? manufacturing_record.ingredients : [emptyIngredient]);
  const [ingredients, dispatchIngredients] = useIngredientManagerReducer(initialIngredientState, emptyIngredient);
  const addIngredient = () => dispatchIngredients({ type: 'add' });
  const updateIngredient = (index, ingredient) => dispatchIngredients({ type: 'update', index, ingredient });
  const removeIngredient = (index, ingredient) => {
    dispatchIngredients({ type: 'update', index, ingredient: { ...ingredient, deleted: true } });
  };

  // INFLOW_INGREDIENTS
  const initialInflowIngredientState = (manufacturing_record.inflow_ingredients.length > 0 ? manufacturing_record.inflow_ingredients : []);
  const [inflowIngredients, dispatchInflowIngredients] = useIngredientManagerReducer(initialInflowIngredientState, emptyIngredient);
  const addInflow = () => dispatchInflowIngredients({ type: 'add' });
  const updateInflow = (index, inflowIngredient) => dispatchInflowIngredients({ type: 'update', index, ingredient: inflowIngredient });
  const removeInflow = (index, inflowIngredient) => {
    dispatchInflowIngredients({ type: 'update', index, ingredient: { ...inflowIngredient, deleted: true } });
  };

  // form & validations
  const [renderFormErrors, setRenderFormErrors] = useState(false);
  const submitFormRef = useRef(null);
  const saveIngredients = () => {
    setRenderFormErrors(true);
    if (validIngredients(ingredients) && validDate(issueDate)) {
      submitFormRef.current.submit();
      return true;
    }
    return false;
  };

  let renderedComponent;
  switch (currentView) {
    case STATES.recordDetailForm:
      renderedComponent = (
        <RecordDetailForm
          onClick={() => setCurrentView(STATES.recordIngredientsForm)}
          form={form}
          links={links}
          product={product}
          setProduct={setProduct}
          quantity={quantity}
          setQuantity={updateQuantity}
          issueDate={issueDate}
          setIssueDate={setIssueDate}
          allowUnitConversion={feature_flags.feature_conversion}
          inflowIngredients={inflowIngredients}
          addInflow={addInflow}
          updateInflow={updateInflow}
          removeInflow={removeInflow}
          units={units}
        />
      );
      break;
    case STATES.recordIngredientsForm:
      renderedComponent = (
        <RecordIngredients
          onClick={() => setCurrentView(STATES.recordDetailForm)}
          note={note}
          setNote={setNote}
          links={links}
          quantity={quantity}
          product={product}
          form={form}
          ingredients={ingredients}
          addIngredient={addIngredient}
          updateIngredient={updateIngredient}
          allowUnitConversion={feature_flags.feature_conversion}
          removeIngredient={removeIngredient}
          renderFormErrors={renderFormErrors}
          saveManufacturingRecord={saveIngredients}
          units={units}
        />
      );
      break;
  }
  return (
    <LocaleContext.Provider value={translation}>
      {renderedComponent}
      {/* for autocomplete list result, we need to expand bottom */}
      <div style={{ marginTop: '250px' }}>
        <SubmitForm
          form={form}
          product={product}
          quantity={quantity}
          issueDate={issueDate}
          note={note}
          ingredients={ingredients}
          ref={submitFormRef}
          description={manufacturing_record.description}
          recipe={recipe}
          inflowIngredients={inflowIngredients}
          customerOrderLineItem={customer_order_line_item}
        />
      </div>
    </LocaleContext.Provider>
  );
};

Form.propTypes = {
  manufacturing_record: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  recipe: PropTypes.object,
  feature_flags: PropTypes.object.isRequired,
  currentView: PropTypes.oneOf(Object.keys(STATES)),
  links: PropTypes.object.isRequired,
  customer_order_line_item: PropTypes.object,
  translation: LocaleProp.isRequired,
  units: PropTypes.array.isRequired,
};
export default Form;
