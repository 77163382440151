import React from "react"
import AkauntingIconImageSrc from 'icons/akaunting_icon.png'

class AkauntingIcon extends React.Component {
  render() {
    return (
      <img className="shared-icon-32" src={AkauntingIconImageSrc} alt="Akaunting icon" />
    );
  }
}

export default AkauntingIcon;
