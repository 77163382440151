import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { formProp } from '../Helper/CommonProps';
import LocaleContext, { I18n, LocaleProp } from 'Context/LocaleContext';
import IngredientCostForm from './Forms/IngredientCostForm';
import SubmitForm from './Forms/SubmitForm';
import useCollectionManagerReducer from '../Helper/Hooks/UseCollectionManagerReducer';

const Form = (props) => {
  const { recipe_material_cost_form, form, translation, default_currency, } = props;
  const SCOPE_OPTIONS = { scope: 'components.material_cost.form', locale: translation.locale };

  const [costCurrency, setCostCurrency] = useState( recipe_material_cost_form.total_cost_currency || default_currency);
  const currencyOptions = I18n.t('currencies', SCOPE_OPTIONS);

  // ingredient material costs
  const initialLineItemsState = recipe_material_cost_form.ingredient_material_costs;
  const [lineItems, dispatchLineItems] = useCollectionManagerReducer(initialLineItemsState, []);
  const updateLineItem = (index, materialCost) => dispatchLineItems({ type: 'update', index, object: materialCost });

  const calculateCost = (materialCost) => {
    let exchangeRate = 1
    if (materialCost.currency != costCurrency) {
      exchangeRate = materialCost.exchange_rate;
    }

    return parseFloat(Number(materialCost.ingredient.total_output_for_cost_calculation * materialCost.amount * exchangeRate).toFixed(4));
  }

  const totalCost = lineItems.reduce((acc, materialCost) => acc + calculateCost(materialCost), 0);

  const submitFormRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    Rails.fire(submitFormRef.current, 'submit');
  }

  return (
    <LocaleContext.Provider value={translation}>
      <div className="row">
        <div className="col-xl-12 col-12">
          <div className="card">
            <div className="card-header d-flex bg-transparent">
              <h5 className="card-title d-flex align-items-center">
                <i className="far fa-file-alt fa-2x" />
                <span className="ml-3">{I18n.t('cost_of', { ...SCOPE_OPTIONS, description: recipe_material_cost_form.container.description})}</span>
              </h5>
            </div>

            <div className="card-header pb-0 px-0">
              <div className="table-responsive">
                <table className="table mb-0">
                  <colgroup>
                    <col style={{width: '5%'}}/>
                    <col style={{width: '30%'}}/>
                    <col style={{width: '15%'}}/>
                    <col style={{width: '25%'}}/>
                    <col style={{width: '25%'}}/>
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="border-0"></th>
                      <th className="border-0 font-weight-600">{I18n.t('table.product', SCOPE_OPTIONS)}</th>
                      <th className="border-0 font-weight-600">{I18n.t('table.quantity', SCOPE_OPTIONS)}</th>
                      <th className="border-0 font-weight-600">{I18n.t('table.purchase_price', SCOPE_OPTIONS)}</th>
                      <th className="border-0">
                        <span className="d-flex justify-content-end pr-2 font-weight-600">{I18n.t('table.cost', SCOPE_OPTIONS)}</span>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>

            <div className="card-body pt-0 px-0">
              <div className="table-responsive">
                <table className="table">
                  <colgroup>
                    <col style={{width: '5%'}}/>
                    <col style={{width: '30%'}}/>
                    <col style={{width: '15%'}}/>
                    <col style={{width: '25%'}}/>
                    <col style={{width: '25%'}}/>
                  </colgroup>
                  <tbody>
                    {
                      lineItems.map((ingredientMaterialCost, i) => (
                        <IngredientCostForm
                          key={`line-item-${i}`}
                          index={i}
                          ingredientMaterialCost={ingredientMaterialCost}
                          costCurrency={costCurrency}
                          currencyOptions={currencyOptions}
                          updateLineItem={updateLineItem}
                          totalCalculatedAmount={calculateCost(ingredientMaterialCost)}
                        />
                      ))
                    }

                    <tr>
                      <td className="align-middle"></td>
                      <td className="align-middle"></td>
                      <td className="align-middle"></td>
                      <td className="align-middle font-weight-600">{I18n.t('cost_currency', SCOPE_OPTIONS)}</td>
                      <td className="align-middle">
                        <div className="d-flex justify-content-end pr-2">
                          <select className="custom-select custom-select-currency-width" value={costCurrency} onChange={(e) => setCostCurrency(e.target.value) } data-tid="input-total-cost-currency-selection">
                            {currencyOptions.map(currencyObject => {
                              return <option value={currencyObject.value} key={currencyObject.value}>{currencyObject.label}</option>
                            })}
                          </select>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="align-middle border-top-0"></td>
                      <td className="align-middle border-top-0"></td>
                      <td className="align-middle border-top-0"></td>
                      <td className="align-middle font-weight-600">{I18n.t('total_cost', SCOPE_OPTIONS)}</td>
                      <td className="align-middle">
                        <h4 className="d-flex justify-content-end pr-2">{I18n.toNumber(totalCost, { strip_insignificant_zeros: true })} {costCurrency}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-9 col-12">
                  <p className="text-muted">
                    {recipe_material_cost_form.last_updated_at && (
                      <span className='pr-1'>{I18n.t('created_at', { ...SCOPE_OPTIONS, date: recipe_material_cost_form.last_updated_at })}</span>
                    )}
                    {recipe_material_cost_form.calculate_parasut_cost && (
                      <a href="?refresh=true">{I18n.t('calculate_cost', SCOPE_OPTIONS)}</a>
                    )}
                  </p>
                </div>

                <div className="col-3 d-flex justify-content-end">
                  <button className={`col-12 btn btn-primary ${loading ? 'disabled' : ''}`} onClick={handleSubmit} disabled={loading} data-tid="material-cost-submit-btn">
                    <i className={`fa fa-spinner fa-spin mr-1 ${loading ? '' : 'd-none'}`} />
                    {I18n.t('actions.save', SCOPE_OPTIONS)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SubmitForm form={form} lineItems={lineItems} costCurrency={costCurrency} ref={submitFormRef} />
    </LocaleContext.Provider>
  );
};

Form.propTypes = {
  recipe_material_cost_form: PropTypes.object.isRequired,
  form: formProp.isRequired,
  translation: LocaleProp.isRequired,
  default_currency: PropTypes.string.isRequired,
};

export default Form;
