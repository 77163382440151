import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ProductAutocomplete from '../../Product/ProductAutocomplete';
import LocaleContext, { I18n } from 'Context/LocaleContext';
import { validProduct, validQtyChange, validQtyAfter } from '../../Helper/Validators/StockAdjustmentValidator';

const StockAdjustmentItemForm = (props) => {
  const {
    index,
    adjustment,
    addNewAdjustmentItem,
    updateAdjustmentItem,
    deleteAdjustmentItem,
    searchPath,
    showError
  } = props;

  const localeContext = useContext(LocaleContext);
  const SCOPE_OPTIONS = { scope: 'components.stock_adjustment.forms.stock_adjustment_line_item_form', locale: localeContext.locale };

  const handleProductSelection = (product) => {
    const qtyChange = parseFloat(adjustment.qty_change);
    const qtyAfter = qtyChange + (parseFloat(product.stock_count) || 0);
    const shouldAddNewLine = !validProduct(adjustment.product);
    updateAdjustmentItem(index, { ...adjustment, product: product, qty_change: qtyChange, qty_after: qtyAfter });
    if (shouldAddNewLine) {
      addNewAdjustmentItem();
    }
  };

  const onQtyChangeChanged = (e) => {
    const qtyChange = parseFloat(e.target.value);
    const qtyAfter = qtyChange + (parseFloat(adjustment.product.stock_count) || 0);
    updateAdjustmentItem(index, { ...adjustment, qty_change: qtyChange, qty_after: qtyAfter });
  };

  const onQtyAfterChanged = (e) => {
    const qtyAfterChange = parseFloat(e.target.value);
    const qtyChange = qtyAfterChange - (parseFloat(adjustment.product.stock_count) || 0);
    updateAdjustmentItem(index, { ...adjustment, qty_after: qtyAfterChange, qty_change: qtyChange });
  };

  const productInvalid = !validProduct(adjustment.product);
  const qtyChangeInvalid = !validQtyChange(adjustment.qty_change);
  const qtyAfterInvalid = !validQtyAfter(adjustment.qty_after);

  return (
    <tr>
      <td className="align-middle">
        <ProductAutocomplete
          searchPath={searchPath}
          product={adjustment.product}
          onProductSelect={handleProductSelection}
          topClassNames=""
          parasutProductNewUrl=""
          inputDataTid="stock-adjustment-item-product-autocomplete"
          inputClassNames="form-control flex-grow-1"
          autoFocus={index != 0}
        >
          {showError && productInvalid && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {I18n.t('product.feedback', SCOPE_OPTIONS)}
            </div>
          )}
        </ProductAutocomplete>
      </td>
      <td className="align-middle">{adjustment.product.code || '-'}</td>
      <td className="align-middle">
        <div className={`input-group ${adjustment.qty_change < 0 ? 'border rounded border-warning' : ''}`}>
          <input value={isNaN(adjustment.qty_change) ? '' : adjustment.qty_change} onChange={onQtyChangeChanged} className={`form-control ${showError && qtyChangeInvalid && 'is-invalid'}`} type="number" step="1" data-tid="input-stock-adjustment-item-qty-change" />
          <div className="input-group-append">
            <span className="input-group-text">{adjustment.product.unit || I18n.t('unit', SCOPE_OPTIONS)}</span>
          </div>
          <div className="invalid-feedback">
            {I18n.t('quantity_change.feedback', SCOPE_OPTIONS)}
          </div>
        </div>
      </td>
      <td className="align-middle">{adjustment.product.stock_count || '-'}</td>
      <td className="align-middle">
        <div className="input-group">
          <input value={isNaN(adjustment.qty_after) ? '' : adjustment.qty_after} onChange={onQtyAfterChanged} className={`form-control ${showError && qtyAfterInvalid && 'is-invalid'}`} type="number" step="1" data-tid="input-stock-adjustment-item-qty-after" />
          <div className="input-group-append">
            <span className="input-group-text">{adjustment.product.unit || I18n.t('unit', SCOPE_OPTIONS)}</span>
          </div>
          <div className="invalid-feedback">
            {I18n.t('quantity_after.feedback', SCOPE_OPTIONS)}
          </div>
        </div>
      </td>
      <td className="align-middle">
        <div className="col-form-label ml-3">
          <button type="button" className="close close-danger" data-dismiss="alert" aria-label="Close" onClick={() => deleteAdjustmentItem(index)} data-tid="remove-stock-adjustment-item-btn">
            <i className="fas fa-times" />
          </button>
        </div>
      </td>
    </tr >
  );
};

StockAdjustmentItemForm.propTypes = {
  index: PropTypes.number.isRequired,
  adjustment: PropTypes.object.isRequired,
  addNewAdjustmentItem: PropTypes.func.isRequired,
  updateAdjustmentItem: PropTypes.func.isRequired,
  deleteAdjustmentItem: PropTypes.func.isRequired,
  searchPath: PropTypes.string.isRequired,
  showError: PropTypes.bool.isRequired
};

export default StockAdjustmentItemForm;
